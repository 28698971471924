import React, { useCallback, useEffect } from 'react'

import { AppRouteProps } from '../../Layout/AppRoutes'
import { AppTabProps } from '../../Components/AppTabs/index'
import { AppFeatureContainer } from '../../Components/AppFeatureContainer'
import { useAppDispatch } from '../../Hooks/useAppDispatch'
import { LabsThunks } from '../Labs/StoreAdapter'
import { LabContactsThunks } from '../LabContacts/StoreAdapter'

const tabs: AppTabProps[] = [
  { value: 'labs', route: '/lab-management/labs', label: 'Labs' },
  { value: 'labContacts', route: '/lab-management/contacts', label: 'Contacts' },
]

interface LabManagementProps {
  routes?: AppRouteProps[],
}

export const LabManagement: React.FC<LabManagementProps> = ({
  routes,
}) => {
  const dispatch = useAppDispatch()

  const loadData = useCallback(() => {
    dispatch(LabsThunks.fetchLabs({}))
    dispatch(LabContactsThunks.fetchLabContacts({}))
  }, [dispatch])

  useEffect(() => {
    loadData()
  }, [loadData])

  return (
    <AppFeatureContainer routes={routes} tabs={tabs} selected={'labs'} />
  )
}
