import { GridRowId } from '@material-ui/data-grid'
import React, { useState } from 'react'

export const DataGridContext = React.createContext({
  rowSelection: [] as GridRowId[],
  visibleRows: [] as GridRowId[],
  setRowSelection: (selection: GridRowId[]) => {},
  setVisibleRows: (rows: GridRowId[]) => {},
})

export const DataGridProvider: React.FC = ({
  children,
}) => {
  const [rowSelection, setRowSelection] = useState<GridRowId[]>()
  const [visibleRows, setVisibleRows] = useState<GridRowId[]>()

  const ctx = {
    rowSelection,
    visibleRows,
    setRowSelection,
    setVisibleRows,
  }

  return (
    <DataGridContext.Provider value={ctx}>
      {children}
    </DataGridContext.Provider>
  )
}
