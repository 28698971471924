import { Box, ButtonGroup, makeStyles, Popover, Typography } from '@material-ui/core'
import _ from 'lodash'
import React from 'react'
import { CopyToClipboardButton } from '../../../Components/CopyToClipboardButton'
import { useShipmentSelector } from '../Hooks/useShipmentSelector'
import { ShipmentExternalTrackingButton } from './ShipmentExternalTrackingButton'

const useStyles = makeStyles((theme) => ({
  popover: {
    pointerEvents: 'none',
  },
  paper: {
    pointerEvents: 'auto',
    padding: theme.spacing(1),
    minWidth: 220,
  },
}))

interface ShipmentItemSrNumberProps {
  shipmentId: string
}

export const ShipmentSrNumber: React.FC<ShipmentItemSrNumberProps> = ({
  shipmentId,
}) => {
  const classes = useStyles()
  const shipment = useShipmentSelector(shipmentId)

  const [anchorEl, setAnchorEl] = React.useState(null)

  const handlePopoverOpen = (event: any) => {
    if (!anchorEl && !_.isEmpty(shipment?.srNumber)) {
      setAnchorEl(event.currentTarget)
    } else {
      setAnchorEl(null)
    }
  }

  const handlePopoverClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)

  return (
    <Box>
      <Typography
        aria-owns={open ? 'mouse-over-popover' : undefined}
        aria-haspopup="true"
        style={{ minWidth: 0, fontSize: 'inherit' }}
        onMouseEnter={handlePopoverOpen}
        // onMouseLeave={handlePopoverClose}
      >{shipment?.srNumber}
      </Typography>
      {anchorEl && <Popover
        id="mouse-over-popover"
        className={classes.popover}
        classes={{
          paper: classes.paper,
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        /* transformOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }} */
        onMouseLeave={handlePopoverClose}
        onClose={handlePopoverClose}
      >
          <Box display={'flex'}>
            <Typography>{shipment?.srNumber}</Typography>
            <ButtonGroup>
              <CopyToClipboardButton buttonSize={'small'} size={18} text={shipment?.srNumber} />
              <ShipmentExternalTrackingButton buttonSize={'small'} schema={shipment} />
            </ButtonGroup>
          </Box>
      </Popover>}
    </Box>
  )
}
