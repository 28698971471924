import { Typography } from '@material-ui/core'
import _ from 'lodash'
import React, { useMemo } from 'react'
import { useShipmentItemsForShipmentSelector } from '../../ShipmentItems/Hooks/useShipmentItemsForShipmentSelector'

interface ShipmentDeliveredCountProps {
  shipmentId: string
}

export const ShipmentDeliveredCount: React.FC<ShipmentDeliveredCountProps> = ({
  shipmentId,
}) => {
  const items = useShipmentItemsForShipmentSelector(shipmentId)

  const total = useMemo(() => {
    return _.map(items).length
  }, [items])

  const delivered = useMemo(() => {
    return _.filter(items, item => item.isDelivered).length
  }, [items])

  return (
    <>
      <Typography>{delivered}/{total}</Typography>
    </>
  )
}
