import { colors } from '@material-ui/core'
import _, { stubTrue } from 'lodash'
import React, { useMemo } from 'react'
import Chart from 'react-google-charts'
import { ShipmentStatusService } from '../../../Services/Shipments/ShipmentStatusService'
import { useShipmentItemsForShipmentSelector } from '../../ShipmentItems/Hooks/useShipmentItemsForShipmentSelector'
import { useShipmentSelector } from '../Hooks/useShipmentSelector'

const now = new Date()

interface ShipmentProgressChartProps {
  shipmentId: string
  size?: number
}

export const ShipmentStatusChart: React.FC<ShipmentProgressChartProps> = ({
  shipmentId,
  size = 70,
}) => {
  const shipment = useShipmentSelector(shipmentId)
  const items = useShipmentItemsForShipmentSelector(shipmentId)

  const statusSummary = useMemo(() => {
    const statusService = new ShipmentStatusService(
      {
        shipment,
        shipmentItems: items,
      })
    return statusService.shipmentStatusSummary()
  }, [shipment, items])

  return (
    <Chart
      height={size}
      width={size}
      chartType="PieChart"
      data={[
        ['Shipments', 'Count'],
        ['Pending', statusSummary.pending ? 1 : 0],
        ['Delivered', statusSummary.delivered ? 1 : 0],
        ['Late', statusSummary.late ? 1 : 0],
        ['Delayed', statusSummary.delayed ? 1 : 0],
        ['In Transit', !statusSummary.delayed && statusSummary.inTransit ? 1 : 0],
      ]}
      options={{
        pieHole: 0.4,
        legend: 'none',
        pieSliceText: 'none',
        // tooltip: { trigger: 'none' },
        slices: {
          0: { color: colors.grey[300] },
          1: { color: colors.green[500] },
          2: { color: colors.red[500] },
          3: { color: colors.orange[600] },
          4: { color: colors.lightBlue[500] },
        },
      }}
    />
  )
}
