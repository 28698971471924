import { Box, Button, createStyles, Grid, makeStyles, Typography } from '@material-ui/core'
import { IsEmail, IsNotEmpty } from 'class-validator'
import {
  useGoogleReCaptcha,
} from 'react-google-recaptcha-v3'
import _ from 'lodash'
import React, { useState } from 'react'
import { useHistory } from 'react-router'
import { IsValidPassword } from './Utils'
import { BaseSchema } from '../Schema/BaseSchema'
import { SchemaTextInput } from '../Components/Inputs/Schema/SchemaTextInput'

class SignupObject extends BaseSchema {
  @IsNotEmpty({ message: 'First Name is required.' })
  firstName: string

  @IsNotEmpty({ message: 'Last Name is required.' })
  lastName: string

  @IsNotEmpty({ message: 'Email is required.' })
  @IsEmail({}, { message: 'Invalid Email.' })
  email: string

  @IsNotEmpty()
  @IsValidPassword({ message: 'Invalid password.The password must be between 8-30 characters and contain at least one uppercase and one number.' })
  password: string

  constructor (data?: Partial<SignupObject>) {
    super()
    _.assign(this, data)
  }
}

export const Signup: React.FC = () => {
  const history = useHistory()
  const styles = useStyles()

  const { executeRecaptcha } = useGoogleReCaptcha()

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [hash, setHash] = useState('')
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [signupObj, setSignupObj] = useState(new SignupObject())

  const handleOnChange = (value: string, field: string) => {
    signupObj.setValue(field, value)
    setHash(signupObj.generateHash())
  }

  const handleOnSignup = () => {
    if (!signupObj || !signupObj.validate()) {
      setHash(signupObj?.generateHash())
      return
    }
    if (executeRecaptcha) {
      executeRecaptcha('Signup').then(challenge => {
        /* login(loginObj.username, loginObj.password, token).then(tokens => {
          dispatch(TokensActions.set(tokens))
        }).catch(() => {
          addErrorMessage('Login failed. Please make sure you have entered correct email and password.')
        })
        console.log('token', token) */
      }).catch(err => {
        console.log('captcha error', err)
      })
    }
    setHash(signupObj?.generateHash())
  }

  const handleOnSubmit = (event: React.FormEvent) => {
    event.preventDefault()
    handleOnSignup()
  }

  return (
    <Box className={styles.container}>
      <form onSubmit={e => handleOnSubmit(e)}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <SchemaTextInput
              label={'First Name'}
              schema={signupObj}
              field={'firstName'}
              onChange={handleOnChange}
            />
          </Grid>
          <Grid item xs={12}>
            <SchemaTextInput
              label={'Last Name'}
              schema={signupObj}
              field={'lastName'}
              onChange={handleOnChange}
            />
          </Grid>
          <Grid item xs={12}>
            <SchemaTextInput
              label={'Email'}
              schema={signupObj}
              field={'email'}
              onChange={handleOnChange}
            />
          </Grid>
          <Grid item xs={12}>
            <SchemaTextInput
              type={'password'}
              label={'Password'}
              schema={signupObj}
              field={'password'}
              onChange={handleOnChange}
            />
          </Grid>
        </Grid>
        <Box margin={1}>
          <Typography variant={'caption'}>*By clicking the "Login" button, you accept our Terms of Service and Privacy Policy.</Typography>
        </Box>
        <Button type={'submit'} color={'primary'} fullWidth variant={'contained'} onClick={handleOnSignup}>Signup</Button>
        <Box marginTop={3} display={'flex'} alignItems={'center'}>
          <Typography>Already have an account?</Typography><Button onClick={() => history.push('/login')} color={'primary'}>Login</Button>
        </Box>
      </form>
      <Box marginTop={1} textAlign={'center'}>
        <Typography style={{ flex: 1, justifySelf: 'center' }}>*No credit card required.</Typography>
      </Box>
    </Box>
  )
}

const useStyles = makeStyles(theme =>
  createStyles({
    container: {
      padding: theme.spacing(3),
    },
  }),
)
