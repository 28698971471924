/**
 * An adapter and slice definition for the redux store
 */

import {
  createEntityAdapter,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit'
import { AppRootState } from '../../AppState/AppStore'
import createUpdateOneReducer from '../../AppState/createUpdateOneRecuder'
import createUpdateManyReducer from '../../AppState/createUpdateManyReducer'
import { ServiceSchema } from '../../Schema/ServiceSchema'
import { ServicesAPI } from '../../Services/API/ServicesAPI'
import createFetchPaginatedEntitiesThunk from '../../AppState/createFetchPaginatedEntitiesThunk'
import createFetchEntityThunk from '../../AppState/createFetchEntityThunk'

const STORE_NAME = 'services'

const fetchEntities = createFetchPaginatedEntitiesThunk<ServiceSchema>(
  STORE_NAME,
  ServicesAPI,
)

const fetchEntity = createFetchEntityThunk<ServiceSchema>(
  STORE_NAME,
  ServicesAPI,
)

const EntityAdapter = createEntityAdapter<ServiceSchema>({
  selectId: (entity) => entity.id,
  sortComparer: (a, b) =>
    a.getSortingValue().localeCompare(b.getSortingValue()),
})

const EntitySlice = createSlice({
  name: 'services',
  initialState: EntityAdapter.getInitialState(),
  reducers: {
    addOne: EntityAdapter.addOne,
    addMany: EntityAdapter.addMany,
    removeOne: EntityAdapter.removeOne,
    removeAll: EntityAdapter.removeAll,
    updateOne: (state, payload: PayloadAction<ServiceSchema>) =>
      createUpdateOneReducer<ServiceSchema>(state, payload),
  },
  extraReducers: (builder) =>
    builder
      .addCase(fetchEntities.fulfilled, (state, action) => {
        createUpdateManyReducer<ServiceSchema>(state, action)
      })
      .addCase(fetchEntity.fulfilled, (state, action) => {
        createUpdateOneReducer<ServiceSchema>(state, action)
      }),
})

export const ServicesActions = EntitySlice.actions
export const ServicesReducer = EntitySlice.reducer
export const ServiceSelectors = EntityAdapter.getSelectors(
  (state: AppRootState) => state.services,
)

export const ServicesThunks = {
  fetchServices: fetchEntities,
  fetchService: fetchEntity,
}
