import PasswordValidator from 'password-validator'
import {
  ValidatorConstraint,
  ValidatorConstraintInterface,
  ValidationArguments,
  ValidationOptions,
  registerDecorator,
} from 'class-validator'

const passwordValidator = new PasswordValidator()
passwordValidator
  .is()
  .min(8)
  .is()
  .max(30)
  .has()
  .uppercase()
  .has()
  .lowercase()
  .has()
  .digits()
  .has()
  .not()
  .spaces()
  .is()
  .not()
  .oneOf(['Passw0rd', 'Password123', 'P@ssw0rd'])

@ValidatorConstraint({ name: 'IsValidPassword', async: false })
export class IsValidPasswordConstraint implements ValidatorConstraintInterface {
  validate (password: any, args?: ValidationArguments) {
    if (!password) {
      return true
    }
    return !!passwordValidator.validate(password)
  }
}

export function IsValidPassword (validationOptions?: ValidationOptions) {
  return function (object: Object, propertyName: string) {
    registerDecorator({
      target: object.constructor,
      propertyName: propertyName,
      options: validationOptions,
      constraints: [],
      validator: IsValidPasswordConstraint,
    })
  }
}
