import React from 'react'
import { AppDrawer, AppDrawerToolbar, AppDrawerContent, AppDrawerActions } from '../../Components/AppDrawer'
import { Box, Button, CircularProgress, List, Typography } from '@material-ui/core'
import { useAppStyles } from '../../Hooks/useAppStyles'
import { useUpdateLab } from './Hooks/useUpdateLab'
import { useLabSelector } from './Hooks/useLabSelector'
import { ContentContainer } from '../../Components/Content/ContentContainer'
import { ContentItem } from '../../Components/Content/ContentItem'
import { useLabContactsForLabSelector } from '../LabContacts/Hooks/useLabContactsForLabSelector'
import _ from 'lodash'
import { LabContactTag } from '../LabContacts/Components/LabContactTag'
import { useHistory } from 'react-router'
import { AppPermission, useAppPermissions } from '../../Hooks/useAppPermissions'
import { EditIconButton } from '../../Components/Buttons/EditIconButton'
import { LabTag } from './Components/LabTag'
import { DeleteLabButton } from './Components/DeleteLabButton'

interface LabViewDrawerProps {
  labId: string
  open: boolean
  onClose: () => void,
}

export const LabViewDrawer: React.FC<LabViewDrawerProps> = ({
  open,
  onClose,
  labId,
}) => {
  const { checkPermissions } = useAppPermissions()
  const appStyles = useAppStyles()
  const history = useHistory()

  const lab = useLabSelector(labId)
  const labContacts = useLabContactsForLabSelector(labId)

  return (
    <AppDrawer
      width={400}
      open={open}
      onClose={onClose}
    >
      <AppDrawerToolbar>
        <LabTag labId={labId} />
        <EditIconButton size={'small'} permissions={AppPermission.LabEdit} onClick={() => history.push(`/lab-management/labs/${labId}/edit`)} />
        <DeleteLabButton schema={lab} onDeleted={onClose} />
      </AppDrawerToolbar>
      <AppDrawerContent>
        <Box className={appStyles.content}>
        {!lab && <CircularProgress />}
        {lab && (
          <>
            <ContentContainer title={'Lab'}>
              <ContentItem title={'Name'}>
                <Typography>{lab.name}</Typography>
              </ContentItem>
            </ContentContainer>

            <ContentContainer title={'Contacts'}>
              <List>
                {_.map(labContacts, labContact => (
                  <LabContactTag
                    button
                    key={labContact.id}
                    labContactId={labContact.id}
                    onClick={() => history.push(`/lab-management/contacts/${labContact.id}`)}
                  />
                ))}
              </List>
            </ContentContainer>
          </>
        )}
        </Box>
      </AppDrawerContent>
      <AppDrawerActions>
        <Button color={'primary'} onClick={onClose}>Close</Button>
      </AppDrawerActions>
    </AppDrawer>
  )
}
