/**
 * An adapter and slice definition for the redux store
 */

import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit'
import { AppRootState } from '../../AppState/AppStore'
import createUpdateManyReducer from '../../AppState/createUpdateManyReducer'
import createUpdateOneReducer from '../../AppState/createUpdateOneRecuder'
import { CarrierSchema } from '../../Schema/CarrierSchema'
import { CarriersAPI } from '../../Services/API/CarriersAPI'
import { ServiceLocator } from '../../Services/ServiceLocator'

const STORE_NAME = 'carriers'

const fetchEntities = createAsyncThunk(
  `${STORE_NAME}/fetchEntities`,
  async () => {
    let entities: CarrierSchema[] = []
    try {
      const response = await ServiceLocator.resolve(
        CarriersAPI,
      ).fetchPaginatedEntityList()
      entities = response.pageItems
    } catch (err) {
      console.log(`${STORE_NAME}/fetchEntities`, err)
    }
    return entities
  },
)

const fetchEntity = createAsyncThunk(
  `${STORE_NAME}/fetchEntity`,
  async (entityId: string) => {
    let service: CarrierSchema = null
    try {
      service = await ServiceLocator.resolve(CarriersAPI).getEntity(entityId)
    } catch (err) {
      console.log(`${STORE_NAME}/fetchEntity`, err)
    }
    return service
  },
)

const EntityAdapter = createEntityAdapter<CarrierSchema>({
  selectId: (entity) => entity.id,
  sortComparer: (a, b) =>
    a.getSortingValue().localeCompare(b.getSortingValue()),
})

const EntitySlice = createSlice({
  name: 'carriers',
  initialState: EntityAdapter.getInitialState(),
  reducers: {
    addOne: EntityAdapter.addOne,
    addMany: EntityAdapter.addMany,
    removeOne: EntityAdapter.removeOne,
    removeAll: EntityAdapter.removeAll,
    updateOne: (state, payload: PayloadAction<CarrierSchema>) =>
      createUpdateOneReducer<CarrierSchema>(state, payload),
  },
  extraReducers: (builder) =>
    builder
      .addCase(fetchEntities.fulfilled, (state, action) => {
        createUpdateManyReducer<CarrierSchema>(state, action)
      })
      .addCase(fetchEntity.fulfilled, (state, action) => {
        createUpdateOneReducer<CarrierSchema>(state, action)
      }),
})

export const CarriersActions = EntitySlice.actions
export const CarriersReducer = EntitySlice.reducer
export const CarriersSelectors = EntityAdapter.getSelectors(
  (state: AppRootState) => state.carriers,
)
export const CarriersThunks = {
  fetchCarriers: fetchEntities,
  fetchCarrier: fetchEntity,
}
