import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'

import { ChargeCodesReducer } from '../App/ChargeCodes/StoreAdapter'
import { UsersReducer } from '../App/Users/StoreAdapter'
import { LabsReducer } from '../App/Labs/StoreAdapter'
import { LabContactsReducer } from '../App/LabContacts/StoreAdapter'
import { CarriersReducer } from '../App/Carriers/StoreAdapter'
import { ServicesReducer } from '../App/Services/StoreAdapter'
import { ConsigneesReducer } from '../App/Consignees/StoreAdapter'
import { ContainersReducer } from '../App/Containers/StoreAdapter'
import { ShipmentsReducer } from '../App/Shippments/StoreAdapter'
import { AuthReducer } from '../Auth/StoreAdapter'
import { ShipmentItemsReducer } from '../App/ShipmentItems/StoreAdapter'
import { ShipmentFiltersReducer } from '../App/Shippments/StoreFilterAdapter'
import { DelaysReducer } from '../App/Delays/StoreAdapter'

export const AppStore = configureStore({
  reducer: {
    auth: AuthReducer,
    users: UsersReducer,
    chargeCodes: ChargeCodesReducer,
    labs: LabsReducer,
    labContacts: LabContactsReducer,
    carriers: CarriersReducer,
    services: ServicesReducer,
    consignees: ConsigneesReducer,
    containers: ContainersReducer,
    shipments: ShipmentsReducer,
    shipmentFilters: ShipmentFiltersReducer,
    shipmentItems: ShipmentItemsReducer,
    delays: DelaysReducer,
  },
  middleware: getDefaultMiddleware({
    serializableCheck: false,
  }),
})

export type AppRootState = ReturnType<typeof AppStore.getState>
export type AppDispatch = typeof AppStore.dispatch
