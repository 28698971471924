/**
 * An adapter and slice definition for the redux store
 */

import {
  createEntityAdapter,
  createSlice,
  EntityState,
  PayloadAction,
} from '@reduxjs/toolkit'
import { plainToClass } from 'class-transformer'
import { AppRootState } from '../../AppState/AppStore'
import createFetchEntitiesThunk from '../../AppState/createFetchEntitiesThunk'
import createFetchEntityThunk from '../../AppState/createFetchEntityThunk'
import createFetchPaginatedEntitiesThunk from '../../AppState/createFetchPaginatedEntitiesThunk'
import createUpdateManyReducer from '../../AppState/createUpdateManyReducer'
import createUpdateOneReducer from '../../AppState/createUpdateOneRecuder'
import { ShipmentSchema } from '../../Schema/ShipmentSchema'
import { ShipmentsAPI } from '../../Services/API/ShipmentsAPI'

const STORE_NAME = 'shipments'

const fetchEntities = createFetchPaginatedEntitiesThunk<ShipmentSchema>(
  STORE_NAME,
  ShipmentsAPI,
)
const fetchEntity = createFetchEntityThunk<ShipmentSchema>(
  STORE_NAME,
  ShipmentsAPI,
)

const EntityAdapter = createEntityAdapter<ShipmentSchema>({
  selectId: (entity) => entity.id,
  sortComparer: (a, b) =>
    a.getSortingValue().localeCompare(b.getSortingValue()),
})

const EntitySlice = createSlice({
  name: STORE_NAME,
  initialState: EntityAdapter.getInitialState(),
  reducers: {
    addOne: EntityAdapter.addOne,
    addMany: EntityAdapter.addMany,
    removeOne: EntityAdapter.removeOne,
    removeAll: EntityAdapter.removeAll,
    updateOne: (state, payload: PayloadAction<ShipmentSchema>) =>
      createUpdateOneReducer<ShipmentSchema>(state, payload),
  },
  extraReducers: (builder) =>
    builder
      .addCase(fetchEntities.fulfilled, (state, action) => {
        createUpdateManyReducer<ShipmentSchema>(state, action)
      })
      .addCase(fetchEntity.fulfilled, (state, action) => {
        createUpdateOneReducer<ShipmentSchema>(state, action)
      }),
})

export const ShipmentsActions = EntitySlice.actions
export const ShipmentsReducer = EntitySlice.reducer
export const ShipmentSelectors = EntityAdapter.getSelectors(
  (state: AppRootState) => state.shipments,
)
export const ShipmentThunks = {
  fetchShipments: fetchEntities,
  fetchShipment: fetchEntity,
}
