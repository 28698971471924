import React from 'react'
import { SnackbarProvider } from 'notistack'

export const AppSnackbar: React.FC = ({
  children,
}) => {
  return (
    <SnackbarProvider>
      {children}
    </SnackbarProvider>
  )
}
