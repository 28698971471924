/**
 * An adapter and slice definition for the redux store
 */

import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit'
import { AppRootState } from '../../AppState/AppStore'
import createUpdateManyReducer from '../../AppState/createUpdateManyReducer'
import createUpdateOneReducer from '../../AppState/createUpdateOneRecuder'
import { DelaySchema } from '../../Schema/DelaySchema'
import { DelaysAPI } from '../../Services/API/DelaysAPI'
import { ServiceLocator } from '../../Services/ServiceLocator'

const STORE_NAME = 'delays'

const fetchEntities = createAsyncThunk(
  `${STORE_NAME}/fetchEntities`,
  async () => {
    let entities: DelaySchema[] = []
    try {
      const response = await ServiceLocator.resolve(
        DelaysAPI,
      ).fetchPaginatedEntityList({ limit: 100 })
      entities = response.pageItems
    } catch (err) {
      console.log(`${STORE_NAME}/fetchEntities`, err)
    }
    return entities
  },
)

const fetchEntity = createAsyncThunk(
  `${STORE_NAME}/fetchEntity`,
  async (entityId: string) => {
    let service: DelaySchema = null
    try {
      service = await ServiceLocator.resolve(DelaysAPI).getEntity(entityId)
    } catch (err) {
      console.log(`${STORE_NAME}/fetchEntity`, err)
    }
    return service
  },
)

const EntityAdapter = createEntityAdapter<DelaySchema>({
  selectId: (entity) => entity.id,
  sortComparer: (a, b) =>
    a.getSortingValue().localeCompare(b.getSortingValue()),
})

const EntitySlice = createSlice({
  name: STORE_NAME,
  initialState: EntityAdapter.getInitialState(),
  reducers: {
    addOne: EntityAdapter.addOne,
    addMany: EntityAdapter.addMany,
    removeOne: EntityAdapter.removeOne,
    removeAll: EntityAdapter.removeAll,
    updateOne: (state, payload: PayloadAction<DelaySchema>) =>
      createUpdateOneReducer<DelaySchema>(state, payload),
  },
  extraReducers: (builder) =>
    builder
      .addCase(fetchEntities.fulfilled, (state, action) => {
        createUpdateManyReducer<DelaySchema>(state, action)
      })
      .addCase(fetchEntity.fulfilled, (state, action) => {
        createUpdateOneReducer<DelaySchema>(state, action)
      }),
})

export const DelaysActions = EntitySlice.actions
export const DelaysReducer = EntitySlice.reducer
export const DelaysSelectors = EntityAdapter.getSelectors(
  (state: AppRootState) => state.delays,
)
export const DelaysThunks = {
  fetchDelays: fetchEntities,
  fetchDelay: fetchEntity,
}
