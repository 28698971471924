import { Box, Button, Paper, useTheme } from '@material-ui/core'
import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router'
import { useAppTabs } from '../../Components/AppTabs/useAppTabs'
import { AppToolbar } from '../../Components/AppToolbar'
import { useAppStyles } from '../../Hooks/useAppStyles'
import { CharegeCodesDataGrid } from './Components/CarriersDataGrid'
import { useCarriersSelector } from './Hooks/useCarriersSelector'
import { CarrierAddDrawer } from './CarrierAddDrawer'
import { CarrierEditDrawer } from './CarrierEditDrawer'
import { AppPermission, useAppPermissions } from '../../Hooks/useAppPermissions'

export const Carriers: React.FC = () => {
  const theme = useTheme()
  const appStyles = useAppStyles()
  const appTabs = useAppTabs()
  const history = useHistory()
  const { checkPermissions } = useAppPermissions()

  const { entityId: paramEntityId } = useParams<{entityId: string}>()

  const carriers = useCarriersSelector()

  const [openAdd, setOpenAdd] = useState(false)
  const [editSchemaId, setEditSchemaId] = useState<string>(null)

  useEffect(() => {
    setEditSchemaId(paramEntityId)
  }, [paramEntityId])

  useEffect(() => {
    if (appTabs) {
      appTabs.select('carriers')
    }
  }, [appTabs])

  return (
    <Box className={appStyles.tabContent}>
      <AppToolbar>
        <Box flex={1} />
        {checkPermissions(AppPermission.CarrierAdd) && <Button variant={'contained'} color={'primary'} onClick={() => setOpenAdd(true)}>Add Carrier</Button>}
      </AppToolbar>
      <Paper style={{ height: 'calc(100vh - 200px)', padding: theme.spacing(1) }}>
        <CharegeCodesDataGrid entities={carriers} />
      </Paper>
      <CarrierAddDrawer
        open={openAdd}
        onClose={() => setOpenAdd(false)}
      />
      {checkPermissions(AppPermission.CarrierEdit) && <CarrierEditDrawer
        open={Boolean(editSchemaId)}
        schemaId={editSchemaId}
        onClose={() => history.push('/settings/carriers')}
      />}
    </Box>
  )
}
