import { Tab, Tabs } from '@material-ui/core'
import React, { useState } from 'react'
import { useHistory } from 'react-router'

export interface AppTabProps {
  value: string,
  route: string,
  label: string,
}

interface AppTabsContextProps {
  selected?: string,
  select: (value: string) => void
  hidden?: boolean
}

export const AppTabsContext = React.createContext<AppTabsContextProps>({
  selected: null,
  select: (value: string) => {},
  hidden: true,
})

interface AppTabsProps {
  tabs: AppTabProps[],
  selected?: string,
}

export const AppTabs: React.FC<AppTabsProps> = ({
  tabs,
  selected,
  children,
}) => {
  const history = useHistory()
  const [selectedTab, selectTab] = useState(selected)

  const context: AppTabsContextProps = {
    selected: selectedTab,
    select: selectTab,
  }

  return (
    <AppTabsContext.Provider value={context}>
      <Tabs
          scrollButtons="auto"
          variant="scrollable"
          indicatorColor="primary"
          textColor="primary"
          value={selectedTab}
          onChange={(event: any, value: string) => selectTab(value)}
        >
          {tabs.map((tab) => (
            <Tab
              key={tab.value}
              label={tab.label}
              value={tab.value}
              onClick={() => history.push(tab.route)}
            />
          ))}
      </Tabs>
      {children}
    </AppTabsContext.Provider>
  )
}
