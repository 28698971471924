import _ from 'lodash'
import { useCallback, useMemo } from 'react'
import { PartialRecord } from '../Utils/Types'

export enum AppPermission {
  CarrierAdd = 'Carrier.add',
  CarrierEdit = 'Carrier.edit',
  CarrierDelete = 'Carrier.delete',

  ChargeCodeAdd = 'ChargeCode.add',
  ChargeCodeEdit = 'ChargeCode.edit',
  ChargeCodeDelete = 'ChargeCode.delete',

  ConsigneeAdd = 'Consignee.add',
  ConsigneeEdit = 'Consignee.edit',
  ConsigneeDelete = 'Consignee.delete',

  ContainerAdd = 'Container.add',
  ContainerEdit = 'Container.edit',
  ContainerDelete = 'Container.delete',

  DelayAdd = 'Delay.add',
  DelayEdit = 'Delay.edit',
  DelayDelete = 'Delay.delete',

  LabAdd = 'Lab.add',
  LabEdit = 'Lab.edit',
  LabDelete = 'Lab.delete',
  LabDisable = 'Lab.disable',

  LabContactAdd = 'LabContact.add',
  LabContactEdit = 'LabContact.edit',
  LabContactDelete = 'LabContact.delete',
  LabContactDisable = 'LabContact.disable',

  ServiceAdd = 'Service.add',
  ServiceEdit = 'Service.edit',
  ServiceDelete = 'Service.delete',

  ShipmentAdd = 'Shipment.add',
  ShipmentEdit = 'Shipment.edit',
  ShipmentDelete = 'Shipment.delete',
  ShipmentExport = 'Shipment.export',

  ShipmentItemAdd = 'ShipmentItem.add',
  ShipmentItemEdit = 'ShipmentItem.edit',
  ShipmentItemDelete = 'ShipmentItem.delete',
}

const StaffPermissions: Record<AppPermission, boolean> = {
  [AppPermission.CarrierAdd]: false,
  [AppPermission.CarrierEdit]: false,
  [AppPermission.CarrierDelete]: false,

  [AppPermission.ChargeCodeAdd]: false,
  [AppPermission.ChargeCodeEdit]: false,
  [AppPermission.ChargeCodeDelete]: false,

  [AppPermission.ConsigneeAdd]: true,
  [AppPermission.ConsigneeEdit]: true,
  [AppPermission.ConsigneeDelete]: false,

  [AppPermission.ContainerAdd]: true,
  [AppPermission.ContainerEdit]: false,
  [AppPermission.ContainerDelete]: false,

  [AppPermission.DelayAdd]: false,
  [AppPermission.DelayEdit]: false,
  [AppPermission.DelayDelete]: false,

  [AppPermission.LabAdd]: true,
  [AppPermission.LabEdit]: false,
  [AppPermission.LabDelete]: false,
  [AppPermission.LabDisable]: false,

  [AppPermission.LabContactAdd]: true,
  [AppPermission.LabContactEdit]: false,
  [AppPermission.LabContactDelete]: false,
  [AppPermission.LabContactDisable]: false,

  [AppPermission.ServiceAdd]: false,
  [AppPermission.ServiceEdit]: false,
  [AppPermission.ServiceDelete]: false,

  [AppPermission.ShipmentAdd]: true,
  [AppPermission.ShipmentEdit]: true,
  [AppPermission.ShipmentDelete]: false,
  [AppPermission.ShipmentExport]: false,

  [AppPermission.ShipmentItemAdd]: true,
  [AppPermission.ShipmentItemEdit]: true,
  [AppPermission.ShipmentItemDelete]: false,
}

const AdminPermissions: PartialRecord<AppPermission, boolean> = {
  [AppPermission.CarrierAdd]: true,
  [AppPermission.CarrierEdit]: true,
  [AppPermission.CarrierDelete]: true,

  [AppPermission.ChargeCodeAdd]: true,
  [AppPermission.ChargeCodeEdit]: true,
  [AppPermission.ChargeCodeDelete]: true,

  [AppPermission.ConsigneeDelete]: true,

  [AppPermission.ContainerEdit]: true,
  [AppPermission.ContainerDelete]: true,

  [AppPermission.DelayAdd]: true,
  [AppPermission.DelayEdit]: true,
  [AppPermission.DelayDelete]: true,

  [AppPermission.LabEdit]: true,
  [AppPermission.LabDelete]: true,
  [AppPermission.LabDisable]: true,

  [AppPermission.LabContactEdit]: true,
  [AppPermission.LabContactDelete]: true,
  [AppPermission.LabContactDisable]: true,

  [AppPermission.ServiceAdd]: true,
  [AppPermission.ServiceEdit]: true,
  [AppPermission.ServiceDelete]: true,

  [AppPermission.ShipmentDelete]: true,
  [AppPermission.ShipmentExport]: true,

  [AppPermission.ShipmentItemDelete]: true,
}

export const useAppPermissions = () => {
  const permissions = useMemo(() => {
    return _.merge({}, StaffPermissions, AdminPermissions)
  }, [])

  const checkPermissions = useCallback(
    (check: AppPermission | AppPermission[]) => {
      if (_.isArray(check)) {
        for (const permission of check) {
          const granted = Boolean(_.get(permissions, permission))
          if (!granted) {
            return false
          }
        }
        return true
      } else {
        return Boolean(_.get(permissions, check))
      }
    },
    [permissions],
  )

  return {
    permissions,
    checkPermissions,
  }
}
