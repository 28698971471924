import { useAppSelector } from '../../../Hooks/useAppSelector'
import { UsersSelectors } from '../StoreAdapter'

export const useUserSelector = (entityId: string) => {
  const entityState = useAppSelector((state) =>
    UsersSelectors.selectById(state, entityId),
  )

  return entityState
}
