import React from 'react'
import { EntityTag } from '../../../Components/EntityTag'
import { useLabSelector } from '../Hooks/useLabSelector'

interface LabTagProps {
  labId: string
  avatarSize?: 'small' | 'medium' | 'large'
  button?: true
  onClick?: Function
  showAvatar?: boolean
}

export const LabTag: React.FC<LabTagProps> = ({
  labId,
  avatarSize,
  onClick = (event: MouseEvent) => {},
  showAvatar = false,
}) => {
  const entity = useLabSelector(labId)

  return (
    <EntityTag
      onClick={event => onClick(event)}
      primary={entity?.name}
      avatarSize={avatarSize}
      showAvatar={showAvatar}
  />
  )
}
