import { Box, IconButton, Tooltip } from '@material-ui/core'
import React from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import ClipboardIcon from '@material-ui/icons/FileCopyOutlined'
import { useSnackbarMessages } from './AppSnackbar/Hooks/useSnackbarMessages'

interface CopyToClipboardButtonProps {
  text: string
  onCopy?: () => void
  buttonSize?: 'small' | 'medium'
  size?: number
}

export const CopyToClipboardButton: React.FC<CopyToClipboardButtonProps> = ({
  text,
  onCopy = () => {},
  buttonSize = 'medium',
  size = 24,
}) => {
  const { addSuccessMessage } = useSnackbarMessages()
  const handleOnCopy = () => {
    addSuccessMessage('Added to Clipboard successfully.')
    if (onCopy) {
      onCopy()
    }
  }
  return (
    <Box onClick={(e) => {
      e.stopPropagation()
    }}>
      <Tooltip title={'Copy to Clipboard'}>
        <CopyToClipboard text={text} onCopy={handleOnCopy}><IconButton size={buttonSize}><ClipboardIcon style={{ fontSize: 'inherit' }} /></IconButton></CopyToClipboard>
      </Tooltip>
    </Box>
  )
}
