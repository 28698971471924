import React, { useCallback, useState } from 'react'
import { AppDrawer, AppDrawerToolbar, AppDrawerContent, AppDrawerActions } from '../../Components/AppDrawer'
import { v4 as uuid } from 'uuid'
import { Box, Button, Paper, Typography } from '@material-ui/core'
import { useAppStyles } from '../../Hooks/useAppStyles'
import { useAddService } from './Hooks/useAddServcie'
import { ServiceSchema } from '../../Schema/ServiceSchema'
import { ServiceForm } from './Components/ServiceForm'
import { useSnackbarMessages } from '../../Components/AppSnackbar/Hooks/useSnackbarMessages'

interface ServiceAddDrawerProps {
  open: boolean
  onClose: (schema?: ServiceSchema) => void,
  defaultData?: Partial<ServiceSchema>
}

export const ServiceAddDrawer: React.FC<ServiceAddDrawerProps> = ({
  open,
  onClose,
  defaultData,
}) => {
  const { addSuccessMessage, addErrorMessage } = useSnackbarMessages()
  const appStyles = useAppStyles()
  const { addService, processing } = useAddService()

  const [hash, setHash] = useState('')
  const [schema, setSchema] = React.useState(new ServiceSchema(defaultData))

  const handleOnSchemaChanged = useCallback((schema: ServiceSchema) => {
    setHash(uuid())
  }, [])

  const handleOnSave = useCallback(() => {
    if (schema.validate()) {
      addService(schema).then(result => {
        addSuccessMessage('Service added successfully.')
        setSchema(new ServiceSchema(defaultData))
        onClose(result)
      }).catch(() => {
        addErrorMessage('Failed to add the service.')
      })
    }
    setHash(uuid())
  }, [schema, addService, onClose, addErrorMessage, addSuccessMessage, defaultData])

  return (
    <AppDrawer
      width={400}
      open={open}
      onClose={onClose}
    >
      <AppDrawerToolbar>
        <Typography variant={'h5'}>Add Service</Typography>
      </AppDrawerToolbar>
      <AppDrawerContent>
        <Box className={appStyles.content}>
          <Paper className={appStyles.content}>
            <ServiceForm schema={schema} onChange={handleOnSchemaChanged} />
          </Paper>
        </Box>
      </AppDrawerContent>
      <AppDrawerActions>
        <Button disabled={processing} color={'primary'} onClick={handleOnSave}>Save</Button>
      </AppDrawerActions>
    </AppDrawer>
  )
}
