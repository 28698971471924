import { Box, Button, Paper, useTheme } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router'
import { useAppTabs } from '../../Components/AppTabs/useAppTabs'
import { AppToolbar } from '../../Components/AppToolbar'
import { useAppStyles } from '../../Hooks/useAppStyles'
import { CharegeCodesDataGrid } from './Components/DelaysDataGrid'
import { useDelaysSelector } from './Hooks/useDelaysSelector'
import { DelayAddDrawer } from './DelayAddDrawer'
import { DelayEditDrawer } from './DelayEditDrawer'
import { AppPermission, useAppPermissions } from '../../Hooks/useAppPermissions'

export const Delays: React.FC = () => {
  const theme = useTheme()
  const appStyles = useAppStyles()
  const appTabs = useAppTabs()
  const history = useHistory()
  const { checkPermissions } = useAppPermissions()

  const { entityId: paramEntityId } = useParams<{entityId: string}>()

  const delays = useDelaysSelector()

  const [openAdd, setOpenAdd] = useState(false)
  const [editSchemaId, setEditSchemaId] = useState<string>(null)

  useEffect(() => {
    setEditSchemaId(paramEntityId)
  }, [paramEntityId])

  useEffect(() => {
    if (appTabs) {
      appTabs.select('delays')
    }
  }, [appTabs])

  return (
    <Box className={appStyles.tabContent}>
      <AppToolbar>
        <Box flex={1} />
        {checkPermissions(AppPermission.DelayAdd) && <Button variant={'contained'} color={'primary'} onClick={() => setOpenAdd(true)}>Add Delay</Button>}
      </AppToolbar>
      <Paper style={{ height: 'calc(100vh - 200px)', padding: theme.spacing(1) }}>
        <CharegeCodesDataGrid entities={delays} />
      </Paper>
      <DelayAddDrawer
        open={openAdd}
        onClose={() => setOpenAdd(false)}
      />
      {checkPermissions(AppPermission.DelayEdit) && <DelayEditDrawer
        open={Boolean(editSchemaId)}
        schemaId={editSchemaId}
        onClose={() => history.push('/settings/delays')}
      />}
    </Box>
  )
}
