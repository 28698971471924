import React from 'react'
import { EntityTag } from '../../../Components/EntityTag'
import { useServiceSelector } from '../Hooks/useServiceSelector'

interface ServiceTagProps {
  serviceId: string
  avatarSize?: 'small' | 'medium' | 'large'
  showAvatar?: boolean
  showDescription?: boolean
  fontSize?: number
}

export const ServiceTag: React.FC<ServiceTagProps> = ({
  serviceId,
  avatarSize,
  showAvatar = false,
  showDescription,
  fontSize,
}) => {
  const entity = useServiceSelector(serviceId)

  return (
    <EntityTag
      primary={entity?.name}
      secondary={showDescription ? entity?.notation : undefined}
      avatarSize={avatarSize}
      showAvatar={showAvatar}
      fontSize={fontSize}
    />
  )
}
