import { useCallback, useState } from 'react'
import { useMounted } from '../../../Hooks/useMounted'
import { useAppDispatch } from '../../../Hooks/useAppDispatch'
import { ShipmentsActions } from '../StoreAdapter'
import { ShipmentSchema } from '../../../Schema/ShipmentSchema'
import { ServiceLocator } from '../../../Services/ServiceLocator'
import { ShipmentsAPI } from '../../../Services/API/ShipmentsAPI'

export const useUpdateShipment = () => {
  const dispatch = useAppDispatch()
  const mounted = useMounted()

  const [processing, setProcessing] = useState(false)
  const [errors, setErrors] = useState<
    Record<string, string> | Record<string, string>[]
  >(null)
  const [errorMessage, setErrorMessage] = useState<string>(null)

  const updateShipment = useCallback(
    async (schema: ShipmentSchema): Promise<ShipmentSchema> => {
      setProcessing(true)
      return ServiceLocator.resolve(ShipmentsAPI)
        .updateEntity(schema)
        .then((schema) => {
          dispatch(ShipmentsActions.updateOne(schema))
          if (mounted.current) {
            setProcessing(false)
            setErrors(null)
            setErrorMessage(null)
          }
          return schema
        })
        .catch((err) => {
          if (mounted.current) {
            setProcessing(false)
            setErrorMessage(err?.response?.data?.errorMessage)
            setErrors(err?.response?.data?.errors)
            throw err
          }
          return null
        })
    },
    [dispatch, mounted],
  )

  return {
    processing,
    errors,
    errorMessage,
    updateShipment,
  }
}
