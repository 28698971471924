import { Grid } from '@material-ui/core'
import React, { useCallback } from 'react'
import { SchemaAutocompleteInput } from '../../../Components/Inputs/Schema/SchemaAutocompleteInput'
import { SchemaSwitchInput } from '../../../Components/Inputs/Schema/SchemaSwitchInput'
import { SchemaTextInput } from '../../../Components/Inputs/Schema/SchemaTextInput'
import { InputOptionProps } from '../../../Components/Inputs/Types'
import { ConsigneeType } from '../../../Schema/ConsigneeSchema'
import { ServiceSchema } from '../../../Schema/ServiceSchema'
import { SchemaNumberInput } from '../../../Components/Inputs/Schema/SchemaNumberInput'
import { SchemaYesNoPicker } from '../../../Components/Inputs/Schema/SchemaYesNoPicker'

const ConsigneeTypeOptions: InputOptionProps[] = [
  {
    label: 'International',
    value: ConsigneeType.International,
  },
  {
    label: 'Domestic',
    value: ConsigneeType.Domestic,
  },
]

interface ServiceFormProps {
  schema: ServiceSchema
  onChange: (schema: ServiceSchema) => void
}

export const ServiceForm: React.FC<ServiceFormProps> = ({
  schema,
  onChange,
}) => {
  const handleOnSchemaChanged = useCallback((value: any, field: string) => {
    schema.setValue(field, value)
    onChange(schema)
  }, [onChange, schema])

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <SchemaAutocompleteInput
          label={'Consignee Type'}
          placeholder={'Select a consignee type'}
          schema={schema}
          options={ConsigneeTypeOptions}
          field={'type'}
          onChange={handleOnSchemaChanged}
        />
      </Grid>
      <Grid item xs={12}>
        <SchemaTextInput
          label={'Name'}
          placeholder={'Enter a service name'}
          schema={schema}
          field={'name'}
          required
          onChange={handleOnSchemaChanged}
        />
      </Grid>
      <Grid item xs={12}>
        <SchemaTextInput
          required
          label={'Notation'}
          placeholder={'Enter a delivery ETA notation'}
          schema={schema}
          field={'notation'}
          onChange={handleOnSchemaChanged}
        />
      </Grid>
      <Grid item xs={12}>
        <SchemaNumberInput
          label={'Min. Transit Days'}
          schema={schema}
          field={'minTransitDays'}
          onChange={handleOnSchemaChanged}
        />
      </Grid>
      <Grid item xs={12}>
        <SchemaNumberInput
          label={'Max. Transit Days'}
          schema={schema}
          field={'maxTransitDays'}
          onChange={handleOnSchemaChanged}
        />
      </Grid>
      <Grid item xs={12}>
        <SchemaSwitchInput
          label={'Courier Enabled?'}
          schema={schema}
          field={'courierEnabled'}
          onChange={handleOnSchemaChanged}
        />
      </Grid>
      <Grid item xs={12}>
        <SchemaYesNoPicker
          label={'Courier Default'}
          schema={schema}
          field={'courierDefault'}
          onChange={handleOnSchemaChanged}
        />
      </Grid>
      <Grid item xs={12}>
        <SchemaSwitchInput
          label={'Is Enabled?'}
          schema={schema}
          field={'isEnabled'}
          onChange={handleOnSchemaChanged}
        />
      </Grid>
    </Grid>
  )
}
