import _ from 'lodash'
import { useMemo } from 'react'
import { useAppSelector } from '../../../Hooks/useAppSelector'

export const useChargeCodesSelector = () => {
  const state = useAppSelector((state) => state.chargeCodes)

  const chargeCodes = useMemo(() => {
    return _.map(state.entities)
  }, [state])

  return chargeCodes
}
