import React, { useCallback, useEffect, useState } from 'react'
import { AppDrawer, AppDrawerToolbar, AppDrawerContent, AppDrawerActions } from '../../Components/AppDrawer'
import { LabSchema } from '../../Schema/LabSchema'
import { LabForm } from './Components/LabForm'
import { v4 as uuid } from 'uuid'
import { Box, Button, CircularProgress, Paper, Typography } from '@material-ui/core'
import { useAppStyles } from '../../Hooks/useAppStyles'
import { useUpdateLab } from './Hooks/useUpdateLab'
import { BaseSchema } from '../../Schema/BaseSchema'
import { useLabSelector } from './Hooks/useLabSelector'
import { useSnackbarMessages } from '../../Components/AppSnackbar/Hooks/useSnackbarMessages'

interface LabEditDrawerProps {
  labId: string
  open: boolean
  onClose: () => void,
}

export const LabEditDrawer: React.FC<LabEditDrawerProps> = ({
  open,
  onClose,
  labId,
}) => {
  const { addSuccessMessage, addErrorMessage } = useSnackbarMessages()
  const appStyles = useAppStyles()
  const { updateLab, processing } = useUpdateLab()

  const labState = useLabSelector(labId)

  const [hash, setHash] = useState('')
  const [lab, setLab] = React.useState<LabSchema>(null)

  const handleOnSchemaChanged = useCallback((schema: LabSchema) => {
    setHash(uuid())
  }, [])

  useEffect(() => {
    if (!labState || !(labState instanceof BaseSchema)) {
      return
    }
    setLab(labState.clone())
  }, [labState])

  const handleOnSave = useCallback(() => {
    if (lab.validate()) {
      updateLab(lab).then(() => {
        addSuccessMessage('Lab updated successfully.')
        onClose()
      }).catch(() => {
        addErrorMessage('Failed to update the lab.')
      })
    }
    setHash(uuid())
  }, [lab, updateLab, onClose, addSuccessMessage, addErrorMessage])

  return (
    <AppDrawer
      width={400}
      open={open}
      onClose={onClose}
    >
      <AppDrawerToolbar>
        <Typography variant={'h5'}>Edit Lab</Typography>
      </AppDrawerToolbar>
      <AppDrawerContent>
        <Box className={appStyles.content}>
          <Paper className={appStyles.content}>
            {!lab && <CircularProgress />}
            {lab && <LabForm schema={lab} onChange={handleOnSchemaChanged} />}
          </Paper>
        </Box>
      </AppDrawerContent>
      <AppDrawerActions>
        <Button disabled={processing} color={'primary'} onClick={handleOnSave}>Save</Button>
      </AppDrawerActions>
    </AppDrawer>
  )
}
