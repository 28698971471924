import React, { useMemo } from 'react'
import { Tooltip, List, ListItem, ListItemIcon, ListItemText, makeStyles, Divider, useTheme, Avatar } from '@material-ui/core'

import { useHistory, useLocation } from 'react-router-dom'

const useTooltipStyles = makeStyles((theme) => ({
  arrow: {
    color: theme.palette.common.black,
  },
  tooltip: {
    backgroundColor: theme.palette.common.black,
    fontSize: theme.typography.pxToRem(16),
  },
}))

export interface LeftMenuItemProps {
  title: string
  icon: any
  route: string
  showTooltip?: boolean
  enabled?: boolean
}

export const LeftMenuItem: React.FC< LeftMenuItemProps > = ({
  icon,
  title,
  route,
  showTooltip = true,
  enabled = true,
}) => {
  const tooltipStyles = useTooltipStyles()
  const theme = useTheme()
  const routerHistory = useHistory()
  const location = useLocation()

  const selected = useMemo(() => {
    if (route === '/') {
      return location.pathname === '/'
    }
    return location.pathname.search(route) >= 0
  }, [location, route])

  const ItemIcon = icon

  return (
    <>
      <Tooltip arrow classes={tooltipStyles} title={title} disableFocusListener={!showTooltip} disableHoverListener={!showTooltip} disableTouchListener={!showTooltip}placement="right">
        <List>
          <ListItem
            disabled={!enabled}
            button
            onClick={() => routerHistory.push(route)}
            style={{ marginLeft: theme.spacing(1), backgroundColor: selected && theme.palette.primary.light }}
          >
            <ListItemIcon style={{ color: selected && theme.palette.primary.contrastText }}>
              <Avatar style={{ background: selected && theme.palette.primary.dark }}>
              <ItemIcon />
              </Avatar>
            </ListItemIcon>
            <ListItemText primary={title} style={{ color: selected && theme.palette.primary.contrastText }} />
          </ListItem>
        </List>
      </Tooltip>
      <Divider />
    </>
  )
}
