import React from 'react'
import { BaseSchema } from '../../../Schema/BaseSchema'
import { ChargeCodePicker } from '../../../App/ChargeCodes/Components/ChargeCodePicker'

interface SchemaChargeCodePickerProps {
  className?: any
  schema: BaseSchema
  label?: string
  field?: string
  helperText?: string
  onChange: Function
  disabled?: boolean
  required?: boolean
  variant?: 'standard' | 'filled' | 'outlined'
  placeholder?: string
  freeSolo?: boolean
  noOptionsText?: string
  clearOnBlur?: boolean
  enableCreate?: boolean
}

export const SchemaChargeCodePicker: React.FC<SchemaChargeCodePickerProps> = ({
  className,
  schema,
  field = 'chargeCodeId',
  label = 'Charge Code',
  helperText,
  onChange = (value: string, field: string) => {},
  disabled,
  required,
  variant,
  placeholder = 'Select a Charge Code',
  freeSolo,
  noOptionsText,
  clearOnBlur,
  enableCreate,
}) => {
  return (
    <ChargeCodePicker
      className={className}
      label={label}
      onChange={value => onChange(value, field)}
      disabled={disabled}
      required={required}
      variant={variant}
      placeholder={placeholder}
      freeSolo={freeSolo}
      noOptionsText={noOptionsText}
      clearOnBlur={clearOnBlur}
      enableCreate={enableCreate}
      error={schema.isPropertyInvalid(field)}
      helperText={schema.isPropertyInvalid(field) ? schema.getPropertyError(field) : helperText }
      value={schema.getValue(field) || null}
    />
  )
}
