/**
 * An adapter and slice definition for the redux store
 */

import {
  createEntityAdapter,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit'
import { AppRootState } from '../../AppState/AppStore'
import createFetchEntityThunk from '../../AppState/createFetchEntityThunk'
import createFetchPaginatedEntitiesThunk from '../../AppState/createFetchPaginatedEntitiesThunk'
import createUpdateManyReducer from '../../AppState/createUpdateManyReducer'
import createUpdateOneReducer from '../../AppState/createUpdateOneRecuder'
import { ConsigneeSchema } from '../../Schema/ConsigneeSchema'
import { ConsigneesAPI } from '../../Services/API/ConsigneesAPI'

const STORE_NAME = 'consignees'

const fetchEntities = createFetchPaginatedEntitiesThunk<ConsigneeSchema>(
  STORE_NAME,
  ConsigneesAPI,
)
const fetchEntity = createFetchEntityThunk<ConsigneeSchema>(
  STORE_NAME,
  ConsigneesAPI,
)

const EntityAdapter = createEntityAdapter<ConsigneeSchema>({
  selectId: (entity) => entity.id,
  sortComparer: (a, b) =>
    a.getSortingValue().localeCompare(b.getSortingValue()),
})

const EntitySlice = createSlice({
  name: STORE_NAME,
  initialState: EntityAdapter.getInitialState(),
  reducers: {
    addOne: EntityAdapter.addOne,
    addMany: EntityAdapter.addMany,
    removeOne: EntityAdapter.removeOne,
    removeAll: EntityAdapter.removeAll,
    updateOne: (state, payload: PayloadAction<ConsigneeSchema>) =>
      createUpdateOneReducer<ConsigneeSchema>(state, payload),
  },
  extraReducers: (builder) =>
    builder
      .addCase(fetchEntities.fulfilled, (state, action) => {
        createUpdateManyReducer<ConsigneeSchema>(state, action)
      })
      .addCase(fetchEntity.fulfilled, (state, action) => {
        createUpdateOneReducer<ConsigneeSchema>(state, action)
      }),
})

export const ConsigneesActions = EntitySlice.actions
export const ConsigneesReducer = EntitySlice.reducer
export const ConsigneeSelectors = EntityAdapter.getSelectors(
  (state: AppRootState) => state.consignees,
)
export const ConsigneeThunks = {
  fetchConsignees: fetchEntities,
  fetchConsignee: fetchEntity,
}
