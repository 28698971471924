import { Paper } from '@material-ui/core'
import clsx from 'clsx'
import React, { ReactNode } from 'react'
import { useAppStyles } from '../../Hooks/useAppStyles'
import { ContentHeader } from './ContentHeader'

interface ContentContainerProps {
  title: string
  action?: ReactNode,
}

export const ContentContainer: React.FC<ContentContainerProps> = ({
  title,
  action,
  children,
}) => {
  const appStyles = useAppStyles()

  return (
    <Paper className={clsx([appStyles.content, appStyles.contentItem])}>
      <ContentHeader title={title} variant='h5' action={action} />
      {children}
    </Paper>
  )
}
