import { Grid } from '@material-ui/core'
import React, { useCallback } from 'react'
import { SchemaAutocompleteInput } from '../../../Components/Inputs/Schema/SchemaAutocompleteInput'
import { SchemaTextInput } from '../../../Components/Inputs/Schema/SchemaTextInput'
import { InputOptionProps } from '../../../Components/Inputs/Types'
import { UserRole, UserSchema } from '../../../Schema/UserSchema'

const UserRoleOptions: InputOptionProps[] = [
  {
    label: 'Admin',
    value: UserRole.Admin,
  },
  {
    label: 'Staff',
    value: UserRole.Staff,
  },
]

interface UserFormProps {
  schema: UserSchema
  onChange: (schema: UserSchema) => void
}

export const UserForm: React.FC<UserFormProps> = ({
  schema,
  onChange,
}) => {
  const handleOnSchemaChanged = useCallback((value: any, field: string) => {
    schema.setValue(field, value)
    onChange(schema)
  }, [onChange, schema])

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <SchemaTextInput
          label={'Email'}
          placeholder={'Enter a user email'}
          schema={schema}
          field={'email'}
          onChange={handleOnSchemaChanged}
        />
      </Grid>
      <Grid item xs={12}>
        <SchemaTextInput
          label={'First Name'}
          placeholder={'Enter a user first name'}
          schema={schema}
          field={'firstName'}
          onChange={handleOnSchemaChanged}
        />
      </Grid>
      <Grid item xs={12}>
        <SchemaTextInput
          label={'Last Name'}
          placeholder={'Enter a user last name'}
          schema={schema}
          field={'lastName'}
          onChange={handleOnSchemaChanged}
        />
      </Grid>
      <Grid item xs={12}>
        <SchemaAutocompleteInput
          label={'Role'}
          placeholder={'Select a user role'}
          schema={schema}
          options={UserRoleOptions}
          field={'role'}
          onChange={handleOnSchemaChanged}
        />
      </Grid>
    </Grid>
  )
}
