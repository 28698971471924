import _ from 'lodash'
import { useMemo } from 'react'
import { useShipmentItemsSelector } from './useShipmentItemsSelector'

export const useShipmentItemsForShipmentSelector = (shipmentId: string) => {
  const items = useShipmentItemsSelector()

  const filteredItems = useMemo(() => {
    return _.filter(items, (i) => i.shipmentId === shipmentId)
  }, [items, shipmentId])

  return filteredItems
}
