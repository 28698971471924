import React from 'react'
import { EntityTag } from '../../../Components/EntityTag'
import { useConsigneeSelector } from '../Hooks/useConsigneeSelector'

interface ConsigneeTagProps {
  consigneeId: string
  avatarSize?: 'small' | 'medium' | 'large'
  onClick?: Function
  showAvatar?: boolean
}

export const ConsigneeTag: React.FC<ConsigneeTagProps> = ({
  consigneeId,
  avatarSize,
  onClick = () => {},
  showAvatar = false,
}) => {
  const consignee = useConsigneeSelector(consigneeId)

  return (
    <EntityTag
      onClick={event => onClick(event)}
      primary={consignee?.name}
      secondary={`${consignee?.city || '-'}, ${consignee?.region || '-'}, ${consignee?.postalCode || '-'}, ${consignee?.country || '-'}`}
      avatarSize={avatarSize}
      showAvatar={showAvatar}
    />
  )
}
