import { EntityState, PayloadAction } from '@reduxjs/toolkit'
import _ from 'lodash'
import { BaseSchema } from '../Schema/BaseSchema'

export default function createUpdateManyReducer<T> (
  state: EntityState<T>,
  payload: PayloadAction<T[]>,
) {
  const entities = payload.payload
  _.forEach(entities, (entity) => {
    if (!entity || !(entity instanceof BaseSchema)) {
      return
    }

    const key = entity.id

    if (!(key in state.entities)) {
      state.ids.push(key)
    }

    state.entities[key] = entity
  })
}
