import { useCallback, useState } from 'react'
import { useMounted } from '../../../Hooks/useMounted'
import { useAppDispatch } from '../../../Hooks/useAppDispatch'
import { CarriersActions } from '../StoreAdapter'
import { CarrierSchema } from '../../../Schema/CarrierSchema'
import { ServiceLocator } from '../../../Services/ServiceLocator'
import { CarriersAPI } from '../../../Services/API/CarriersAPI'

export const useUpdateCarrier = () => {
  const dispatch = useAppDispatch()
  const mounted = useMounted()

  const [processing, setProcessing] = useState(false)
  const [error, setError] = useState(false)
  const [errorMessage, setErrorMessage] = useState<string>(null)

  const updateCarrier = useCallback(
    async (schema: CarrierSchema): Promise<CarrierSchema> => {
      setProcessing(true)
      return ServiceLocator.resolve(CarriersAPI)
        .updateEntity(schema)
        .then((schema) => {
          dispatch(CarriersActions.updateOne(schema))
          if (mounted.current) {
            setProcessing(false)
            setError(true)
            setErrorMessage(null)
          }
          return schema
        })
        .catch((err) => {
          if (mounted.current) {
            setProcessing(false)
            setError(true)
            setErrorMessage(err.message)
            throw err
          }
          return null
        })
    },
    [dispatch, mounted],
  )

  return {
    processing,
    error,
    errorMessage,
    updateCarrier,
  }
}
