import { useAppSelector } from '../../../Hooks/useAppSelector'
import { ConsigneeSelectors } from '../StoreAdapter'

export const useConsigneeSelector = (entityId: string) => {
  const entityState = useAppSelector((state) =>
    ConsigneeSelectors.selectById(state, entityId),
  )

  return entityState
}
