import React, { useCallback, useEffect, useState } from 'react'
import { v4 as uuid } from 'uuid'
import { Box, Button, CircularProgress, Paper, Typography } from '@material-ui/core'

import { AppDrawer, AppDrawerToolbar, AppDrawerContent, AppDrawerActions } from '../../Components/AppDrawer'
import { useAppStyles } from '../../Hooks/useAppStyles'
import { useUpdateDelay } from './Hooks/useUpdateDelay'
import { BaseSchema } from '../../Schema/BaseSchema'
import { useDelaySelector } from './Hooks/useDelaySelector'
import { DelaySchema } from '../../Schema/DelaySchema'
import { DelayForm } from './Components/DelayForm'
import { useSnackbarMessages } from '../../Components/AppSnackbar/Hooks/useSnackbarMessages'
import { DeleteDelayButton } from './Components/DeleteDelayButton'

interface DelayEditDrawerProps {
  schemaId: string
  open: boolean
  onClose: () => void,
}

export const DelayEditDrawer: React.FC<DelayEditDrawerProps> = ({
  open,
  onClose,
  schemaId,
}) => {
  const { addSuccessMessage, addErrorMessage } = useSnackbarMessages()
  const appStyles = useAppStyles()
  const { updateDelay, processing } = useUpdateDelay()

  const schemaState = useDelaySelector(schemaId)

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [hash, setHash] = useState('')
  const [schema, setSchema] = React.useState<DelaySchema>(null)

  const handleOnSchemaChanged = useCallback((schema: DelaySchema) => {
    setHash(uuid())
  }, [])

  useEffect(() => {
    if (!schemaState || !(schemaState instanceof BaseSchema)) {
      setSchema(null)
      return
    }
    setSchema(schemaState.clone())
  }, [schemaState])

  const handleOnSave = useCallback(() => {
    if (schema.validate()) {
      updateDelay(schema).then(() => {
        addSuccessMessage('Delay updated successfully.')
        onClose()
      }).catch(() => {
        addErrorMessage('Failed to update the Delay.')
      })
    }
    setHash(uuid())
  }, [schema, updateDelay, onClose, addSuccessMessage, addErrorMessage])

  return (
    <AppDrawer
      width={400}
      open={open}
      onClose={onClose}
    >
      <AppDrawerToolbar>
        <Typography variant={'h5'} style={{ flex: 1 }}>Edit Delay</Typography>
        <DeleteDelayButton schema={schema} onDeleted={onClose} />
      </AppDrawerToolbar>
      <AppDrawerContent>
        <Box className={appStyles.content}>
          <Paper className={appStyles.content}>
            {!schema && <CircularProgress />}
            {schema && <DelayForm schema={schema} onChange={handleOnSchemaChanged} />}
          </Paper>
        </Box>
      </AppDrawerContent>
      <AppDrawerActions>
        <Button disabled={processing} color={'primary'} onClick={handleOnSave}>Save</Button>
      </AppDrawerActions>
    </AppDrawer>
  )
}
