import { ListItem, ListItemAvatar, ListItemText } from '@material-ui/core'
import React from 'react'
import { useUserSelector } from '../Hooks/useUserSelector'
import { UserAvatar } from './UserAvatar'

interface UserTagProps {
  userId: string
  avatarSize?: 'small' | 'medium' | 'large'
  button?: true
}

export const UserTag: React.FC<UserTagProps> = ({
  userId,
  avatarSize,
  button,
}) => {
  const entity = useUserSelector(userId)

  return (
    <ListItem button={button}>
      <ListItemAvatar>
        <UserAvatar userId={userId} size={avatarSize} />
      </ListItemAvatar>
      <ListItemText>
        {entity?.getFullName()}
      </ListItemText>
    </ListItem>
  )
}
