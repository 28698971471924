import { APIBase } from './APIBase'
import { Singleton } from 'typescript-ioc'
import { ShippingAPIClient } from './Clients/ShippingAPIClient'
import { ContainerSchema } from '../../Schema/ContainerSchema'

@Singleton
export class ContainersAPI extends APIBase<ContainerSchema> {
  constructor () {
    super(ContainerSchema, ShippingAPIClient)
    this.resourcePath = 'containers'
  }
}
