import React from 'react'
import { ShipmentItemSchema } from '../../../Schema/ShipmentItemSchema'
import { CarrierExternalTrackingButton } from '../../Carriers/Components/CarrierExternalTrackingButton'
import { useShipmentSelector } from '../../Shippments/Hooks/useShipmentSelector'

interface ShipmentItemExternalTrackingButtonProps {
  schema: ShipmentItemSchema
  buttonSize?: 'small' | 'medium'
}

export const ShipmentItemExternalTrackingButton: React.FC<ShipmentItemExternalTrackingButtonProps> = ({
  schema,
  buttonSize,
}) => {
  const shipment = useShipmentSelector(schema?.shipmentId)

  return (
    <CarrierExternalTrackingButton carrierId={shipment?.carrierId} trackingNumber={schema?.carrierTrackingNumber} buttonSize={buttonSize} />
  )
}
