import { useCallback, useState } from 'react'
import { useMounted } from '../../../Hooks/useMounted'
import { useAppDispatch } from '../../../Hooks/useAppDispatch'
import { ServicesActions } from '../StoreAdapter'
import { ServiceSchema } from '../../../Schema/ServiceSchema'
import { ServiceLocator } from '../../../Services/ServiceLocator'
import { ServicesAPI } from '../../../Services/API/ServicesAPI'

export const useDeleteService = () => {
  const dispatch = useAppDispatch()
  const mounted = useMounted()

  const [processing, setProcessing] = useState(false)
  const [error, setError] = useState(false)
  const [errorMessage, setErrorMessage] = useState<string>(null)

  const deleteService = useCallback(
    async (schemaId: string): Promise<ServiceSchema> => {
      setProcessing(true)
      return ServiceLocator.resolve(ServicesAPI)
        .deleteEntity(schemaId)
        .then((schema) => {
          dispatch(ServicesActions.removeOne(schemaId))
          if (mounted.current) {
            setProcessing(false)
            setError(true)
            setErrorMessage(null)
          }
          return schema
        })
        .catch((err) => {
          if (mounted.current) {
            setProcessing(false)
            setError(true)
            setErrorMessage(err?.response?.data?.errorMessage)
            throw err
          }
          return null
        })
    },
    [dispatch, mounted],
  )

  return {
    processing,
    error,
    errorMessage,
    deleteService,
  }
}
