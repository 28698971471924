/**
 * An adapter and slice definition for the redux store
 */

import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit'
import { AppRootState } from '../../AppState/AppStore'
import createFetchEntityThunk from '../../AppState/createFetchEntityThunk'
import createFetchPaginatedEntitiesThunk from '../../AppState/createFetchPaginatedEntitiesThunk'
import createUpdateManyReducer from '../../AppState/createUpdateManyReducer'
import createUpdateOneReducer from '../../AppState/createUpdateOneRecuder'
import { ChargeCodeSchema } from '../../Schema/ChargeCodeSchema'
import { ChargeCodesAPI } from '../../Services/API/ChargeCodesAPI'

const STORE_NAME = 'chargeCodes'

const fetchEntities = createFetchPaginatedEntitiesThunk<ChargeCodeSchema>(
  STORE_NAME,
  ChargeCodesAPI,
)

const fetchEntity = createFetchEntityThunk<ChargeCodeSchema>(
  STORE_NAME,
  ChargeCodesAPI,
)

const EntityAdapter = createEntityAdapter<ChargeCodeSchema>({
  selectId: (entity) => entity.id,
  sortComparer: (a, b) =>
    a.getSortingValue().localeCompare(b.getSortingValue()),
})

const EntitySlice = createSlice({
  name: STORE_NAME,
  initialState: EntityAdapter.getInitialState(),
  reducers: {
    addOne: EntityAdapter.addOne,
    addMany: EntityAdapter.addMany,
    removeOne: EntityAdapter.removeOne,
    removeAll: EntityAdapter.removeAll,
    updateOne: (state, payload: PayloadAction<ChargeCodeSchema>) =>
      createUpdateOneReducer<ChargeCodeSchema>(state, payload),
  },
  extraReducers: (builder) =>
    builder
      .addCase(fetchEntities.fulfilled, (state, action) => {
        createUpdateManyReducer<ChargeCodeSchema>(state, action)
      })
      .addCase(fetchEntity.fulfilled, (state, action) => {
        createUpdateOneReducer<ChargeCodeSchema>(state, action)
      }),
})

export const ChargeCodesActions = EntitySlice.actions
export const ChargeCodesReducer = EntitySlice.reducer
export const ChargeCodeSelectors = EntityAdapter.getSelectors(
  (state: AppRootState) => state.chargeCodes,
)
export const ChargeCodeThunks = {
  fetchChargeCodes: fetchEntities,
  fetchChargeCode: fetchEntity,
}
