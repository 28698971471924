import React, { useMemo } from 'react'
import clsx from 'clsx'

import { Avatar, makeStyles } from '@material-ui/core'
import { stringToColor } from '../Utils/StringToColor'

interface FormAvatarProps {
  className?: any
  size?: 'small' | 'medium' | 'large'
  text?: string
}

export const EntityAvatar: React.FC<FormAvatarProps> = ({ className, size, text }) => {
  const styles = useStyles()

  const avatarText = useMemo(() => {
    if (text && text.length > 0) {
      return text[0]
    } else {
      return '-'
    }
  }, [text])

  return (
    <Avatar
      className={clsx([styles.avatar, className, {
        [styles.small]: size === 'small',
        [styles.medium]: size === 'medium',
        [styles.medium]: size === 'large',
      }])
      }
      style={{ backgroundColor: stringToColor(text || '') }}
    >{avatarText}</Avatar>
  )
}

const useStyles = makeStyles((theme) => ({
  avatar: {
    display: 'flex',
    marginRight: theme.spacing(1),
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  medium: {
    width: theme.spacing(5),
    height: theme.spacing(5),
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  smallText: {
    fontSize: '0.5em',
  },
  mediumText: {
    fontSize: '1em',
  },
  largeText: {
    large: '1.5em',
  },
}))
