import { createSelector } from '@reduxjs/toolkit'
import _, { memoize } from 'lodash'
import { AppRootState } from '../../AppState/AppStore'
import { ConsigneeSelectors } from './StoreAdapter'

export const getConsigneeSelector = createSelector(
  (state: AppRootState) => state,
  (state) =>
    memoize((id: string) => {
      return ConsigneeSelectors.selectById(state, id)
    }),
)
