import { APIBase } from './APIBase'
import { Singleton } from 'typescript-ioc'
import { ServiceSchema } from '../../Schema/ServiceSchema'
import { ServiceAPIClient } from './Clients/ServiceAPIClient'

@Singleton
export class ServicesAPI extends APIBase<ServiceSchema> {
  constructor () {
    super(ServiceSchema, ServiceAPIClient)
    this.resourcePath = 'services'
  }
}
