import React from 'react'
import { Switch } from 'react-router-dom'

import { Container } from '@material-ui/core'

import { RouteWithSubRoutes } from '../Layout/Components/RouteWithSubRoutes'
import { AppTabs, AppTabProps } from '../Components/AppTabs/index'
import { AppRouteProps } from '../Layout/AppRoutes'

interface AppFeatureContainerProps {
  tabs: AppTabProps[],
  routes?: AppRouteProps[],
  selected?: string
}

export const AppFeatureContainer: React.FC<AppFeatureContainerProps> = ({
  routes,
  tabs,
  selected,
  children,
}) => {
  return (
    <Container maxWidth={false}>
      <AppTabs tabs={tabs} selected={selected}>
        <Switch>
          {routes && routes.map((route, i) => (
              <RouteWithSubRoutes key={i} {...route} />
          ))}
        </Switch>
        {children}
      </AppTabs>
    </Container>
  )
}
