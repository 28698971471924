import React from 'react'
import { Redirect, Route } from 'react-router-dom'
import { AppRouteProps } from '../AppRoutes'

export const RouteWithSubRoutes: React.FC<AppRouteProps> = (route: any) => {
  if (route.redirectTo) {
    return (
      <Redirect exact={route.exact} from={route.path} to={route.redirectTo}/>
    )
  } else {
    return (
    <Route
      path={route.path}
      render={props => (
        // pass the sub-routes down to keep nesting
        <route.component {...props} routes={route.routes} />
      )}
    />
    )
  }
}
