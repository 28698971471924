import { Box, createStyles, makeStyles } from '@material-ui/core'
import {
  GridCellParams, GridColDef, DataGrid, GridSortDirection, GridValueFormatterParams, GridToolbarContainer,
  GridToolbarExport,
  visibleSortedGridRowIdsSelector,
} from '@material-ui/data-grid'
import _ from 'lodash'
import React, { useCallback, useMemo } from 'react'
import { useHistory } from 'react-router'
import { AppPermission, useAppPermissions } from '../../../Hooks/useAppPermissions'
import { useAppSelector } from '../../../Hooks/useAppSelector'
import { ShipmentSchema } from '../../../Schema/ShipmentSchema'
import { formatLocalDateTime, removeTimeFromDate } from '../../../Utils/DateFunctions'
import { CarrierTag } from '../../Carriers/Components/CarrierTag'
import { useCarriersSelector } from '../../Carriers/Hooks/useCarriersSelector'
import { ChargeCodeTag } from '../../ChargeCodes/Components/ChargeCodeTag'
import { useChargeCodesSelector } from '../../ChargeCodes/Hooks/useChargeCodesSelector'
import { ConsigneeTag } from '../../Consignees/Components/ConsigneeTag'
import { useConsigneesSelector } from '../../Consignees/Hooks/useConsigneesSelector'
import { LabContactTag } from '../../LabContacts/Components/LabContactTag'
import { useLabContactsSelector } from '../../LabContacts/Hooks/useLabContactsSelector'
import { ServiceTag } from '../../Services/Components/ServiceTag'
import { useServicesSelector } from '../../Services/Hooks/useServicesSelector'
import { getShipmentItemsForShipmentSelector } from '../../ShipmentItems/Selectors'
import { getLabSelector } from '../../Labs/Selectors'
import { getContainersForShipmentSelector } from '../../Containers/Selectors'
import { ShipmentDeliveredCount } from './ShipmentDeliveredCount'
import { ShipmentItemTrackingNumbers } from './ShipmentItemTrackingNumbers'
import { ShipmentProgressChart } from './ShipmentProgressChart'
import { useDataGridContext } from '../../../Components/DataGrid/Hooks/useDataGridContext'
import { ShipmentStatusChart } from './ShipmentStatusChart'
import { ShipmentSrNumber } from './ShipmentItemSrNumber'
// import { UserTag } from '../UserTag'

const columns: GridColDef[] = [
  {
    field: 'id',
    headerName: '%',
    width: 60,
    filterable: false,
    renderCell: (params: GridValueFormatterParams) => {
      return (
        <Box>
          <ShipmentStatusChart shipmentId={params.value?.toString()} size={50} />
        </Box>
      )
    },
  },
  {
    field: 'delivered',
    headerName: 'D',
    width: 50,
    type: 'number',
    sortable: false,
    filterable: false,
    disableExport: true,
    renderCell: (params: GridValueFormatterParams) => {
      return (
        <ShipmentDeliveredCount shipmentId={params.getValue(params.id, 'id')?.toString()} />
      )
    },
  },
  {
    field: 'srNumber',
    headerName: 'SR#',
    width: 100,
    renderCell: (params: GridValueFormatterParams) => {
      return (
        <ShipmentSrNumber shipmentId={params.getValue(params.id, 'id')?.toString()} />
      )
    },
  },
  {
    field: 'srPickupDate',
    headerName: 'PD',
    width: 100,
    type: 'date',
    valueGetter: (params: GridValueFormatterParams) => {
      if (!params?.value) {
        return null
      }
      return removeTimeFromDate(params.value as Date)
    },
  },
  {
    field: 'shipmentDate',
    headerName: 'SD',
    width: 100,
    type: 'date',
    sortable: true,
    valueGetter: (params: GridValueFormatterParams) => {
      if (!params?.value) {
        return null
      }
      return removeTimeFromDate(params.value as Date)
    },
  },
  {
    field: 'consigneeId',
    headerName: 'Consignee',
    width: 250,
    sortable: true,
    disableExport: true,
    renderCell: (params: GridValueFormatterParams) => {
      return <ConsigneeTag consigneeId={params.value?.toString()} />
    },
  },
  {
    field: 'consignee.id',
    headerName: 'Consignee ID',
    width: 200,
    hide: true,
    valueGetter: (params: GridValueFormatterParams) => {
      return _.get(params.row, params.field)
    },
  },
  {
    field: 'consignee.name',
    headerName: 'Consignee Name',
    width: 200,
    hide: true,
    valueGetter: (params: GridValueFormatterParams) => {
      return _.get(params.row, params.field)
    },
  },
  {
    field: 'consignee.address',
    headerName: 'Consignee Address',
    width: 200,
    hide: true,
    valueGetter: (params: GridValueFormatterParams) => {
      return _.get(params.row, params.field)
    },
  },
  {
    field: 'consignee.city',
    headerName: 'Consignee City',
    width: 200,
    hide: true,
    valueGetter: (params: GridValueFormatterParams) => {
      return _.get(params.row, params.field)
    },
  },
  {
    field: 'consignee.region',
    headerName: 'Consignee Region',
    width: 200,
    hide: true,
    valueGetter: (params: GridValueFormatterParams) => {
      return _.get(params.row, params.field)
    },
  },
  {
    field: 'consignee.postalCode',
    headerName: 'Consignee Postal Code',
    width: 200,
    hide: true,
    valueGetter: (params: GridValueFormatterParams) => {
      return _.get(params.row, params.field)
    },
  },
  {
    field: 'consignee.country',
    headerName: 'Consignee Country',
    width: 200,
    hide: true,
    valueGetter: (params: GridValueFormatterParams) => {
      return _.get(params.row, params.field)
    },
  },
  {
    field: 'serviceId',
    headerName: 'Service',
    width: 100,
    disableExport: true,
    renderCell: (params: GridValueFormatterParams) => {
      return <ServiceTag serviceId={params.row?.serviceId} fontSize={12} />
    },
  },
  {
    field: 'service.id',
    headerName: 'Service ID',
    width: 200,
    hide: true,
    valueGetter: (params: GridValueFormatterParams) => {
      return _.get(params.row, params.field)
    },
  },
  {
    field: 'service.name',
    headerName: 'Service Name',
    width: 200,
    hide: true,
    valueGetter: (params: GridValueFormatterParams) => {
      return _.get(params.row, params.field)
    },
  },
  {
    field: 'labContactId',
    headerName: 'Lab Contact',
    width: 200,
    disableExport: true,
    renderCell: (params: GridValueFormatterParams) => {
      return <LabContactTag labContactId={params.row?.labContactId?.toString()} />
    },
  },
  {
    field: 'lab.id',
    headerName: 'Lab ID',
    width: 200,
    hide: true,
    valueGetter: (params: GridValueFormatterParams) => {
      return _.get(params.row, params.field)
    },
  },
  {
    field: 'lab.name',
    headerName: 'Lab Name',
    width: 200,
    hide: true,
    valueGetter: (params: GridValueFormatterParams) => {
      return _.get(params.row, params.field)
    },
  },
  {
    field: 'labContact.id',
    headerName: 'Lab Contact ID',
    width: 200,
    hide: true,
    valueGetter: (params: GridValueFormatterParams) => {
      return _.get(params.row, params.field)
    },
  },
  {
    field: 'labContact.name',
    headerName: 'Lab Contact Name',
    width: 200,
    hide: true,
    valueGetter: (params: GridValueFormatterParams) => {
      return _.get(params.row, params.field)
    },
  },
  {
    field: 'labContact.email',
    headerName: 'Lab Contact Name',
    width: 200,
    hide: true,
    valueGetter: (params: GridValueFormatterParams) => {
      return _.get(params.row, params.field)
    },
  },
  {
    field: 'carrierId',
    headerName: 'Carrier',
    width: 200,
    resizable: true,
    disableExport: true,
    renderCell: (params: GridValueFormatterParams) => {
      const shipmentId = params.getValue(params.id, 'id')?.toString()
      return <CarrierTag
        carrierId={params.value?.toString()}
        secondaryText={(
          <Box display={'flex'} alignContent={'center'} alignItems={'center'} lineHeight={0} minWidth={0}>
            <ShipmentItemTrackingNumbers shipmentId={shipmentId} />
            {/* <Typography>{trackingNumber}</Typography>
            {trackingNumber && <CopyToClipboardButton buttonSize={'small'} size={18} text={trackingNumber} /> */ }
          </Box>
        )}
      />
    },
  },
  {
    field: 'carrier.id',
    headerName: 'Carrier ID',
    width: 200,
    hide: true,
    valueGetter: (params: GridValueFormatterParams) => {
      return _.get(params.row, params.field)
    },
  },
  {
    field: 'carrier.name',
    headerName: 'Carrier Name',
    width: 200,
    hide: true,
    valueGetter: (params: GridValueFormatterParams) => {
      return _.get(params.row, params.field)
    },
  },
  {
    field: 'trackingNumbers',
    headerName: 'Tracking Numbers',
    width: 200,
    hide: true,
    valueGetter: (params: GridValueFormatterParams) => {
      const shipmentItems = _.get(params.row, 'shipmentItems')
      if (_.isEmpty(shipmentItems)) {
        return null
      }
      const trackingNumbers = _.filter(_.map(shipmentItems, item => item.carrierTrackingNumber), number => !_.isEmpty(number))
      return trackingNumbers.join(', ')
    },
  },
  {
    field: 'containers',
    headerName: 'Containers',
    width: 200,
    hide: true,
    valueGetter: (params: GridValueFormatterParams) => {
      const containers = _.get(params.row, 'containers')
      if (_.isEmpty(containers)) {
        return null
      }
      const names = _.map(containers, item => item.name)
      return names.join(', ')
    },
  },
  {
    field: 'piecesTotal',
    headerName: '#',
    width: 90,
    type: 'number',
  },
  {
    field: 'weightTotal',
    headerName: 'W',
    width: 90,
    type: 'number',
  },
  {
    field: 'dimensionalWeightTotal',
    headerName: 'D.W.',
    width: 90,
    type: 'number',
  },
  {
    field: 'carrierEta',
    headerName: 'Carrier ETA',
    type: 'date',
    width: 100,
    valueGetter: (params: GridValueFormatterParams) => {
      if (!params?.value) {
        return null
      }
      return removeTimeFromDate(params.value as Date)
    },
  },
  {
    field: 'chargeCodeId',
    headerName: 'Charge Code',
    width: 100,
    disableExport: true,
    renderCell: (params: GridValueFormatterParams) => {
      return <ChargeCodeTag chargeCodeId={params.value?.toString()} fontSize={12} />
    },
  },
  {
    field: 'chargeCode.id',
    headerName: 'Charge Code ID',
    width: 200,
    hide: true,
    valueGetter: (params: GridValueFormatterParams) => {
      return _.get(params.row, params.field)
    },
  },
  {
    field: 'chargeCode.code',
    headerName: 'Charge Code Code',
    width: 200,
    hide: true,
    valueGetter: (params: GridValueFormatterParams) => {
      return _.get(params.row, params.field)
    },
  },
  {
    field: 'updated',
    headerName: 'Updated',
    width: 150,
    type: 'dateTime',
    valueFormatter: (params: GridValueFormatterParams) => {
      return formatLocalDateTime(params.getValue(params.id, 'updated') as Date)
    },
  },
  {
    field: 'created',
    headerName: 'Created',
    width: 150,
    type: 'dateTime',
    valueFormatter: (params: GridValueFormatterParams) => {
      return formatLocalDateTime(params.getValue(params.id, 'created') as Date)
    },
  },
]

/*
function CustomToolbar () {
  const { checkPermissions } = useAppPermissions()

  if (!checkPermissions(AppPermission.ShipmentExport)) {
    return null
  }
  return (
    <GridToolbarContainer>
      <GridToolbarExport csvOptions={{ allColumns: true }} />
    </GridToolbarContainer>
  )
}
*/

interface ShipmentsDataGridProps {
  entities: ShipmentSchema[] | Record<string, ShipmentSchema>
}

export const ShipmentsDataGrid: React.FC<ShipmentsDataGridProps> = ({
  entities,
}) => {
  const { setVisibleRows } = useDataGridContext()
  const getShipmentItemsForShipment = useAppSelector(state => getShipmentItemsForShipmentSelector(state))
  const getContainersForShipment = useAppSelector(state => getContainersForShipmentSelector(state))
  const getLab = useAppSelector(state => getLabSelector(state))

  const labContacts = useLabContactsSelector()
  const services = useServicesSelector()
  const consignees = useConsigneesSelector()
  const chargeCodes = useChargeCodesSelector()
  const carriers = useCarriersSelector()

  const history = useHistory()
  const styles = useStyles()

  const [sortModel, setSortModel] = React.useState([
    {
      field: 'shipmentDate',
      sort: 'desc' as GridSortDirection,
    },
  ])

  const transformRows = useCallback((rows: ShipmentSchema[]) => {
    _.forEach(rows, row => {
      const labContact = _.get(labContacts, row.labContactId)
      _.set(row, 'labContact', labContact)
      _.set(row, 'lab', getLab(labContact?.labId))
      _.set(row, 'service', _.find(services, item => item.id === row.serviceId))
      _.set(row, 'consignee', _.get(consignees, row.consigneeId))
      _.set(row, 'chargeCode', _.find(chargeCodes, item => item.id === row.chargeCodeId))
      _.set(row, 'carrier', _.get(carriers, row.carrierId))
      _.set(row, 'shipmentItems', getShipmentItemsForShipment(row.id))
      _.set(row, 'containers', getContainersForShipment(row.id))
    })
  }, [labContacts, services, consignees, chargeCodes, carriers, getShipmentItemsForShipment, getContainersForShipment, getLab])

  const rows: ShipmentSchema[] = useMemo(() => {
    let arr
    if (_.isArray(entities)) {
      arr = entities
    } else {
      arr = _.map(entities)
    }
    transformRows(arr)
    return arr
  }, [entities, transformRows])

  const handleOnCellClick = (params: GridCellParams) => {
    /* if (_.includes(['status', 'assigneeId'], cellParams.field)) {
      return
    } */
    history.push(`/shipment-management/shipments/${params.getValue(params.id, 'id')}`)
  }

  return (
    <DataGrid
      className={styles.grid}
      columns={columns}
      rows={rows}
      showColumnRightBorder
      disableSelectionOnClick={true}
      onCellClick={handleOnCellClick}
      sortModel={sortModel}
      onSortModelChange={(model) => setSortModel(model)}
      // density={'compact'}
      /* components={{
        Toolbar: CustomToolbar,
      }} */
      onStateChange={({ state }) => {
        const newRows = visibleSortedGridRowIdsSelector(state)
        setVisibleRows(newRows)
      }}
    />
  )
}

const useStyles = makeStyles(theme =>
  createStyles({
    grid: {
      fontSize: 12,
    },
  }),
)
