import { Type } from 'class-transformer'
import {
  IsDate,
  IsEnum,
  IsNotEmpty,
  IsNumber,
  IsOptional,
  IsString,
  IsUUID,
} from 'class-validator'
import _ from 'lodash'
import { YesNoType } from '../Utils/Types'
import { BaseSchema } from './BaseSchema'
import { DelaySchema } from './DelaySchema'
import { ShipmentDelaySchema } from './ShipmentDelaySchema'

export class ShipmentSchema extends BaseSchema {
  /**
   * User ID
   */
  @IsOptional()
  @IsUUID()
  id: string

  /**
   * SR Number
   */
  @IsString()
  @IsNotEmpty()
  srNumber: string = null

  /**
   * SR Pickup Date
   */
  @Type(() => Date)
  @IsNotEmpty()
  @IsDate()
  srPickupDate: Date = new Date()

  /**
   * Shipment Date
   */
  @Type(() => Date)
  @IsNotEmpty()
  @IsDate()
  shipmentDate: Date = new Date()

  /**
   * Consignee Identifier
   */
  @IsOptional()
  @IsUUID()
  carrierId: string = null

  /**
   *  Carrier ETA
   */
  @IsOptional()
  @Type(() => Date)
  @IsNotEmpty()
  @IsDate()
  carrierEta: Date

  /**
   * DG flag.
   */
  @IsOptional()
  @IsString()
  publicTrackingNumber: string = null

  /**
   * DG flag.
   */
  @IsOptional()
  @IsEnum(YesNoType)
  dgFlag: YesNoType = YesNoType.No

  /**
   * Consignee Identifier
   */
  @IsNotEmpty()
  @IsUUID()
  consigneeId: string = null

  /**
   * Lab Contact Identifier
   */
  @IsNotEmpty()
  @IsUUID()
  labContactId: string = null

  /**
   * Charge Code Identifier
   */
  @IsNotEmpty()
  @IsUUID()
  chargeCodeId: string = null

  /**
   * Service Identifier
   */
  @IsNotEmpty()
  @IsUUID()
  serviceId: string = null

  /**
   * courier
   */
  @IsString()
  @IsEnum(YesNoType)
  courier: YesNoType = YesNoType.No

  @Type(() => Number)
  @IsNumber()
  @IsOptional()
  piecesTotal: number

  @Type(() => Number)
  @IsNumber()
  @IsOptional()
  weightTotal: number

  @Type(() => Number)
  @IsNumber()
  @IsOptional()
  dimensionalWeightTotal: number

  /**
   * Date created
   */
  @Type(() => Date)
  created: Date

  /**
   * Updated by
   */
  createdBy: string

  /**
   * Date updated
   */
  @Type(() => Date)
  updated: Date

  /**
   * Updated by
   */
  updatedBy: string

  /**
   * Date deleted
   */
  @Type(() => Date)
  deleted: Date

  /**
   * Deleted by
   */
  deletedBy: string

  @Type(() => ShipmentDelaySchema)
  shipmentDelays: ShipmentDelaySchema[]

  /**
   * Construct
   * @param data
   */
  constructor (data?: Partial<ShipmentSchema>) {
    super()
    _.assign(this, data)
  }

  /**
   * Returns a string for sorting
   * @returns string
   */
  getSortingValue () {
    return `${this.srPickupDate}, ${this.srNumber}`
  }

  /**
   * Retrieve shipment delay by ID
   * @param delayId
   * @returns
   */
  findDelayById (delayId: string) {
    return _.find(this?.shipmentDelays, (delay) => {
      return delay.delayId === delayId
    })
  }

  /**
   * Remove shipment delay by delay ID
   * @param delayId
   */
  removeDelayById (delayId: string) {
    const pos = _.findIndex(this?.shipmentDelays, (delay) => {
      return delay.delayId === delayId
    })
    if (pos >= 0) {
      this.shipmentDelays.splice(pos, 1)
    }
  }

  /**
   * Attach shipment delay
   * @param delayId
   * @param createdBy
   * @returns
   */
  attachDelay (delayId: string, createdBy?: string) {
    if (!delayId) {
      return
    }

    const pos = _.findIndex(this?.shipmentDelays, (item) => {
      return item.delayId === delayId
    })
    const shipmentDelay = new ShipmentDelaySchema()
    shipmentDelay.shipmentId = this.id
    shipmentDelay.delayId = delayId
    shipmentDelay.created = new Date()
    shipmentDelay.createdBy = createdBy
    if (pos >= 0) {
      this.shipmentDelays[pos] = shipmentDelay
    } else {
      this.shipmentDelays.push(shipmentDelay)
    }
  }
}
