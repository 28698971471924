import { Box, Button, Paper, useTheme } from '@material-ui/core'
import _ from 'lodash'
import React, { useEffect, useMemo, useState } from 'react'
import { useHistory, useParams } from 'react-router'
import { useAppTabs } from '../../Components/AppTabs/useAppTabs'
import { AppToolbar } from '../../Components/AppToolbar'
import { AppPermission, useAppPermissions } from '../../Hooks/useAppPermissions'
import { useAppStyles } from '../../Hooks/useAppStyles'
import { ServicesDataGrid } from './Components/ServicesDataGrid'
import { useServicesSelector } from './Hooks/useServicesSelector'
import { ServiceAddDrawer } from './ServiceAddDrawer'
import { ServiceEditDrawer } from './ServiceEditDrawer'

export const Services: React.FC = () => {
  const theme = useTheme()
  const appStyles = useAppStyles()
  const appTabs = useAppTabs()
  const history = useHistory()
  const { checkPermissions } = useAppPermissions()

  const { entityId: paramEntityId } = useParams<{entityId: string}>()

  const services = useServicesSelector()

  const [openAdd, setOpenAdd] = useState(false)
  const [editSchemaId, setEditSchemaId] = useState<string>(null)

  useEffect(() => {
    setEditSchemaId(paramEntityId)
  }, [paramEntityId])

  useEffect(() => {
    if (appTabs) {
      appTabs.select('services')
    }
  }, [appTabs])

  return (
    <Box className={appStyles.tabContent}>
      <AppToolbar>
        <Box flex={1} />
        {checkPermissions(AppPermission.ServiceAdd) && <Button variant={'contained'} color={'primary'} onClick={() => setOpenAdd(true)}>Add Service</Button>}
      </AppToolbar>
      <Paper style={{ height: 'calc(100vh - 200px)', padding: theme.spacing(1) }}>
        <ServicesDataGrid entities={services} />
      </Paper>
      <ServiceAddDrawer
        open={openAdd}
        onClose={() => setOpenAdd(false)}
      />
      {checkPermissions(AppPermission.ServiceEdit) && <ServiceEditDrawer
        open={Boolean(editSchemaId)}
        schemaId={editSchemaId}
        onClose={() => history.push('/settings/services')}
      />}
    </Box>
  )
}
