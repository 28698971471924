import { useCallback, useState } from 'react'
import { useMounted } from '../../../Hooks/useMounted'
import { UserSchema } from '../../../Schema/UserSchema'
import { v4 as uuid } from 'uuid'
import { useAppDispatch } from '../../../Hooks/useAppDispatch'
import { UsersActions } from '../StoreAdapter'
import { ServiceLocator } from '../../../Services/ServiceLocator'
import { UsersAPI } from '../../../Services/API/UsersAPI'

export const useUpdateUser = () => {
  const dispatch = useAppDispatch()
  const mounted = useMounted()

  const [processing, setProcessing] = useState(false)
  const [error, setError] = useState(false)
  const [errorMessage, setErrorMessage] = useState<string>(null)

  const updateUser = useCallback(
    async (schema: UserSchema): Promise<UserSchema> => {
      setProcessing(true)
      return ServiceLocator.resolve(UsersAPI)
        .updateEntity(schema)
        .then((schema) => {
          dispatch(UsersActions.updateOne(schema))
          if (mounted.current) {
            setProcessing(false)
            setError(true)
            setErrorMessage(null)
          }
          return schema
        })
        .catch((err) => {
          if (mounted.current) {
            setProcessing(false)
            setError(true)
            setErrorMessage(err.message)
            throw err
          }
          return null
        })
    },
    [dispatch, mounted],
  )

  return {
    processing,
    error,
    errorMessage,
    updateUser,
  }
}
