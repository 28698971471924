import React, { useCallback } from 'react'
import { useSnackbarMessages } from '../../../Components/AppSnackbar/Hooks/useSnackbarMessages'
import { DeleteIconButton } from '../../../Components/Buttons/DeleteIconButton'
import { AppPermission } from '../../../Hooks/useAppPermissions'
import { CarrierSchema } from '../../../Schema/CarrierSchema'
import { useDeleteCarrier } from '../Hooks/useDeleteCarrier'

interface DeleteCarrierButtonProps {
  schema: CarrierSchema
  onDeleted?: () => void
}

export const DeleteCarrierButton: React.FC<DeleteCarrierButtonProps> = ({
  schema,
  onDeleted = () => {},
}) => {
  const { addSuccessMessage, addErrorMessage } = useSnackbarMessages()
  const { deleteCarrier, processing, errorMessage } = useDeleteCarrier()

  const handleOnDelete = useCallback(() => {
    deleteCarrier(schema.id).then(() => {
      addSuccessMessage('The carrier deleted successfully.')
      onDeleted()
    }).catch(() => {
      addErrorMessage(errorMessage)
    })
  }, [schema?.id, addSuccessMessage, addErrorMessage, deleteCarrier, errorMessage, onDeleted])

  return (
    <DeleteIconButton permissions={[AppPermission.CarrierDelete]} onDelete={handleOnDelete} disabled={processing} />
  )
}
