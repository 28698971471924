import { ThemeProvider } from '@material-ui/styles'
import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import { Layout } from './Layout'
import { Theme } from './Theme'
import { Provider } from 'react-redux'
import { AppStore } from './AppState/AppStore'
import { AppSnackbar } from './Components/AppSnackbar/AppSnackbar'
import { CookiesProvider } from 'react-cookie'

function App () {
  return (
    <ThemeProvider theme={Theme}>
      <BrowserRouter>
        <CookiesProvider>
          <Provider store={AppStore}>
            <AppSnackbar>
              <Layout />
            </AppSnackbar>
          </Provider>
        </CookiesProvider>
      </BrowserRouter>
    </ThemeProvider>
  )
}

export default App
