import _ from 'lodash'
import { ShipmentItemSchema } from '../../Schema/ShipmentItemSchema'
import { ShipmentSchema } from '../../Schema/ShipmentSchema'

export class ShipmentStatusService {
  private shipment: ShipmentSchema = null

  private shipmentItems: ShipmentItemSchema[] = []

  constructor (data?: {
    shipment: ShipmentSchema
    shipmentItems: ShipmentItemSchema[]
  }) {
    this.shipment = data?.shipment
    this.shipmentItems = data?.shipmentItems
  }

  setShipment (shipment: ShipmentSchema) {
    this.shipment = shipment
  }

  setShipmentItems (shipmentItems: ShipmentItemSchema[]) {
    this.shipmentItems = shipmentItems
  }

  public isPending () {
    return _.isEmpty(this.shipmentItems)
  }

  public isDelivered () {
    const delivered = _.filter(this.shipmentItems, (item) => item.isDelivered)
    return !_.isEmpty(delivered)
  }

  public isInTransit () {
    if (this.isPending()) {
      return false
    }

    return !this.isDelivered()
  }

  public isLate () {
    if (!this?.shipment || !this?.shipment?.carrierEta) {
      return false
    }

    const now = new Date()

    if (this.isPending() || this.isDelivered()) {
      return false
    }

    return this.shipment.carrierEta < now
  }

  public isDelayed () {
    if (!this?.shipment || !this?.shipment?.carrierEta) {
      return false
    }

    const now = new Date()

    return !this.isDelivered() && !_.isEmpty(this.shipment?.shipmentDelays)
  }

  public shipmentStatusSummary () {
    return {
      pending: this.isPending(),
      delivered: this.isDelivered(),
      late: this.isLate(),
      inTransit: this.isInTransit(),
      delayed: this.isDelayed(),
    }
  }
}
