import React, { useCallback, useEffect, useState } from 'react'
import { AppDrawer, AppDrawerToolbar, AppDrawerContent, AppDrawerActions } from '../../Components/AppDrawer'
import { LabContactSchema } from '../../Schema/LabContactSchema'
import { LabContactForm } from './Components/LabContactForm'
import { v4 as uuid } from 'uuid'
import { Box, Button, CircularProgress, Paper, Typography } from '@material-ui/core'
import { useAppStyles } from '../../Hooks/useAppStyles'
import { useUpdateLabContact } from './Hooks/useUpdateLabContact'
import { BaseSchema } from '../../Schema/BaseSchema'
import { useLabContactSelector } from './Hooks/useLabContactSelector'

interface LabContactEditDrawerProps {
  labContactId: string
  open: boolean
  onClose: () => void,
}

export const LabContactEditDrawer: React.FC<LabContactEditDrawerProps> = ({
  open,
  onClose,
  labContactId,
}) => {
  const appStyles = useAppStyles()
  const { updateLabContact, processing } = useUpdateLabContact()

  const labContactState = useLabContactSelector(labContactId)

  const [hash, setHash] = useState('')
  const [labContact, setLabContact] = React.useState<LabContactSchema>(null)

  const handleOnSchemaChanged = useCallback((schema: LabContactSchema) => {
    setHash(uuid())
  }, [])

  useEffect(() => {
    if (!labContactState || !(labContactState instanceof BaseSchema)) {
      return
    }
    setLabContact(labContactState.clone())
  }, [labContactState])

  const handleOnSave = useCallback(() => {
    if (labContact.validate()) {
      updateLabContact(labContact).then(() => {
        onClose()
      }).catch(() => {

      })
    }
    setHash(uuid())
  }, [labContact, updateLabContact, onClose])

  return (
    <AppDrawer
      width={400}
      open={open}
      onClose={onClose}
    >
      <AppDrawerToolbar>
        <Typography variant={'h5'}>Edit Lab Contact</Typography>
      </AppDrawerToolbar>
      <AppDrawerContent>
        <Box className={appStyles.content}>
          <Paper className={appStyles.content}>
            {!labContact && <CircularProgress />}
            {labContact && <LabContactForm schema={labContact} onChange={handleOnSchemaChanged} />}
          </Paper>
        </Box>
      </AppDrawerContent>
      <AppDrawerActions>
        <Button disabled={processing} color={'primary'} onClick={handleOnSave}>Save</Button>
      </AppDrawerActions>
    </AppDrawer>
  )
}
