import React, { useCallback, useState } from 'react'
import { AppDrawer, AppDrawerToolbar, AppDrawerContent, AppDrawerActions } from '../../Components/AppDrawer'
import { v4 as uuid } from 'uuid'
import { Box, Button, Paper, Typography } from '@material-ui/core'
import { useAppStyles } from '../../Hooks/useAppStyles'
import { useAddChargeCode } from './Hooks/useAddChargeCode'
import { ChargeCodeSchema } from '../../Schema/ChargeCodeSchema'
import { ChargeCodeForm } from './Components/ChargeCodeForm'
import { useSnackbarMessages } from '../../Components/AppSnackbar/Hooks/useSnackbarMessages'

interface ChargeCodeAddDrawerProps {
  open: boolean
  onClose: (schema?: ChargeCodeSchema) => void,
  defaultData?: Partial<ChargeCodeSchema>
}

export const ChargeCodeAddDrawer: React.FC<ChargeCodeAddDrawerProps> = ({
  open,
  onClose,
  defaultData,
}) => {
  const { addSuccessMessage, addErrorMessage } = useSnackbarMessages()
  const appStyles = useAppStyles()
  const { addChargeCode, processing } = useAddChargeCode()

  const [hash, setHash] = useState('')
  const [schema, setSchema] = React.useState(new ChargeCodeSchema(defaultData))

  const handleOnSchemaChanged = useCallback((schema: ChargeCodeSchema) => {
    setHash(uuid())
  }, [])

  const handleOnSave = useCallback(() => {
    if (schema.validate()) {
      addChargeCode(schema).then(result => {
        addSuccessMessage('The charge code created successfully.')
        setSchema(new ChargeCodeSchema(defaultData))
        onClose(result)
      }).catch(() => {
        addErrorMessage('Failed to create the charge code.')
      })
    }
    setHash(uuid())
  }, [schema, addChargeCode, onClose, addSuccessMessage, addErrorMessage, defaultData])

  return (
    <AppDrawer
      width={400}
      open={open}
      onClose={onClose}
    >
      <AppDrawerToolbar>
        <Typography variant={'h5'}>Add Charge Code</Typography>
      </AppDrawerToolbar>
      <AppDrawerContent>
        <Box className={appStyles.content}>
          <Paper className={appStyles.content}>
            <ChargeCodeForm schema={schema} onChange={handleOnSchemaChanged} />
          </Paper>
        </Box>
      </AppDrawerContent>
      <AppDrawerActions>
        <Button disabled={processing} color={'primary'} onClick={handleOnSave}>Save</Button>
      </AppDrawerActions>
    </AppDrawer>
  )
}
