import { Grid } from '@material-ui/core'
import React, { useCallback } from 'react'
import { SchemaSwitchInput } from '../../../Components/Inputs/Schema/SchemaSwitchInput'
import { SchemaTextInput } from '../../../Components/Inputs/Schema/SchemaTextInput'
import { ShipmentItemSchema } from '../../../Schema/ShipmentItemSchema'
import { SchemaDateTimeInput } from '../../../Components/Inputs/Schema/SchemaDateTimeInput'

interface ShipmentItemFormProps {
  schema: ShipmentItemSchema
  onChange: (schema: ShipmentItemSchema) => void
}

export const ShipmentItemDeliveryForm: React.FC<ShipmentItemFormProps> = ({
  schema,
  onChange,
}) => {
  const handleOnSchemaChanged = useCallback((value: any, field: string) => {
    schema.setValue(field, value)
    onChange(schema)
  }, [onChange, schema])

  return (
    <>
      <Grid item xs={12}>
        <SchemaSwitchInput
          label={'Is Delivered?'}
          schema={schema}
          field={'isDelivered'}
          required
          onChange={handleOnSchemaChanged}
        />
      </Grid>
      {schema.isDelivered && (
        <>
          <Grid item xs={12}>
            <SchemaDateTimeInput
              type={'datetime'}
              label={'Delivery Date'}
              schema={schema}
              field={'deliveryDate'}
              onChange={handleOnSchemaChanged}
            />
          </Grid>
          <Grid item xs={12}>
            <SchemaTextInput
              label={'Signed By'}
              placeholder={'Enter a Signed By'}
              schema={schema}
              field={'signedBy'}
              onChange={handleOnSchemaChanged}
            />
          </Grid>
        </>
      )}
    </>
  )
}
