import React, { useCallback, useEffect } from 'react'

import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core'

interface ConfirmationDialogProps {
  open: boolean
  title?: string
  cancelText?: string,
  continueText?: string,
  confirmationText?: string,
  onCancel: () => void,
  onConfirm: () => void,
}

export const ConfirmationDialog: React.FC<ConfirmationDialogProps> = ({
  open,
  title,
  cancelText = 'Cancel',
  continueText = 'OK',
  confirmationText = 'Are you sure?',
  onCancel,
  onConfirm,
}) => {
  const [isOpen, setIsOpen] = React.useState(false)

  useEffect(() => {
    if (open && !isOpen) {
      setIsOpen(true)
    } else if (!open && isOpen) {
      setIsOpen(false)
    }
  }, [open, isOpen])

  const handleOnOk = useCallback((e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation()
    setIsOpen(false)
    if (onConfirm) {
      onConfirm()
    }
  }, [onConfirm])

  const handleOnClose = useCallback((e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation()
    setIsOpen(false)
    if (onCancel) {
      onCancel()
    }
  }, [onCancel])

  return (
    <Dialog
      open={isOpen}
      onClose={handleOnClose}
    >
      {title && (
        <DialogTitle>{title}</DialogTitle>
      )}
      <DialogContent>
        <DialogContentText>
          {confirmationText}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleOnClose} color="primary">
          {cancelText}
        </Button>
        <Button onClick={handleOnOk} color="primary" autoFocus>
          {continueText}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ConfirmationDialog
