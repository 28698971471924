
import React from 'react'
import { Route, Switch } from 'react-router-dom'
import clsx from 'clsx'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import Drawer from '@material-ui/core/Drawer'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import CssBaseline from '@material-ui/core/CssBaseline'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import { Box, Paper } from '@material-ui/core'

import { LeftMenu } from './Components/LeftMenu'

import { AppRoutes } from './AppRoutes'
import { RouteWithSubRoutes } from './Components/RouteWithSubRoutes'
import { AppLogo } from '../Components/AppLogo'
import { AppDataPreloader } from './AppDataPreloader'
import { UserProfile } from '../Auth/Components/UserProfile'
import Configs from '../Configs'
import { ShipmentManagement } from '../App/ShipmentsManagement'

export const AppLayout: React.FC = () => {
  const classes = useStyles({})
  const theme = useTheme()
  const [open, setOpen] = React.useState(false)

  const handleDrawerOpen = () => {
    setOpen(true)
  }

  const handleDrawerClose = () => {
    setOpen(false)
  }

  return (
    <AppDataPreloader>
    <Box className={classes.root}>

      <CssBaseline />
      <AppBar
        color={'inherit'}
        position="fixed"
        elevation={1}
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, {
              [classes.hide]: open,
            })}
          >
            <MenuIcon />
          </IconButton>
          <AppLogo className={classes.appLogo} />
          <Typography variant="h4" noWrap style={{ marginLeft: 10, flex: 1 }}>
            All Star Delivery
          </Typography>
          <UserProfile />
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
        open={open}
      >
        <div className={classes.toolbar}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl'
              ? (
              <ChevronRightIcon />
                )
              : (
              <ChevronLeftIcon />
                )}
          </IconButton>
        </div>
        <Divider />
        <LeftMenu open={open} />
      </Drawer>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Switch>
          <Route path={'/shipment-management/:section/:entityId/:action'} component={ShipmentManagement} />
          <Route path={'/shipment-management/:section/:entityId'} component={ShipmentManagement} />
          <Route path={'/shipment-management/:section'} component={ShipmentManagement} />
        {AppRoutes.map((route, i) => (
            <RouteWithSubRoutes key={i} {...route} />
        ))}
        </Switch>
      </main>
      <Paper className={classes.version}>{Configs.appVersion}</Paper>
    </Box>
    </AppDataPreloader>
  )
}

const drawerWidth = 240

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appLogo: {
    width: 'auto',
    height: 36,
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    // padding: theme.spacing(2),
  },
  version: {
    position: 'fixed',
    bottom: 2,
    padding: 1,
    right: 2,
  },
}))
