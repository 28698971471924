import { ShipmentItemsSelectors } from '../ShipmentItems/StoreAdapter'
import { createSelector } from '@reduxjs/toolkit'
import _, { memoize } from 'lodash'
import { ShipmentSelectors } from './StoreAdapter'
import { getConsigneeSelector } from '../Consignees/Selectors'
import { getLabContactSelector } from '../LabContacts/Selectors'
import { getLabSelector } from '../Labs/Selectors'

export const getShipmentItemsGroupByShipmentSelector = createSelector(
  ShipmentItemsSelectors.selectAll,
  (items) =>
    memoize(() => {
      return _.groupBy(items, (item) => item.shipmentId)
    }),
)

export const getShipmentDeliveryProgressByShipmentIdSelector = createSelector(
  getShipmentItemsGroupByShipmentSelector,
  (getShipmentItemsGroupByShipment) =>
    memoize(() => {
      const items = getShipmentItemsGroupByShipment()
      return _.keyBy(
        _.map(items, (shipmentItems, shipmentId) => {
          return {
            shipmentId,
            delivered: _.filter(shipmentItems, (item) => item.isDelivered)
              .length,
            total: shipmentItems?.length || 0,
          }
        }),
        (item) => item.shipmentId,
      )
    }),
)

export const getUndeliveredShipmentItemsSelector = createSelector(
  ShipmentItemsSelectors.selectAll,
  (items) =>
    memoize(() => {
      return _.filter(items, (item) => !item.isDelivered)
    }),
)

export const getUndeliveredShipmentIdsSelector = createSelector(
  getUndeliveredShipmentItemsSelector,
  (getUndeliveredShipmentItemsSelector) =>
    memoize(() => {
      const items = getUndeliveredShipmentItemsSelector()
      return _.uniq(_.map(items, (item) => item.shipmentId))
    }),
)

export const getDeliveredShipmentIdsSelector = createSelector(
  getShipmentDeliveryProgressByShipmentIdSelector,
  (getShipmentDeliveryProgressByShipmentId) =>
    memoize(() => {
      const shipments = getShipmentDeliveryProgressByShipmentId()
      return _.map(
        _.filter(shipments, (item) => {
          return item.total > 0 && item.total === item.delivered
        }),
        (item) => item.shipmentId,
      )
    }),
)

export const getPendingShipmentIdsSelector = createSelector(
  ShipmentSelectors.selectAll,
  ShipmentItemsSelectors.selectAll,
  (shipments, items) =>
    memoize(() => {
      const idsWithItems = _.uniq(_.map(items, (item) => item.shipmentId))
      return _.map(
        _.filter(
          shipments,
          (shipment) => !_.includes(idsWithItems, shipment.id),
        ),
        (shipment) => shipment.id,
      )
    }),
)

export const getLateShipmentIdsSelector = createSelector(
  ShipmentSelectors.selectAll,
  getUndeliveredShipmentItemsSelector,
  (shipments, getUndeliveredShipmentItemsSelector) =>
    memoize(() => {
      const now = new Date()
      const groupedShipments = _.keyBy(shipments, (item) => item.id)
      const items = getUndeliveredShipmentItemsSelector()
      const undeliveredItems = _.filter(
        items,
        (item) =>
          groupedShipments[item.shipmentId]?.carrierEta &&
          groupedShipments[item.shipmentId]?.carrierEta < now,
      )
      return _.map(undeliveredItems, (item) => item.shipmentId)
    }),
)

export const getDelayedShipmentIdsSelector = createSelector(
  ShipmentSelectors.selectAll,
  getUndeliveredShipmentIdsSelector,
  (items, getUndeliveredShipmentIds) =>
    memoize(() => {
      const undeliveredIds = getUndeliveredShipmentIds()
      return _.map(
        _.filter(
          items,
          (item) =>
            _.includes(undeliveredIds, item.id) &&
            item.shipmentDelays?.length > 0,
        ),
        (item) => item.id,
      )
    }),
)

export const getShipmentIdsMatchingSearchSelector = createSelector(
  ShipmentSelectors.selectAll,
  ShipmentItemsSelectors.selectAll,
  getConsigneeSelector,
  getLabContactSelector,
  (shipments, items, getConsignee, getLabContact) =>
    memoize((search: string) => {
      const exp = new RegExp(String(search), 'gi')

      const ids: string[] = []
      _.forEach(shipments, (shipment) => {
        if (shipment.publicTrackingNumber?.match(exp)) {
          ids.push(shipment.id)
          return
        }
        if (shipment.srNumber?.match(exp)) {
          ids.push(shipment.id)
        }

        const consignee = getConsignee(shipment.consigneeId)
        if (consignee?.name?.match(exp)) {
          ids.push(shipment.id)
        }

        const labContact = getLabContact(shipment.labContactId)
        const lab = getLabContact(labContact?.labId)
        if (labContact?.name?.match(exp)) {
          ids.push(shipment.id)
        }

        if (labContact?.email?.match(exp)) {
          ids.push(shipment.id)
        }

        if (lab?.name?.match(exp)) {
          ids.push(shipment.id)
        }
      })
      _.forEach(items, (item) => {
        if (item.carrierTrackingNumber?.match(exp)) {
          ids.push(item.shipmentId)
        }
      })

      return _.uniq(ids)
    }),
)
