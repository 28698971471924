import React from 'react'
import { AuthConsumer, AuthProvider } from '../Auth/AuthProvider'
import { AppLayout } from './AppLayout'
import { AuthLayout } from './AuthLayout'

export const Layout: React.FC = () => {
  return (
    <AuthProvider>
      <AuthConsumer>
      {context => (
        context.profile && !context.expired
          ? (
            <AppLayout />
            )
          : (
            <AuthLayout />
            )
      )}
       </AuthConsumer>
    </AuthProvider>
  )
}
