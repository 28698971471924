import { Carriers } from '../App/Carriers'
import { ChargeCodes } from '../App/ChargeCodes'
import { ConsigneeManagement } from '../App/ConsigneeManagement'
import { Consignees } from '../App/Consignees'
import { Containers } from '../App/Containers'
import { Dashboard } from '../App/Dashboard'
import { Delays } from '../App/Delays'
import { LabContacts } from '../App/LabContacts'
import { LabManagement } from '../App/LabManagement'
import { Labs } from '../App/Labs'
import { Services } from '../App/Services'
import { Settings } from '../App/Settings'
import { ShipmentItems } from '../App/ShipmentItems'
import { ShipmentManagement } from '../App/ShipmentsManagement'
import { Shipments } from '../App/Shippments'
import { UserManagement } from '../App/UserManagement'
import { Users } from '../App/Users'

export interface AppRouteProps {
  path: string,
  component?: any,
  redirectTo?: string,
  exact?: boolean,
  routes?: AppRouteProps[]
}

export const AppRoutes: AppRouteProps[] = [
  {
    path: '/',
    component: Dashboard,
    exact: true,
  },
  {
    path: '/test',
    redirectTo: '/',
    exact: true,
  },
  /* {
    path: '/shipment-management',
    component: ShipmentManagement,
    routes: [
      {
        path: '/shipment-management/shipments/:entityId/:action',
        component: Shipments,
      },
      {
        path: '/shipment-management/shipments/:entityId',
        component: Shipments,
      },
      {
        path: '/shipment-management/shipments',
        component: Shipments,
      },
      {
        path: '/shipment-management/items/:entityId',
        component: ShipmentItems,
      },
      {
        path: '/shipment-management/items',
        component: ShipmentItems,
      },
    ],
  }, */
  {
    path: '/consignee-management',
    component: ConsigneeManagement,
    routes: [
      {
        path: '/consignee-management/consignees/:entityId',
        component: Consignees,
      },
      {
        path: '/consignee-management/consignees',
        component: Consignees,
      },
    ],
  },
  {
    path: '/user-management',
    component: UserManagement,
    routes: [
      {
        path: '/user-management/users/:userId',
        component: Users,
      },
      {
        path: '/user-management/users',
        component: Users,
      },
    ],
  },
  {
    path: '/lab-management',
    component: LabManagement,
    routes: [
      {
        path: '/lab-management/labs/:labId/:action',
        component: Labs,
      },
      {
        path: '/lab-management/labs/:labId',
        component: Labs,
      },
      {
        path: '/lab-management/labs',
        component: Labs,
      },
      {
        path: '/lab-management/contacts/:labContactId/:action',
        component: LabContacts,
      },
      {
        path: '/lab-management/contacts/:labContactId',
        component: LabContacts,
      },
      {
        path: '/lab-management/contacts',
        component: LabContacts,
      },
    ],
  },
  {
    path: '/settings',
    component: Settings,
    routes: [
      {
        path: '/settings/delays/:entityId',
        component: Delays,
      },
      {
        path: '/settings/delays',
        component: Delays,
      },
      {
        path: '/settings/carriers/:entityId',
        component: Carriers,
      },
      {
        path: '/settings/carriers',
        component: Carriers,
      },
      {
        path: '/settings/charge-codes/:entityId',
        component: ChargeCodes,
      },
      {
        path: '/settings/charge-codes',
        component: ChargeCodes,
      },
      {
        path: '/settings/services/:entityId',
        component: Services,
      },
      {
        path: '/settings/services',
        component: Services,
      },
      {
        path: '/settings/containers/:entityId',
        component: Containers,
      },
      {
        path: '/settings/containers',
        component: Containers,
      },
    ],
  },
]
