import { Box, IconButton, Tooltip } from '@material-ui/core'
import React, { useMemo } from 'react'
import Launch from '@material-ui/icons/LaunchOutlined'
import { useCarrierSelector } from '../Hooks/useCarrierSelector'
import { v4 as uuid } from 'uuid'
import crypto from 'crypto'

const hasher = (data: string) => {
  return crypto.createHash('md5').update(data).digest('hex')
}

interface CarrierExternalTrackingButtonProps {
  carrierId: string
  trackingNumber?: string
  onOpen?: () => void
  buttonSize?: 'small' | 'medium'
  size?: number
}

export const CarrierExternalTrackingButton: React.FC<CarrierExternalTrackingButtonProps> = ({
  carrierId,
  trackingNumber,
  onOpen = () => {},
  buttonSize = 'medium',
  size = 24,
}) => {
  const carrier = useCarrierSelector(carrierId)

  const trackingUrl = useMemo(() => {
    if (!carrier?.trackingUrl || !trackingNumber) {
      return null
    }
    return carrier?.trackingUrl?.replace('{{TrackingNumber}}', trackingNumber)
  }, [carrier?.trackingUrl, trackingNumber])

  const handleOnOpen = () => {
    if (onOpen) {
      onOpen()
    }
  }

  if (!trackingUrl) {
    return null
  }

  return (
    <Box onClick={(e) => {
      handleOnOpen()
      e.stopPropagation()
    }}>
      <Tooltip title={'Open Carrier Tracking Website'}>
        <IconButton target={hasher(trackingUrl)} href={trackingUrl} size={buttonSize}><Launch style={{ fontSize: 'inherit' }} /></IconButton>
      </Tooltip>
    </Box>
  )
}
