import { IconButton } from '@material-ui/core'
import { Delete } from '@material-ui/icons'
import React, { useState } from 'react'
import { AppPermission, useAppPermissions } from '../../Hooks/useAppPermissions'
import ConfirmationDialog from '../Dialogs/ConfirmationDialog'

interface DeleteIconButtonProps {
  size?: 'small' | 'medium'
  onDelete?: Function
  permissions?: AppPermission | AppPermission[]
  disabled?: boolean
}

export const DeleteIconButton: React.FC<DeleteIconButtonProps> = ({
  size,
  onDelete = () => {},
  permissions,
  disabled,
}) => {
  const { checkPermissions } = useAppPermissions()

  const [openConfirmation, setOpenConfirmation] = useState(false)

  if (permissions && !checkPermissions(permissions)) {
    return null
  }

  const handleOnConfirm = () => {
    onDelete()
    setOpenConfirmation(false)
  }

  return (
    <IconButton
      disabled={disabled}
      size={size}
      onClick={event => setOpenConfirmation(true)}
    >
      <Delete />
      <ConfirmationDialog
        open={openConfirmation}
        onCancel={() => setOpenConfirmation(false)}
        onConfirm={handleOnConfirm}
      />
    </IconButton>
  )
}
