import React from 'react'
import { IconButton, Tooltip } from '@material-ui/core'

import { IconButtonProps } from '@material-ui/core/IconButton'
import { SvgIconProps } from '@material-ui/core/SvgIcon'
import { GetApp } from '@material-ui/icons'

interface DownloadButtonProps {
  buttonProps?: IconButtonProps;
  iconProps?: SvgIconProps;
  disabled?: boolean;
  className?: string;
  onClick?: Function;
}

export const DownloadButton: React.FC< DownloadButtonProps > = ({
  buttonProps,
  iconProps,
  disabled = false,
  className,
  onClick = () => {},
}) => {
  const handleOnClick = () => {
    onClick()
  }

  return (
    <IconButton
      color={'primary'}
      className={className}
      //  size="small"
      {...buttonProps}
      disabled={disabled}
      onClick={() => handleOnClick()}
    >
      <Tooltip title="Download">
        <GetApp {...iconProps} />
      </Tooltip>
    </IconButton>
  )
}
