import React, { useCallback } from 'react'
import { useSnackbarMessages } from '../../../Components/AppSnackbar/Hooks/useSnackbarMessages'
import { DeleteIconButton } from '../../../Components/Buttons/DeleteIconButton'
import { AppPermission } from '../../../Hooks/useAppPermissions'
import { ConsigneeSchema } from '../../../Schema/ConsigneeSchema'
import { useDeleteConsignee } from '../Hooks/useDeleteConsignee'

interface DeleteConsigneeButtonProps {
  schema: ConsigneeSchema
  onDeleted?: () => void
}

export const DeleteConsigneeButton: React.FC<DeleteConsigneeButtonProps> = ({
  schema,
  onDeleted = () => {},
}) => {
  const { addSuccessMessage, addErrorMessage } = useSnackbarMessages()
  const { deleteConsignee, processing, errorMessage } = useDeleteConsignee()

  const handleOnDelete = useCallback(() => {
    deleteConsignee(schema.id).then(() => {
      addSuccessMessage('The carrier deleted successfully.')
      onDeleted()
    }).catch(() => {
      addErrorMessage(errorMessage)
    })
  }, [schema?.id, addSuccessMessage, addErrorMessage, deleteConsignee, errorMessage, onDeleted])

  return (
    <DeleteIconButton permissions={[AppPermission.ConsigneeDelete]} onDelete={handleOnDelete} disabled={processing} />
  )
}
