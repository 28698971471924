import React from 'react'
import { BaseSchema } from '../../../Schema/BaseSchema'
import { YesNoType } from '../../../Utils/Types'
import { YesNoPicker } from '../YesNoPicker'

interface SchemaYesNoPickerProps {
  inputClassName?: any
  className?: any
  schema: BaseSchema
  label?: string
  field: string
  helperText?: string
  onChange: Function
  disabled?: boolean
  required?: boolean
  variant?: 'standard' | 'filled' | 'outlined'
  placeholder?: string
  freeSolo?: boolean
  noOptionsText?: string
  clearOnBlur?: boolean
  showAvatar?: boolean
}

export const SchemaYesNoPicker: React.FC<SchemaYesNoPickerProps> = ({
  className,
  schema,
  field,
  label,
  helperText,
  onChange,
  disabled,
  required,
  variant,
  placeholder,
  freeSolo,
  noOptionsText,
  clearOnBlur,
  showAvatar,
}) => {
  return (
    <YesNoPicker
      className={className}
      disabled={disabled}
      label={label}
      value={schema.getValue(field) as YesNoType || null}
      error={schema.isPropertyInvalid(field)}
      helperText={schema.isPropertyInvalid(field) ? schema.getPropertyError(field) : helperText }
      onChange={value => onChange(value, field)}
      required={required}
      variant={variant}
      placeholder={placeholder}
      noOptionsText={noOptionsText}
      clearOnBlur={clearOnBlur}
      showAvatar={showAvatar}
    />
  )
}
