import { Type } from 'class-transformer'
import {
  IsBoolean,
  IsEnum,
  IsNotEmpty,
  IsOptional,
  IsString,
  IsUUID,
  ValidateIf,
} from 'class-validator'
import _ from 'lodash'
import { InputOptionProps } from '../Components/Inputs/Types'
import { BaseSchema } from './BaseSchema'

export enum ConsigneeType {
  International = 'I',
  Domestic = 'D',
}

export enum CourierDefault {
  Yes = 'Y',
  No = 'N',
}

export class ConsigneeSchema extends BaseSchema {
  /**
   * User ID
   */
  @IsOptional()
  @IsUUID()
  id: string

  /**
   * Code
   */
  @Type(() => String)
  @IsNotEmpty()
  @IsString()
  name: string = null

  /**
   * Address
   */
  @Type(() => String)
  @IsString()
  @IsNotEmpty()
  address: string = null

  /**
   * City
   */
  @Type(() => String)
  @IsNotEmpty()
  @IsString()
  city: string = null

  /**
   * Region
   */
  @Type(() => String)
  @ValidateIf((o) => _.includes(['US', 'CA'], o.country))
  @IsNotEmpty()
  @IsString()
  region: string = null

  /**
   * Postal
   */
  @Type(() => String)
  @IsNotEmpty()
  @IsString()
  postalCode: string = null

  /**
   * Country
   */
  @Type(() => String)
  @IsNotEmpty()
  @IsString()
  country: string = null

  /**
   * Consignee Type
   */
  @IsNotEmpty()
  @IsEnum(ConsigneeType)
  type: ConsigneeType

  /**
   * Is Enabled?
   */
  @Type(() => Boolean)
  @IsNotEmpty()
  @IsBoolean()
  courierEnabled: boolean = false

  /**
   * Is Enabled?
   */
  @Type(() => Boolean)
  @IsNotEmpty()
  @IsBoolean()
  isEnabled: boolean = true

  /**
   * Rank
   */
  @Type(() => Number)
  rank: number = 0

  /**
   * Date created
   */
  @Type(() => Date)
  created: Date

  /**
   * Updated by
   */
  createdBy: string

  /**
   * Date updated
   */
  @Type(() => Date)
  updated: Date

  /**
   * Updated by
   */
  updatedBy: string

  /**
   * Date deleted
   */
  @Type(() => Date)
  deleted: Date

  /**
   * Deleted by
   */
  deletedBy: string

  /**
   * Construct
   * @param data
   */
  constructor (data?: Partial<ConsigneeSchema>) {
    super()
    _.assign(this, data)
  }

  /**
   * Returns a string for sorting
   * @returns string
   */
  getSortingValue () {
    return `${this.name}`
  }

  /**
   * Check if an address is international
   * @returns
   */
  public isInternationAddress () {
    return this.country !== 'US'
  }

  /**
   * Get Consignee Type label
   * @returns
   */
  public getTypeLabel () {
    switch (this.type) {
      case ConsigneeType.Domestic:
        return 'Domestic'
      case ConsigneeType.International:
        return 'International'
      default:
        return '-'
    }
  }

  /**
   * Get International status
   * @returns
   */
  public isInternational () {
    return (
      this.country !== 'US' ||
      (this.country === 'US' && _.includes(['AK', 'HI'], this.region))
    )
  }

  /**
   *
   * @returns
   */
  public getAddress () {
    return `${this.address}, ${this.city}, ${this.region}, ${this.postalCode}, ${this.country}`
  }

  /**
   *
   */
  public getInputOption (): InputOptionProps {
    return {
      value: this.id,
      label: this.name,
      subtitle: this.getAddress(),
    }
  }
}
