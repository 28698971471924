import React, { useCallback, useState } from 'react'
import { AppDrawer, AppDrawerToolbar, AppDrawerContent, AppDrawerActions } from '../../Components/AppDrawer'
import { v4 as uuid } from 'uuid'
import { Box, Button, Paper, Typography } from '@material-ui/core'
import { useAppStyles } from '../../Hooks/useAppStyles'
import { useAddContainer } from './Hooks/useAddContainer'
import { ContainerSchema } from '../../Schema/ContainerSchema'
import { ContainerForm } from './Components/ContainerForm'
import { useSnackbarMessages } from '../../Components/AppSnackbar/Hooks/useSnackbarMessages'

interface ContainerAddDrawerProps {
  defaultData?: Partial<ContainerSchema>,
  open: boolean
  onClose: (schema?: ContainerSchema) => void,
}

export const ContainerAddDrawer: React.FC<ContainerAddDrawerProps> = ({
  defaultData,
  open,
  onClose,
}) => {
  const { addSuccessMessage, addErrorMessage } = useSnackbarMessages()
  const appStyles = useAppStyles()
  const { addContainer, processing } = useAddContainer()

  const [hash, setHash] = useState('')
  const [schema, setSchema] = React.useState(new ContainerSchema(defaultData))

  const handleOnSchemaChanged = useCallback((schema: ContainerSchema) => {
    setHash(uuid())
  }, [])

  const handleOnSave = useCallback(() => {
    if (schema.validate()) {
      addContainer(schema).then(() => {
        addSuccessMessage('Container updated successfully.')
        setSchema(new ContainerSchema(defaultData))
        onClose(schema)
      }).catch(() => {
        addErrorMessage('Failed to update the container.')
      })
    }
    setHash(uuid())
  }, [schema, addContainer, onClose, addSuccessMessage, addErrorMessage, defaultData])

  return (
    <AppDrawer
      width={400}
      open={open}
      onClose={onClose}
    >
      <AppDrawerToolbar>
        <Typography variant={'h5'}>Add Container</Typography>
      </AppDrawerToolbar>
      <AppDrawerContent>
        <Box className={appStyles.content}>
          <Paper className={appStyles.content}>
            <ContainerForm schema={schema} onChange={handleOnSchemaChanged} />
          </Paper>
        </Box>
      </AppDrawerContent>
      <AppDrawerActions>
        <Button disabled={processing} color={'primary'} onClick={handleOnSave}>Save</Button>
      </AppDrawerActions>
    </AppDrawer>
  )
}
