import { createSelector } from '@reduxjs/toolkit'
import _, { memoize } from 'lodash'
import { ContainerSelectors } from './StoreAdapter'
import { getShipmentItemsForShipmentSelector } from '../ShipmentItems/Selectors'

export const getContainersForShipmentSelector = createSelector(
  ContainerSelectors.selectAll,
  getShipmentItemsForShipmentSelector,
  (containers, getShipmentItemsForShipment) =>
    memoize((shipmentId: string) => {
      const items = getShipmentItemsForShipment(shipmentId)
      const containerIds = _.map(items, (item) => item.containerId)
      return _.filter(containers, (container) =>
        _.includes(containerIds, container.id),
      )
    }),
)
