import { IconButton } from '@material-ui/core'
import { Edit } from '@material-ui/icons'
import React from 'react'
import { AppPermission, useAppPermissions } from '../../Hooks/useAppPermissions'

interface EditIconButtonProps {
  size?: 'small' | 'medium'
  onClick?: Function
  permissions?: AppPermission | AppPermission[]
  color?: 'default' | 'inherit' | 'primary' | 'secondary'
}

export const EditIconButton: React.FC<EditIconButtonProps> = ({
  size,
  onClick = () => {},
  permissions,
  color,
}) => {
  const { checkPermissions } = useAppPermissions()

  if (permissions && !checkPermissions(permissions)) {
    return null
  }

  return (
    <IconButton
      size={size}
      onClick={event => onClick(event)}
      color={color}
    >
      <Edit />
    </IconButton>
  )
}
