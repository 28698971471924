import { Type } from 'class-transformer'
import {
  IsAlphanumeric,
  IsBoolean,
  IsNotEmpty,
  IsOptional,
  IsString,
  IsUUID,
  MaxLength,
} from 'class-validator'
import _ from 'lodash'
import { BaseSchema } from './BaseSchema'

export class ChargeCodeSchema extends BaseSchema {
  /**
   * User ID
   */
  @IsOptional()
  @IsUUID()
  id: string

  /**
   * Code
   */
  @Type(() => String)
  @IsNotEmpty()
  @IsAlphanumeric()
  @IsString()
  @MaxLength(36)
  code: string = null

  /**
   * Description
   */
  @IsOptional()
  description: string = null

  /**
   * Is Enabled?
   */
  @Type(() => Boolean)
  @IsNotEmpty()
  @IsBoolean()
  isEnabled: boolean = true

  /**
   * Date created
   */
  @Type(() => Date)
  created: Date

  /**
   * Updated by
   */
  createdBy: string

  /**
   * Date updated
   */
  @Type(() => Date)
  updated: Date

  /**
   * Updated by
   */
  updatedBy: string

  /**
   * Date deleted
   */
  @Type(() => Date)
  deleted: Date

  /**
   * Deleted by
   */
  deletedBy: string

  /**
   * Construct
   * @param data
   */
  constructor (data?: Partial<ChargeCodeSchema>) {
    super()
    _.assign(this, data)
  }

  /**
   * Returns a string for sorting
   * @returns string
   */
  getSortingValue () {
    return `${this.code}`
  }
}
