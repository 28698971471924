import { Type } from 'class-transformer'
import {
  IsBoolean,
  IsInt,
  IsNotEmpty,
  IsOptional,
  IsString,
  IsUUID,
} from 'class-validator'
import _ from 'lodash'
import { BaseSchema } from './BaseSchema'

export class ContainerSchema extends BaseSchema {
  /**
   * User ID
   */
  @IsOptional()
  @IsUUID()
  id: string

  /**
   * Code
   */
  @Type(() => String)
  @IsNotEmpty()
  @IsString()
  name: string = null

  /**
   * Code
   * (Auto Generated (length)x(width)x(height))
   */
  @Type(() => String)
  @IsNotEmpty()
  @IsString()
  dimensionalLabel: string = null

  /**
   * Dimensional Weight
   * (Calculated = ROUND((Length * Width * Height / 139) +.5, 0))
   */
  @Type(() => Number)
  @IsNotEmpty()
  @IsInt()
  dimensionalWeight: number = null

  /**
   * Length
   */
  @Type(() => Number)
  @IsNotEmpty()
  @IsInt()
  length: number = null

  /**
   * Width
   */
  @Type(() => Number)
  @IsNotEmpty()
  @IsInt()
  width: number = null

  /**
   * Height
   */
  @Type(() => Number)
  @IsNotEmpty()
  @IsInt()
  height: number = null

  /**
   * Is Enabled?
   */
  @Type(() => Boolean)
  @IsNotEmpty()
  @IsBoolean()
  isEnabled: boolean = true

  /**
   * Date created
   */
  @Type(() => Date)
  created: Date

  /**
   * Updated by
   */
  createdBy: string

  /**
   * Date updated
   */
  @Type(() => Date)
  updated: Date

  /**
   * Updated by
   */
  updatedBy: string

  /**
   * Date deleted
   */
  @Type(() => Date)
  deleted: Date

  /**
   * Deleted by
   */
  deletedBy: string

  /**
   * Construct
   * @param data
   */
  constructor (data?: Partial<ContainerSchema>) {
    super()
    _.assign(this, data)
  }

  /**
   * Returns a string for sorting
   * @returns string
   */
  getSortingValue () {
    return `${this.name}`
  }

  /**
   * Generate dimensional label
   * @returns string
   */
  generateDimensionalLabel () {
    if (this.length && this.width && this.height) {
      this.dimensionalLabel = `${this.length}x${this.width}x${this.height}`
    } else {
      this.dimensionalLabel = ''
    }
    return this.dimensionalLabel
  }

  /**
   * Calculate dimensional weight
   * @returns
   */
  calculateDimensionalWeight () {
    let dWeight = 0
    if (this.length && this.width && this.height) {
      dWeight = Math.round((this.length * this.width * this.height) / 139 + 0.5)
    }
    this.dimensionalWeight = dWeight
    return this.dimensionalWeight
  }
}
