import _ from 'lodash'
import React from 'react'
import { LeftMenuItem, LeftMenuItemProps } from './LeftMenuItem'

// icons
import DashboardIcon from '@material-ui/icons/Dashboard'
import UsersIcon from '@material-ui/icons/Group'
import SettingsIcon from '@material-ui/icons/Settings'
import BusinesIcon from '@material-ui/icons/Business'
import ContactsIcon from '@material-ui/icons/Contacts'
import ShippingIcon from '@material-ui/icons/LocalShipping'

const menuItems: LeftMenuItemProps[] = [
  { title: 'Dashboard', icon: DashboardIcon, route: '/' },
  { title: 'Shipments', icon: ShippingIcon, route: '/shipment-management/shipments' },
  { title: 'Consignees', icon: ContactsIcon, route: '/consignee-management/consignees' },
  { title: 'Labs', icon: BusinesIcon, route: '/lab-management/labs' },
  { title: 'Users', icon: UsersIcon, route: '/user-management/users' },
  { title: 'Settings', icon: SettingsIcon, route: '/settings/carriers' },
]

interface LeftMenuProps {
  open: boolean
}

export const LeftMenu: React.FC<LeftMenuProps> = ({
  open,
}) => {
  return (
    <>
    {_.map(menuItems, menuItem => {
      return <LeftMenuItem key={menuItem.title} {...menuItem} showTooltip={!open} />
    })}
    </>
  )
}
