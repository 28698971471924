import { Box, Button, Typography } from '@material-ui/core'
import { Add } from '@material-ui/icons'
import React, { useCallback, useState } from 'react'
import { useSnackbarMessages } from '../../../Components/AppSnackbar/Hooks/useSnackbarMessages'
import { InputOptionProps } from '../../../Components/Inputs/Types'
import { useAppStyles } from '../../../Hooks/useAppStyles'
import { ShipmentSchema } from '../../../Schema/ShipmentSchema'
import { DelayPicker } from '../../Delays/Components/DelayPicker'
import { useAddShipmentDelay } from '../Hooks/useAddShipmentDelay'

interface ShipmentDelaysAddProps {
  shipment: ShipmentSchema
}

export const ShipmentDelaysAdd: React.FC<ShipmentDelaysAddProps> = ({
  shipment,
}) => {
  const appStyles = useAppStyles()
  const { addErrorMessage } = useSnackbarMessages()

  const { addShipmentDelay, processing } = useAddShipmentDelay()

  const [selectedDelayId, setSelectedDelayId] = useState<string>(null)

  const filterOptions = useCallback((option: InputOptionProps) => {
    return true
  }, [])

  const handleOnAdd = useCallback(() => {
    addShipmentDelay(shipment, selectedDelayId).then(() => {
      setSelectedDelayId(null)
    }).catch(() => {
      addErrorMessage('Failed to add the delay.')
    })
  }, [addErrorMessage, addShipmentDelay, selectedDelayId, shipment])

  return (
    <Box display={'flex'}>
      <DelayPicker
        value={selectedDelayId}
        className={appStyles.flexOne}
        filter={filterOptions}
        onChange={setSelectedDelayId}
        disabled={processing}
      />
      <Button disabled={processing} startIcon={<Add />} color={'primary'} onClick={handleOnAdd}>Add</Button>
    </Box>
  )
}
