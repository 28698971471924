import { Box } from '@material-ui/core'
import React from 'react'
import { useAppViews } from './useAppViews'

interface AppViewProps {
  value: string
  className?: any,
}

export const AppView: React.FC<AppViewProps> = ({
  children,
  value,
  className,
}) => {
  const appViews = useAppViews()

  if (!appViews) {
    return null
  }

  if (!appViews.hidden && value !== appViews.selected) {
    return null
  }

  return (
    <Box className={className} hidden={value !== appViews.selected}>
      {children}
    </Box>
  )
}
