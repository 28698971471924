import { createStyles, makeStyles, Theme } from '@material-ui/core'

export const useAppStyles = makeStyles((theme: Theme) =>
  createStyles({
    flexOne: {
      flex: 1,
    },
    flexRow: {
      display: 'flex',
      flexDirection: 'row',
    },
    content: {
      padding: theme.spacing(1),
      paddingLeft: theme.spacing(1),
    },
    contentItem: {
      marginBottom: theme.spacing(1),
    },
    tabContent: {
      marginTop: theme.spacing(1),
    },
  }),
)
