import React, { useCallback, useEffect, useState } from 'react'
import { v4 as uuid } from 'uuid'
import { Box, Button, CircularProgress, Paper, Typography } from '@material-ui/core'

import { AppDrawer, AppDrawerToolbar, AppDrawerContent, AppDrawerActions } from '../../Components/AppDrawer'
import { useAppStyles } from '../../Hooks/useAppStyles'
import { useUpdateConsignee } from './Hooks/useUpdateConsignee'
import { BaseSchema } from '../../Schema/BaseSchema'
import { useConsigneeSelector } from './Hooks/useConsigneeSelector'
import { ConsigneeSchema } from '../../Schema/ConsigneeSchema'
import { ConsigneeForm } from './Components/ConsigneeForm'
import { DeleteConsigneeButton } from './Components/DeleteConsigneeButton'

interface ConsigneeEditDrawerProps {
  schemaId: string
  open: boolean
  onClose: () => void,
}

export const ConsigneeEditDrawer: React.FC<ConsigneeEditDrawerProps> = ({
  open,
  onClose,
  schemaId,
}) => {
  const appStyles = useAppStyles()
  const { updateConsignee, processing } = useUpdateConsignee()

  const schemaState = useConsigneeSelector(schemaId)

  const [hash, setHash] = useState('')
  const [schema, setSchema] = React.useState<ConsigneeSchema>(null)

  const handleOnSchemaChanged = useCallback((schema: ConsigneeSchema) => {
    setHash(uuid())
  }, [])

  useEffect(() => {
    if (!schemaState || !(schemaState instanceof BaseSchema)) {
      setSchema(null)
      return
    }
    setSchema(schemaState.clone())
  }, [schemaState])

  const handleOnSave = useCallback(() => {
    if (schema.validate()) {
      updateConsignee(schema).then(() => {
        onClose()
      }).catch(() => {

      })
    }
    setHash(uuid())
  }, [schema, updateConsignee, onClose])

  return (
    <AppDrawer
      width={400}
      open={open}
      onClose={onClose}
    >
      <AppDrawerToolbar>
        <Typography variant={'h5'} style={{ flex: 1 }}>Edit Consignee</Typography>
        <DeleteConsigneeButton schema={schema} onDeleted={onClose} />
      </AppDrawerToolbar>
      <AppDrawerContent>
        <Box className={appStyles.content}>
          <Paper className={appStyles.content}>
            {!schema && <CircularProgress />}
            {schema && <ConsigneeForm schema={schema} onChange={handleOnSchemaChanged} />}
          </Paper>
        </Box>
      </AppDrawerContent>
      <AppDrawerActions>
        <Button disabled={processing} color={'primary'} onClick={handleOnSave}>Save</Button>
      </AppDrawerActions>
    </AppDrawer>
  )
}
