import { ForgotPassword } from './ForgotPassword'
import { Login } from './Login'
import { ResetPassword } from './ResetPassword'
import { Signup } from './Register'

export interface AppRouteProps {
  path: string,
  component?: any,
  redirectTo?: string,
  exact?: boolean,
  routes?: AppRouteProps[]
}

export const AuthRoutes: AppRouteProps[] = [
  {
    path: '/',
    redirectTo: '/login',
    exact: true,
  },
  {
    path: '/login',
    component: Login,
  },
  {
    path: '/register',
    component: Signup,
  },
  {
    path: '/forgot-password',
    component: ForgotPassword,
  },
  {
    path: '/reset-password/:token',
    component: ResetPassword,
  },
  {
    path: '/reset-password',
    component: ResetPassword,
  },
  {
    path: '/',
    component: Login,
    exact: false,
  },
]
