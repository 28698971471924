import { Box, Button, createStyles, Grid, makeStyles, Typography } from '@material-ui/core'
import { IsNotEmpty } from 'class-validator'
import {
  useGoogleReCaptcha,
} from 'react-google-recaptcha-v3'
import React, { useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { IsValidPassword } from './Utils'
import { BaseSchema } from '../Schema/BaseSchema'
import { useChangeForgottenPassword } from './Hooks/useChangeForgottenPassword'
import { useSnackbarMessages } from '../Components/AppSnackbar/Hooks/useSnackbarMessages'
import { SchemaTextInput } from '../Components/Inputs/Schema/SchemaTextInput'

class RequestObj extends BaseSchema {
  @IsNotEmpty({ message: 'Password is required.' })
  @IsValidPassword({ message: 'Invalid Password' })
  password: string
}

export const ResetPassword: React.FC = () => {
  const history = useHistory()
  const styles = useStyles()
  const { addSuccessMessage, addErrorMessage } = useSnackbarMessages()

  const { changePassword, processing } = useChangeForgottenPassword()

  const { token } = useParams<{token: string}>()

  const { executeRecaptcha } = useGoogleReCaptcha()

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [hash, setHash] = useState('')
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [obj, setObj] = useState(new RequestObj())

  const handleOnChange = (value: string, field: string) => {
    obj.setValue(field, value)
    setHash(obj.generateHash())
  }

  const handleOnPasswordChange = (event: React.FormEvent) => {
    event.preventDefault()
    if (!obj || !obj.validate()) {
      setHash(obj?.generateHash())
      return
    }
    if (executeRecaptcha) {
      executeRecaptcha('changePassword').then(challenge => {
        changePassword(token, obj.password, challenge).then(tokens => {
          addSuccessMessage('Password changed. Please login with the new password.')
          history.push('/login')
        }).catch(() => {
          addErrorMessage('Failed to change the password. Invalid or expired password reset token.')
        })
      }).catch(err => {
        console.log('captcha error', err)
      })
    }
  }

  if (!token) {
    addErrorMessage('Invalid or expired password reset token.')
    history.push('/login')
  }

  return (
    <Box className={styles.container}>
      <form onSubmit={handleOnPasswordChange}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography variant="body1">
            Please enter a new password. The password must be between 8-30
            characters and contain at least one uppercase and one number.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <SchemaTextInput
              type={'password'}
              disabled={processing}
              label={'Password'}
              schema={obj}
              field={'password'}
              onChange={handleOnChange}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              type={'submit'}
              disabled={processing}
              color={'primary'}
              fullWidth
              variant={'contained'}
            >
            Change Password
            </Button>
          </Grid>
        </Grid>
      </form>

      <Box marginTop={3} display={'flex'} alignItems={'center'}>
        <Button
          disabled={processing}
          onClick={() => history.push('/login')}
          color={'primary'}
        >
          Back to Login
        </Button>
      </Box>
    </Box>
  )
}

const useStyles = makeStyles(theme =>
  createStyles({
    container: {
      padding: theme.spacing(3),
    },
  }),
)
