import React, { FormEvent, useCallback, useEffect, useState } from 'react'
import { v4 as uuid } from 'uuid'
import { Box, Button, CircularProgress, Paper, Typography } from '@material-ui/core'

import { AppDrawer, AppDrawerToolbar, AppDrawerContent, AppDrawerActions } from '../../Components/AppDrawer'
import { useAppStyles } from '../../Hooks/useAppStyles'
import { useUpdateShipmentItem } from './Hooks/useUpdateShipmentItem'
import { BaseSchema } from '../../Schema/BaseSchema'
import { useShipmentItemSelector } from './Hooks/useShipmentItemSelector'
import { ShipmentItemSchema } from '../../Schema/ShipmentItemSchema'
import { ShipmentItemForm } from './Components/ShipmentItemForm'
import { DeleteShipmentItemButton } from './Components/DeleteShipmentItemButton'

interface ShipmentItemEditDrawerProps {
  schemaId: string
  open: boolean
  onClose: () => void,
}

export const ShipmentItemEditDrawer: React.FC<ShipmentItemEditDrawerProps> = ({
  open,
  onClose,
  schemaId,
}) => {
  const appStyles = useAppStyles()
  const { updateShipmentItem, processing } = useUpdateShipmentItem()

  const schemaState = useShipmentItemSelector(schemaId)

  const [hash, setHash] = useState('')
  const [schema, setSchema] = React.useState<ShipmentItemSchema>(null)

  const handleOnSchemaChanged = useCallback((schema: ShipmentItemSchema) => {
    setHash(uuid())
  }, [])

  useEffect(() => {
    if (!schemaState || !(schemaState instanceof BaseSchema)) {
      setSchema(null)
      return
    }
    setSchema(schemaState.clone())
  }, [schemaState?.id])

  const handleOnSave = useCallback(() => {
    if (schema.validate()) {
      updateShipmentItem(schema).then(() => {
        onClose()
      }).catch(() => {

      })
    }
    setHash(uuid())
  }, [schema, updateShipmentItem, onClose])

  const handleOnSubmit = useCallback((event: FormEvent) => {
    event.preventDefault()
    event.stopPropagation()
    handleOnSave()
  }, [handleOnSave])

  return (
    <AppDrawer
      width={400}
      open={open}
      onClose={onClose}
    >
      <form onSubmit={handleOnSubmit}>
        <AppDrawerToolbar>
          <Typography variant={'h5'} style={{ flex: 1 }}>Edit Shipment Item</Typography>
          <DeleteShipmentItemButton schema={schema} onDeleted={onClose} />
        </AppDrawerToolbar>
        <AppDrawerContent>
          <Box className={appStyles.content}>
            <Paper className={appStyles.content}>
              {!schema && <CircularProgress />}
              {schema && <ShipmentItemForm schema={schema} onChange={handleOnSchemaChanged} />}
            </Paper>
          </Box>
        </AppDrawerContent>
        <AppDrawerActions>
          <Button disabled={processing} color={'primary'} type={'submit'}>Save</Button>
        </AppDrawerActions>
      </form>
    </AppDrawer>
  )
}
