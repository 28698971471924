import React, { useCallback, useEffect, useState } from 'react'
import { UserSchema } from '../Schema/UserSchema'
import { useCookies } from 'react-cookie'
import { CircularProgress } from '@material-ui/core'
import { useFetchProfile } from './Hooks/useFetchProfile'
import { ServiceLocator } from '../Services/ServiceLocator'
import { AxiosClientFactory } from '../Services/AxiosClientFactory'
import { useHistory } from 'react-router'

interface AuthContextProps {
  profile: UserSchema
  expired: boolean
  logout: () => void
}

export const AuthContext = React.createContext<AuthContextProps>({
  profile: null,
  expired: true,
  logout: () => {},
})

export const AuthProvider: React.FC = ({
  children,
}) => {
  const { fetchProfile, processing } = useFetchProfile()
  const [cookies, setCookie, removeCookie] = useCookies(['accessToken', 'refreshToken'])
  const [profile, setProfile] = useState<UserSchema>(null)
  const history = useHistory()

  const [loading, setLoading] = useState<boolean>(!!cookies.accessToken)

  const logout = useCallback(() => {
    removeCookie('accessToken')
    removeCookie('refreshToken')
    setProfile(null)
    ServiceLocator.resolve(AxiosClientFactory).setAuthToken(null)
    history.push('/login')
  }, [removeCookie, history])

  useEffect(() => {
    if (!cookies.accessToken) {
      return
    }
    ServiceLocator.resolve(AxiosClientFactory).setAuthToken(cookies.accessToken)
    fetchProfile().then(profile => {
      setProfile(profile)
    }).catch(() => {
      setCookie('accessToken', null)
      setCookie('refreshToken', null)
    }).finally(() => {
      setLoading(false)
    })
  }, [cookies.accessToken, fetchProfile])

  const context: AuthContextProps = {
    profile: profile,
    expired: false, // set to true when a user token expires
    logout: logout,
  }

  return (
    <AuthContext.Provider value={context}>
      {loading && <CircularProgress />}
      {!loading && children}
    </AuthContext.Provider>
  )
}

export const AuthConsumer = AuthContext.Consumer
