import { useAppSelector } from '../../../Hooks/useAppSelector'
import { CarriersSelectors } from '../StoreAdapter'

export const useCarrierSelector = (entityId: string) => {
  const entityState = useAppSelector((state) =>
    CarriersSelectors.selectById(state, entityId),
  )

  return entityState
}
