import _ from 'lodash'
import { useMemo } from 'react'
import { useAppSelector } from '../../../Hooks/useAppSelector'

export const useServicesSelector = () => {
  const state = useAppSelector((state) => state.services)

  const services = useMemo(() => {
    return _.map(state.entities)
  }, [state])

  return services
}
