import { InputOptionProps } from '../Components/Inputs/Types'

export enum YesNoType {
  Yes = 'Y',
  No = 'N',
}

export const YesNoInputOptions: InputOptionProps[] = [
  {
    label: 'Yes',
    value: 'Y',
  },
  {
    label: 'No',
    value: 'N',
  },
]

export type PartialRecord<K extends keyof any, T> = {
  [P in K]?: T
}
