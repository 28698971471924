import { Divider, Grid, Typography, useTheme } from '@material-ui/core'

import React, { ReactNode } from 'react'
import { useAppStyles } from '../../Hooks/useAppStyles'

interface ContentHeaderProps {
  title: string
  variant?: 'inherit' | 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'subtitle1' | 'subtitle2' | 'body1' | 'body2' | 'caption' | 'button' | 'overline' | 'srOnly'
  action?: ReactNode,
}

export const ContentHeader: React.FC<ContentHeaderProps> = ({
  title,
  variant = 'h6',
  action,
}) => {
  const appStyles = useAppStyles()
  const theme = useTheme()

  return (
    <>
      <Grid container style={{ alignItems: 'flex-end', marginBottom: theme.spacing(1) / 4 }}>
        <Grid item style={{ flex: 1 }}>
          <Typography variant={variant}>{title}</Typography>
        </Grid>
        <Grid item>
          {action}
        </Grid>
      </Grid>
      <Divider className={appStyles.contentItem} />
    </>
  )
}
