import { useCallback, useState } from 'react'
import { useCookies } from 'react-cookie'
import { useMounted } from '../../Hooks/useMounted'
import { useAppDispatch } from '../../Hooks/useAppDispatch'
import { AuthAPI } from '../../Services/API/AuthAPI'
import { ServiceLocator } from '../../Services/ServiceLocator'
import { AuthActions } from '../StoreAdapter'

export const useLogin = () => {
  const dispatch = useAppDispatch()
  const mounted = useMounted()
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [cookies, setCookie] = useCookies(['accessToken'])

  const [processing, setProcessing] = useState(false)
  const [error, setError] = useState(false)
  const [errorMessage, setErrorMessage] = useState<string>(null)

  const login = useCallback(
    async (
      email: string,
      password: string,
      challenge?: string,
    ): Promise<{
      accessToken: string
      refreshToken: string
    }> => {
      setProcessing(true)
      try {
        return ServiceLocator.resolve(AuthAPI)
          .login({ email, password, challenge })
          .then((tokens) => {
            setCookie('accessToken', tokens.accessToken)
            setCookie('refreshToken', tokens.refreshToken)
            dispatch(AuthActions.setTokens(tokens))
            if (mounted.current) {
              setError(false)
              setErrorMessage(null)
              setProcessing(false)
              return tokens
            }
          })
          .catch((err) => {
            dispatch(AuthActions.setTokens(null))
            if (mounted.current) {
              setError(true)
              setErrorMessage(err.message)
              setProcessing(false)
              throw Error(err)
            }
            return null
          })
      } catch (err) {
        if (mounted.current) {
          setError(true)
          setErrorMessage(err.message)
          setProcessing(false)
          throw Error(err)
        }
      }
    },
    [mounted, dispatch, setCookie],
  )

  return {
    processing,
    error,
    errorMessage,
    login,
  }
}
