import React from 'react'

import { BaseSchema } from '../../../Schema/BaseSchema'
import { DateTimeInput } from '../DateTimeInput'

interface SchemaDateTimeInputProps {
  type?: 'date' | 'time' | 'datetime'
  className?: any
  schema: BaseSchema
  label?: String
  field: string
  helperText?: string
  onChange: Function
  disabled?: boolean
  required?: boolean
  interval?: number
}

export const SchemaDateTimeInput: React.FC<SchemaDateTimeInputProps> = ({
  type,
  className,
  schema,
  field,
  label,
  helperText,
  onChange,
  disabled,
  required,
  interval,
}) => {
  return (
    <DateTimeInput
      type={type}
      className={className}
      required={required}
      disabled={disabled}
      error={schema.isPropertyInvalid(field)}
      helperText={schema.isPropertyInvalid(field) ? schema.getPropertyError(field) : helperText }
      value={schema.getValue(field) || null}
      label={label}
      onChange={(date: Date) => onChange(date, field)}
    />
  )
}
