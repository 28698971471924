import React from 'react'
import { BaseSchema } from '../../Schema/BaseSchema'
import { YesNoInputOptions, YesNoType } from '../../Utils/Types'
import { AutocompleteInput } from './AutocompleteInput'

interface YesNoPickerProps {
  inputClassName?: any
  className?: any
  label?: string
  helperText?: string
  onChange: (value: YesNoType) => void
  disabled?: boolean
  required?: boolean
  variant?: 'standard' | 'filled' | 'outlined'
  placeholder?: string
  noOptionsText?: string
  clearOnBlur?: boolean
  value?: YesNoType
  error?: boolean
  showAvatar?: boolean
}

export const YesNoPicker: React.FC<YesNoPickerProps> = ({
  className,
  label,
  helperText,
  onChange,
  disabled,
  required,
  variant,
  placeholder,
  noOptionsText,
  clearOnBlur,
  value,
  error,
  showAvatar = false,
}) => {
  return (
    <AutocompleteInput
      className={className}
      disabled={disabled}
      label={label}
      value={value}
      error={error}
      helperText={helperText }
      onChange={value => onChange(value as YesNoType)}
      required={required}
      variant={variant}
      placeholder={placeholder}
      options={YesNoInputOptions}
      noOptionsText={noOptionsText}
      clearOnBlur={clearOnBlur}
      showAvatar={showAvatar}
    />
  )
}
