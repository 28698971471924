import React, { useEffect, useState } from 'react'
import { Box, Button, CircularProgress, Divider, Grid, Paper, Toolbar, Typography } from '@material-ui/core'

import { AppDrawer, AppDrawerToolbar, AppDrawerContent, AppDrawerActions } from '../../Components/AppDrawer'
import { useAppStyles } from '../../Hooks/useAppStyles'
import { useShipmentSelector } from './Hooks/useShipmentSelector'
import { EditIconButton } from '../../Components/Buttons/EditIconButton'
import { AppPermission } from '../../Hooks/useAppPermissions'
import { ShipmentItemsForShipment } from './Components/ShipmentItemsForShipment'
import { ShipmentDetails } from './Components/ShipmentDetails'
import { DeleteShipmentButton } from './Components/DeleteShipmentButton'
import { AppView } from '../../Components/AppViews/AppView'
import { AppViews } from '../../Components/AppViews'
import { ShipmentUpdater } from './Components/ShipmentUpdater'
import { ShipmentStatusChart } from './Components/ShipmentStatusChart'

interface ShipmentViewDrawerProps {
  schemaId: string
  open: boolean
  onClose: () => void,
}

export const ShipmentViewDrawer: React.FC<ShipmentViewDrawerProps> = ({
  open,
  onClose,
  schemaId,
}) => {
  const [selectedView, setSelectedView] = useState('view')

  const appStyles = useAppStyles()

  const entity = useShipmentSelector(schemaId)

  useEffect(() => {
    if (open) {
      setSelectedView('view')
    }
  }, [open])

  return (
    <>
    <AppDrawer
      open={open}
      onClose={onClose}
      width={'100vw'}
    >
      <AppDrawerToolbar>
        <Typography variant={'h5'} style={{ flex: 1 }}>View Shipment</Typography>
        <DeleteShipmentButton schema={entity} onDeleted={onClose} />
      </AppDrawerToolbar>
      <AppDrawerContent>
        {open && <Box className={appStyles.content}>
          {!entity && <CircularProgress />}
          {entity && (
            <Grid container spacing={1}>
              <Grid item xs={6} md={5} lg={4}>
                <Paper className={appStyles.content} style={{ height: 'calc(100vh - 125px)', overflow: 'auto' }}>
                  <AppViews selected={selectedView} onSelected={setSelectedView}>
                    <AppView key={'view'} value={'view'}>
                      <Toolbar>
                        <ShipmentStatusChart shipmentId={schemaId} />
                        <Box flex={1}><Typography variant={'h5'}>SR Number: #{entity?.srNumber}</Typography></Box><EditIconButton color={'primary'} size={'small'} permissions={AppPermission.ShipmentEdit} onClick={() => setSelectedView('edit')} />
                      </Toolbar>
                      <Divider />
                      <Box className={appStyles.content}>
                      <ShipmentDetails schema={entity} />
                      </Box>
                    </AppView>
                    <AppView key={'edit'} value={'edit'} className={appStyles.content}>
                      <ShipmentUpdater shipment={entity} />
                    </AppView>
                  </AppViews>
                </Paper>
              </Grid>
              <Grid item xs={6} md={7} lg={8}>
                <ShipmentItemsForShipment shipmentId={schemaId} />
              </Grid>
            </Grid>
          )}
        </Box>}
      </AppDrawerContent>
      <AppDrawerActions>
        <Button color={'primary'} onClick={onClose}>Close</Button>
      </AppDrawerActions>
    </AppDrawer>
  </>
  )
}
