import { Grid } from '@material-ui/core'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { SchemaTextInput } from '../../../Components/Inputs/Schema/SchemaTextInput'
import { InputOptionProps } from '../../../Components/Inputs/Types'
import { ShipmentSchema } from '../../../Schema/ShipmentSchema'
import { ConsigneeType } from '../../../Schema/ConsigneeSchema'
import { SchemaDateTimeInput } from '../../../Components/Inputs/Schema/SchemaDateTimeInput'
import { SchemaConsigneePicker } from '../../../Components/Inputs/Schema/SchemaConsigneePicker'
import { useConsigneeSelector } from '../../Consignees/Hooks/useConsigneeSelector'
import { LabPicker } from '../../Labs/Components/LabPicker'
import { SchemaLabContactPicker } from '../../../Components/Inputs/Schema/SchemaLabContactPicker'
import { useLabContactSelector } from '../../LabContacts/Hooks/useLabContactSelector'
import { SchemaChargeCodePicker } from '../../../Components/Inputs/Schema/SchemaChargeCodePicker'
import { SchemaServicePicker } from '../../../Components/Inputs/Schema/SchemaServicePicker'
import { SchemaCarrierPicker } from '../../../Components/Inputs/Schema/SchemaCarrierPicker'
import { useServiceSelector } from '../../Services/Hooks/useServiceSelector'
import { SchemaYesNoPicker } from '../../../Components/Inputs/Schema/SchemaYesNoPicker'
import { YesNoType } from '../../../Utils/Types'

interface ShipmentFormProps {
  schema: ShipmentSchema
  onChange: (schema: ShipmentSchema) => void
}

export const ShipmentForm: React.FC<ShipmentFormProps> = ({
  schema,
  onChange,
}) => {
  const consignee = useConsigneeSelector(schema.consigneeId)
  const labContact = useLabContactSelector(schema.labContactId)
  const service = useServiceSelector(schema.serviceId)

  const [labId, setLabId] = useState<string>(labContact?.labId)

  const courierEnabled = useMemo(() => {
    if (!consignee || !consignee.courierEnabled || !service?.courierEnabled) {
      return false
    }
    return true
  }, [consignee, service])

  const isInternational = useMemo(() => {
    if (consignee && consignee.isInternational()) {
      return true
    }
    return false
  }, [consignee])

  const consigneeDetails = useMemo(() => {
    if (!consignee) {
      return null
    }
    return `${consignee.getAddress()} - Courier: ${consignee.courierEnabled ? 'Y' : 'N'}`
  }, [consignee])

  const serviceDetails = useMemo(() => {
    if (!service) {
      return null
    }
    return `Courier: ${service.courierEnabled ? 'Y' : 'N'} - ${service.notation}`
  }, [service])

  const labContactDetails = useMemo(() => {
    if (!labContact) {
      return null
    }
    return `${labContact.name}/${labContact.email}`
  }, [labContact])

  useEffect(() => {
    if (!courierEnabled) {
      schema.setValue('courier', YesNoType.No)
    } else if (service) {
      schema.setValue('courier', service.courierDefault)
    }
    onChange(schema)
  }, [onChange, courierEnabled, service, schema])

  const handleOnSchemaChanged = useCallback((value: any, field: string) => {
    if (field === 'consigneeId') {
      schema.setValue('serviceId', null)
      schema.setValue(field, value)
    } else if (field === 'labId') {
      setLabId(value)
      schema.setValue('labContactId', null)
    } else {
      schema.setValue(field, value)
    }
    onChange(schema)
  }, [onChange, schema])

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <SchemaTextInput
          label={'SR Number'}
          placeholder={'Enter an SR Number'}
          schema={schema}
          field={'srNumber'}
          required
          onChange={handleOnSchemaChanged}
          autoFocus
        />
      </Grid>
      <Grid item xs={12}>
        <SchemaDateTimeInput
          label={'SR Pickup Date'}
          required
          schema={schema}
          field={'srPickupDate'}
          onChange={handleOnSchemaChanged}
        />
      </Grid>
      <Grid item xs={12}>
      <SchemaDateTimeInput
          type={'date'}
          label={'Shipment Date'}
          required
          schema={schema}
          field={'shipmentDate'}
          onChange={handleOnSchemaChanged}
        />
      </Grid>
      <Grid item xs={12}>
        <SchemaConsigneePicker
          schema={schema}
          onChange={handleOnSchemaChanged}
          enableCreate={true}
          helperText={consigneeDetails}
        />
      </Grid>
      <Grid item xs={12}>
        <SchemaServicePicker
          disabled={!consignee}
          consigneeType={consignee?.type}
          required
          schema={schema}
          onChange={handleOnSchemaChanged}
          helperText={serviceDetails}
        />
      </Grid>
      {isInternational && <Grid item xs={12}>
        <SchemaYesNoPicker
          disabled={!courierEnabled}
          label={'Courier Service (Y/N)'}
          schema={schema}
          field={'courier'}
          onChange={handleOnSchemaChanged}
          helperText={'Default: ' + service?.courierDefault}
          showAvatar={false}
        />
      </Grid>}
      <Grid item xs={12}>
        <LabPicker
          required
          label={'Lab'}
          placeholder={'Select a Lab'}
          value={labId}
          onChange={value => handleOnSchemaChanged(value, 'labId')}
        />
      </Grid>
      <Grid item xs={12}>
        <SchemaLabContactPicker
          required
          disabled={!labId}
          labId={labId}
          schema={schema}
          onChange={handleOnSchemaChanged}
          helperText={labContactDetails}
        />
      </Grid>
      <Grid item xs={12}>
        <SchemaYesNoPicker
          label={'DG?'}
          schema={schema}
          field={'dgFlag'}
          onChange={handleOnSchemaChanged}
        />
      </Grid>
      <Grid item xs={12}>
        <SchemaChargeCodePicker
          required
          schema={schema}
          field={'chargeCodeId'}
          onChange={handleOnSchemaChanged}
        />
      </Grid>
      {schema.id && (
      <>
        <Grid item xs={12}>
          <SchemaCarrierPicker
            schema={schema}
            onChange={handleOnSchemaChanged}
          />
        </Grid>
        <Grid item xs={12}>
          <SchemaTextInput
            label={'Public Tracking Number'}
            placeholder={'Enter a public tracking number.'}
            schema={schema}
            field={'publicTrackingNumber'}
            onChange={handleOnSchemaChanged}
          />
        </Grid>
      </>
      )}
    </Grid>
  )
}
