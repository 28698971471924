/**
 * An adapter and slice definition for the redux store
 */

import {
  createEntityAdapter,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit'
import { AppRootState } from '../../AppState/AppStore'
import createFetchEntityThunk from '../../AppState/createFetchEntityThunk'
import createFetchPaginatedEntitiesThunk from '../../AppState/createFetchPaginatedEntitiesThunk'
import createUpdateManyReducer from '../../AppState/createUpdateManyReducer'
import createUpdateOneReducer from '../../AppState/createUpdateOneRecuder'
import { LabSchema } from '../../Schema/LabSchema'
import { LabsAPI } from '../../Services/API/LabsAPI'

const STORE_NAME = 'labs'

const fetchEntities = createFetchPaginatedEntitiesThunk<LabSchema>(
  STORE_NAME,
  LabsAPI,
)
const fetchEntity = createFetchEntityThunk<LabSchema>(STORE_NAME, LabsAPI)

const EntityAdapter = createEntityAdapter<LabSchema>({
  selectId: (entity) => entity.id,
  sortComparer: (a, b) =>
    a.getSortingValue().localeCompare(b.getSortingValue()),
})

const EntitySlice = createSlice({
  name: 'labs',
  initialState: EntityAdapter.getInitialState(),
  reducers: {
    addOne: EntityAdapter.addOne,
    addMany: EntityAdapter.addMany,
    removeOne: EntityAdapter.removeOne,
    removeAll: EntityAdapter.removeAll,
    updateOne: (state, payload: PayloadAction<LabSchema>) =>
      createUpdateOneReducer<LabSchema>(state, payload),
  },
  extraReducers: (builder) =>
    builder
      .addCase(fetchEntities.fulfilled, (state, action) => {
        createUpdateManyReducer<LabSchema>(state, action)
      })
      .addCase(fetchEntity.fulfilled, (state, action) => {
        createUpdateOneReducer<LabSchema>(state, action)
      }),
})

export const LabsActions = EntitySlice.actions
export const LabsReducer = EntitySlice.reducer
export const LabsSelectors = EntityAdapter.getSelectors(
  (state: AppRootState) => state.labs,
)
export const LabsThunks = {
  fetchLabs: fetchEntities,
  fetchLab: fetchEntity,
}

/*
const adapter = createStoreAdapter<LabSchema>(STORE_NAME, LabsAPI)

export const LabsActions = adapter.actions
export const LabsReducer = adapter.reducer
export const LabsSelectors = adapter.selectors
export const LabsThunks = adapter.thunks
*/
