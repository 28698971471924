import { Singleton } from 'typescript-ioc'
import { APIClientBase } from './APIClientBase'

@Singleton
export class ServiceAPIClient extends APIClientBase {
  /**
   * Instanciate api client
   */
  constructor () {
    super('service')
  }
}
