import { APIBase } from './APIBase'
import { Singleton } from 'typescript-ioc'
import { ShippingAPIClient } from './Clients/ShippingAPIClient'
import { CarrierSchema } from '../../Schema/CarrierSchema'

@Singleton
export class CarriersAPI extends APIBase<CarrierSchema> {
  constructor () {
    super(CarrierSchema, ShippingAPIClient)
    this.resourcePath = 'carriers'
  }
}
