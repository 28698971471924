import { useCallback, useState } from 'react'
import { useMounted } from '../../Hooks/useMounted'
import { AuthAPI } from '../../Services/API/AuthAPI'
import { ServiceLocator } from '../../Services/ServiceLocator'

export const useChangeForgottenPassword = () => {
  const mounted = useMounted()

  const [processing, setProcessing] = useState(false)
  const [error, setError] = useState(false)
  const [errorMessage, setErrorMessage] = useState<string>(null)

  const changePassword = useCallback(
    async (token: string, password: string, challenge?: string) => {
      setProcessing(true)
      return ServiceLocator.resolve(AuthAPI)
        .changeForgottenPassword({ token, password, challenge })
        .then((token) => {
          if (mounted.current) {
            setError(false)
            setErrorMessage(null)
            setProcessing(false)
            return token
          }
        })
        .catch((err) => {
          if (mounted.current) {
            setError(true)
            setErrorMessage(err.message)
            setProcessing(false)
            throw Error(err)
          }
        })
    },
    [mounted],
  )

  return {
    processing,
    error,
    errorMessage,
    changePassword,
  }
}
