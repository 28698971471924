/**
 * An adapter and slice definition for the redux store
 */

import {
  createEntityAdapter,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit'
import { AppRootState } from '../../AppState/AppStore'
import createFetchEntityThunk from '../../AppState/createFetchEntityThunk'
import createFetchPaginatedEntitiesThunk from '../../AppState/createFetchPaginatedEntitiesThunk'
import createUpdateManyReducer from '../../AppState/createUpdateManyReducer'
import createUpdateOneReducer from '../../AppState/createUpdateOneRecuder'
import { UserSchema } from '../../Schema/UserSchema'
import { UsersAPI } from '../../Services/API/UsersAPI'

const STORE_NAME = 'users'

const fetchEntities = createFetchPaginatedEntitiesThunk<UserSchema>(
  STORE_NAME,
  UsersAPI,
)

const fetchEntity = createFetchEntityThunk<UserSchema>(STORE_NAME, UsersAPI)

const UsersAdapter = createEntityAdapter<UserSchema>({
  selectId: (user) => user.id,
  sortComparer: (a, b) =>
    a.getSortingValue().localeCompare(b.getSortingValue()),
})

const UsersSlice = createSlice({
  name: STORE_NAME,
  initialState: UsersAdapter.getInitialState(),
  reducers: {
    addOne: UsersAdapter.addOne,
    addMany: UsersAdapter.addMany,
    removeOne: UsersAdapter.removeOne,
    removeAll: UsersAdapter.removeAll,
    updateOne: (state, payload: PayloadAction<UserSchema>) =>
      createUpdateOneReducer<UserSchema>(state, payload),
  },
  extraReducers: (builder) =>
    builder
      .addCase(fetchEntities.fulfilled, (state, action) => {
        createUpdateManyReducer<UserSchema>(state, action)
      })
      .addCase(fetchEntity.fulfilled, (state, action) => {
        createUpdateOneReducer<UserSchema>(state, action)
      }),
})

export const UsersActions = UsersSlice.actions
export const UsersReducer = UsersSlice.reducer
export const UsersSelectors = UsersAdapter.getSelectors(
  (state: AppRootState) => state.users,
)
export const UsersThunks = {
  fetchUsers: fetchEntities,
  fetchUser: fetchEntity,
}
