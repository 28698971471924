import { Box, Grid, Typography, useTheme } from '@material-ui/core'
import React, { useCallback, useMemo } from 'react'
import { ContentItem } from '../../../Components/Content/ContentItem'
import { SchemaAutocompleteInput } from '../../../Components/Inputs/Schema/SchemaAutocompleteInput'
import { SchemaCountryPicker } from '../../../Components/Inputs/Schema/SchemaCountryPicker'
import { SchemaCountryRegionPicker } from '../../../Components/Inputs/Schema/SchemaCountryRegionPicker'
import { SchemaSwitchInput } from '../../../Components/Inputs/Schema/SchemaSwitchInput'
import { SchemaTextInput } from '../../../Components/Inputs/Schema/SchemaTextInput'
import { InputOptionProps } from '../../../Components/Inputs/Types'
import { useAppStyles } from '../../../Hooks/useAppStyles'
import { ConsigneeType, ConsigneeSchema, CourierDefault } from '../../../Schema/ConsigneeSchema'
import { ConsigneeTypeChip } from './ConsigneeTypeChip'

const CourierDefaultOptions: InputOptionProps[] = [
  {
    label: 'Yes',
    value: CourierDefault.Yes,
  },
  {
    label: 'No',
    value: CourierDefault.No,
  },
]

interface ConsigneeFormProps {
  schema: ConsigneeSchema
  onChange: (schema: ConsigneeSchema) => void
}

export const ConsigneeForm: React.FC<ConsigneeFormProps> = ({
  schema,
  onChange,
}) => {
  const theme = useTheme()
  const appStyles = useAppStyles()

  const handleOnSchemaChanged = useCallback((value: any, field: string) => {
    if (field === 'type') {
      return
    }
    schema.setValue(field, value)
    if (field === 'country') {
      schema.setValue('region', null)
    }
    schema.setValue('type', schema.isInternational() ? ConsigneeType.International : ConsigneeType.Domestic)
    onChange(schema)
  }, [onChange, schema])

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <ContentItem title={'Consignee Type'}>
          <Box display={'flex'} alignContent={'center'} alignItems={'center'}>
            <ConsigneeTypeChip type={schema?.type} />
            <Typography style={{ marginLeft: theme.spacing(2) }}>{schema?.getTypeLabel()}</Typography>
          </Box>
        </ContentItem>
      </Grid>
      <Grid item xs={12}>
        <SchemaTextInput
          className={appStyles.contentItem}
          label={'Name'}
          placeholder={'Enter a name'}
          schema={schema}
          field={'name'}
          required
          onChange={handleOnSchemaChanged}
        />
      </Grid>
      <Grid item xs={12}>
        <SchemaTextInput
          className={appStyles.contentItem}
          required
          label={'Address'}
          placeholder={'Enter an address'}
          schema={schema}
          field={'address'}
          onChange={handleOnSchemaChanged}
        />
      </Grid>
      <Grid item xs={12}>
        <SchemaTextInput
          className={appStyles.contentItem}
          label={'City'}
          placeholder={'Enter a city'}
          schema={schema}
          field={'city'}
          required
          onChange={handleOnSchemaChanged}
        />
      </Grid>
      <Grid item xs={12}>
        <SchemaCountryPicker
          className={appStyles.contentItem}
          label={'Country'}
          placeholder={'Enter a country'}
          schema={schema}
          field={'country'}
          required
          onChange={handleOnSchemaChanged}
        />
      </Grid>
      <Grid item xs={12}>
        <SchemaCountryRegionPicker
          className={appStyles.contentItem}
          countryCode={schema.country}
          schema={schema}
          field={'region'}
          onChange={handleOnSchemaChanged}
        />
      </Grid>
      <Grid item xs={12}>
        <SchemaTextInput
          className={appStyles.contentItem}
          label={'Postal Code'}
          placeholder={'Enter a postal code'}
          schema={schema}
          field={'postalCode'}
          required
          onChange={handleOnSchemaChanged}
        />
      </Grid>
      {schema?.isInternationAddress() && (
        <>
          <Grid item xs={12}>
            <SchemaSwitchInput
              className={appStyles.contentItem}
              label={'Courier Enabled?'}
              schema={schema}
              field={'courierEnabled'}
              onChange={handleOnSchemaChanged}
            />
          </Grid>
        </>
      )}
      <Grid item xs={12}>
        <SchemaSwitchInput
          className={appStyles.contentItem}
          label={'Is Enabled?'}
          schema={schema}
          field={'isEnabled'}
          onChange={handleOnSchemaChanged}
        />
      </Grid>
    </Grid>
  )
}
