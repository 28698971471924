import { useCallback, useState } from 'react'
import { useMounted } from '../../../Hooks/useMounted'
import { ServiceLocator } from '../../../Services/ServiceLocator'
import { ShipmentsAPI } from '../../../Services/API/ShipmentsAPI'

export const useExportShipments = () => {
  const mounted = useMounted()

  const [processing, setProcessing] = useState(false)
  const [error, setError] = useState(false)
  const [errorMessage, setErrorMessage] = useState<string>(null)

  const exportShipments = useCallback(
    async (data: {
      shipmentIds: string[]
    }): Promise<{ documentUrl: string }> => {
      setProcessing(true)
      return ServiceLocator.resolve(ShipmentsAPI)
        .export(data)
        .then((schema) => {
          if (mounted.current) {
            setProcessing(false)
            setError(true)
            setErrorMessage(null)
          }
          return schema
        })
        .catch((err) => {
          if (mounted.current) {
            setProcessing(false)
            setError(true)
            setErrorMessage(err?.response?.data?.errorMessage)
            throw err
          }
          return null
        })
    },
    [mounted],
  )

  return {
    processing,
    error,
    errorMessage,
    exportShipments,
  }
}
