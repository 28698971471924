import React, { useCallback, useEffect } from 'react'

import { AppRouteProps } from '../../Layout/AppRoutes'
import { AppTabProps } from '../../Components/AppTabs/index'
import { AppFeatureContainer } from '../../Components/AppFeatureContainer'
import { useParams } from 'react-router-dom'
import { Shipments } from '../Shippments'
import { ShipmentItems } from '../ShipmentItems'
import { useAppDispatch } from '../../Hooks/useAppDispatch'
import { ConsigneeThunks } from '../Consignees/StoreAdapter'
import { ShipmentThunks } from '../Shippments/StoreAdapter'
import { ShipmentItemsThunks } from '../ShipmentItems/StoreAdapter'

const routePrefix = '/shipment-management'

const tabs: AppTabProps[] = [
  { value: 'shipments', route: `${routePrefix}/shipments`, label: 'Shipments' },
  { value: 'items', route: `${routePrefix}/items`, label: 'Shipment Items' },
]

interface ShipmentManagementProps {
  routes?: AppRouteProps[],
}

export const ShipmentManagement: React.FC<ShipmentManagementProps> = ({
  routes,
}) => {
  const dispatch = useAppDispatch()
  const { section } = useParams<any>()

  const loadShipmentData = useCallback(() => {
    dispatch(ConsigneeThunks.fetchConsignees({}))
    dispatch(ShipmentThunks.fetchShipments({}))
    dispatch(ShipmentItemsThunks.fetchShipmentItems({}))
  }, [dispatch])

  useEffect(() => {
    loadShipmentData()
    const timer = setInterval(loadShipmentData, 60000)
    return () => {
      clearInterval(timer)
    }
  }, [loadShipmentData])

  return (
    <AppFeatureContainer tabs={tabs} selected={section}>
      <Shipments />
      <ShipmentItems />
    </AppFeatureContainer>
  )
}
