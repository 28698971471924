import { Grid } from '@material-ui/core'
import React, { useCallback } from 'react'
import { SchemaSwitchInput } from '../../../Components/Inputs/Schema/SchemaSwitchInput'
import { SchemaTextInput } from '../../../Components/Inputs/Schema/SchemaTextInput'
import { ChargeCodeSchema } from '../../../Schema/ChargeCodeSchema'

interface ChargeCodeFormProps {
  schema: ChargeCodeSchema
  onChange: (schema: ChargeCodeSchema) => void
}

export const ChargeCodeForm: React.FC<ChargeCodeFormProps> = ({
  schema,
  onChange,
}) => {
  const handleOnSchemaChanged = useCallback((value: any, field: string) => {
    schema.setValue(field, value)
    onChange(schema)
  }, [onChange, schema])

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <SchemaTextInput
          label={'Code'}
          placeholder={'Enter a charge code'}
          schema={schema}
          field={'code'}
          required
          onChange={handleOnSchemaChanged}
        />
      </Grid>
      <Grid item xs={12}>
        <SchemaTextInput
          label={'Description'}
          placeholder={'Enter a charge code description'}
          schema={schema}
          field={'description'}
          onChange={handleOnSchemaChanged}
        />
      </Grid>
      <Grid item xs={12}>
        <SchemaSwitchInput
          label={'Is Enabled?'}
          schema={schema}
          field={'isEnabled'}
          onChange={handleOnSchemaChanged}
        />
      </Grid>
    </Grid>
  )
}
