import { Type } from 'class-transformer'
import {
  IsBoolean,
  IsEnum,
  IsInt,
  IsNotEmpty,
  IsOptional,
  IsString,
  IsUUID,
  Min,
} from 'class-validator'
import _ from 'lodash'
import { BaseSchema } from './BaseSchema'
import { ConsigneeType, CourierDefault } from './ConsigneeSchema'

export class ServiceSchema extends BaseSchema {
  /**
   * User ID
   */
  @IsOptional()
  @IsUUID()
  id: string

  /**
   * Name
   */
  @Type(() => String)
  @IsNotEmpty()
  @IsString()
  name: string = null

  /**
   * Notation
   */
  @Type(() => String)
  @IsNotEmpty()
  @IsString()
  notation: string = null

  /**
   * Minimum number of transit days.
   */
  @Type(() => Number)
  @IsInt()
  @Min(1)
  minTransitDays: number = 1

  /**
   * Maximum number of transit days.
   */
  @Type(() => Number)
  @IsInt()
  @Min(1)
  maxTransitDays: number = 1

  /**
   * Consignee Type
   */
  @IsNotEmpty()
  @IsEnum(ConsigneeType)
  type: ConsigneeType

  /**
   * Is Enabled?
   */
  @Type(() => Boolean)
  @IsNotEmpty()
  @IsBoolean()
  courierEnabled: boolean = false

  /**
   *
   */
  @IsEnum(CourierDefault)
  @IsString()
  courierDefault: CourierDefault = CourierDefault.No

  /**
   * Is Enabled?
   */
  @Type(() => Boolean)
  @IsNotEmpty()
  @IsBoolean()
  isEnabled: boolean = true

  /**
   * Date created
   */
  @Type(() => Date)
  created: Date

  /**
   * Updated by
   */
  createdBy: string

  /**
   * Date updated
   */
  @Type(() => Date)
  updated: Date

  /**
   * Updated by
   */
  updatedBy: string

  /**
   * Date deleted
   */
  @Type(() => Date)
  deleted: Date

  /**
   * Deleted by
   */
  deletedBy: string

  /**
   * Construct
   * @param data
   */
  constructor (data?: Partial<ServiceSchema>) {
    super()
    _.assign(this, data)
  }

  /**
   * Returns a string for sorting
   * @returns string
   */
  getSortingValue () {
    return `${this.name}`
  }
}
