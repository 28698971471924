import React from 'react'
import { EntityTag } from '../../../Components/EntityTag'
import { useCarrierSelector } from '../Hooks/useCarrierSelector'

interface CarrierTagProps {
  carrierId: string
  avatarSize?: 'small' | 'medium' | 'large'
  showAvatar?: boolean
  secondaryText?: string | React.ReactNode
}

export const CarrierTag: React.FC<CarrierTagProps> = ({
  carrierId,
  avatarSize,
  showAvatar = false,
  secondaryText,
}) => {
  const entity = useCarrierSelector(carrierId)

  return (
    <EntityTag
      primary={entity?.name}
      secondary={secondaryText}
      avatarSize={avatarSize}
      showAvatar={showAvatar}
    />
  )
}
