import packageJson from '../package.json'

const Configs = {
  appVersion: packageJson.version,
  recaptchaClientKey: process.env.REACT_APP_RECAPTCHA_CLIENT_KEY,
  apis: {
    auth: process.env.REACT_APP_API_AUTH,
    users: process.env.REACT_APP_API_USERS,
    shipping: process.env.REACT_APP_API_SHIPPING,
    consignee: process.env.REACT_APP_API_CONSIGNEE,
    service: process.env.REACT_APP_API_SERVICE,
  },
  asTrackingUrl: process.env.REACT_APP_AS_TRACKING_URL,
}

export default Configs
