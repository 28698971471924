import React, { useCallback, useState } from 'react'
import { AppDrawer, AppDrawerToolbar, AppDrawerContent, AppDrawerActions } from '../../Components/AppDrawer'
import { v4 as uuid } from 'uuid'
import { Box, Button, Paper, Typography } from '@material-ui/core'
import { useAppStyles } from '../../Hooks/useAppStyles'
import { useAddCarrier } from './Hooks/useAddCarrier'
import { CarrierSchema } from '../../Schema/CarrierSchema'
import { CarrierForm } from './Components/CarrierForm'
import { useSnackbarMessages } from '../../Components/AppSnackbar/Hooks/useSnackbarMessages'

interface CarrierAddDrawerProps {
  open: boolean
  onClose: () => void,
  defaultData?: Partial<CarrierSchema>,
}

export const CarrierAddDrawer: React.FC<CarrierAddDrawerProps> = ({
  open,
  onClose,
  defaultData,
}) => {
  const { addSuccessMessage, addErrorMessage } = useSnackbarMessages()
  const appStyles = useAppStyles()
  const { addCarrier, processing } = useAddCarrier()

  const [hash, setHash] = useState('')
  const [schema, setSchema] = React.useState(new CarrierSchema(defaultData))

  const handleOnSchemaChanged = useCallback((schema: CarrierSchema) => {
    setHash(uuid())
  }, [])

  const handleOnSave = useCallback(() => {
    if (schema.validate()) {
      addCarrier(schema).then(() => {
        addSuccessMessage('Carrier added successfully.')
        setSchema(new CarrierSchema())
        onClose()
      }).catch(() => {
        addErrorMessage('Failed to add the Carrier.')
      })
    }
    setHash(uuid())
  }, [schema, addCarrier, onClose, addSuccessMessage, addErrorMessage])

  return (
    <AppDrawer
      width={400}
      open={open}
      onClose={onClose}
    >
      <AppDrawerToolbar>
        <Typography variant={'h5'}>Add Carrier</Typography>
      </AppDrawerToolbar>
      <AppDrawerContent>
        <Box className={appStyles.content}>
          <Paper className={appStyles.content}>
            <CarrierForm schema={schema} onChange={handleOnSchemaChanged} />
          </Paper>
        </Box>
      </AppDrawerContent>
      <AppDrawerActions>
        <Button disabled={processing} color={'primary'} onClick={handleOnSave}>Save</Button>
      </AppDrawerActions>
    </AppDrawer>
  )
}
