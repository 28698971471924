import React, { useCallback, useEffect } from 'react'

import { AppRouteProps } from '../../Layout/AppRoutes'
import { AppTabProps } from '../../Components/AppTabs/index'
import { AppFeatureContainer } from '../../Components/AppFeatureContainer'
import { useAppDispatch } from '../../Hooks/useAppDispatch'
import { UsersThunks } from '../Users/StoreAdapter'

const tabs: AppTabProps[] = [
  { value: 'users', route: '/user-management/users', label: 'Users' },
]

interface UserManagementProps {
  routes?: AppRouteProps[],
}

export const UserManagement: React.FC<UserManagementProps> = ({
  routes,
}) => {
  const dispatch = useAppDispatch()

  const loadData = useCallback(() => {
    dispatch(UsersThunks.fetchUsers({}))
  }, [dispatch])

  useEffect(() => {
    loadData()
  }, [loadData])

  return (
    <AppFeatureContainer routes={routes} tabs={tabs} selected={'users'} />
  )
}
