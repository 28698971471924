import { APIBase } from './APIBase'
import { Singleton } from 'typescript-ioc'
import { ConsigneeSchema } from '../../Schema/ConsigneeSchema'
import { ConsigneeAPIClient } from './Clients/ConsigneeAPIClient'

@Singleton
export class ConsigneesAPI extends APIBase<ConsigneeSchema> {
  constructor () {
    super(ConsigneeSchema, ConsigneeAPIClient)
    this.resourcePath = 'consignees'
  }
}
