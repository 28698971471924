import { setupAuthAPIMock } from './AuthAPIMocks'
import { setupShippingAPIMock } from './ShippingAPIMock'
import { setupUsersAPIMock } from './UsersMocks'

const mocks = {
  // auth: setupAuthAPIMock,
  // shipping: setupShippingAPIMock,
  // users: setupUsersAPIMock,
}

export default mocks
