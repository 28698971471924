import React from 'react'
import { EntityTag } from '../../../Components/EntityTag'
import { useContainerSelector } from '../Hooks/useContainerSelector'

interface ContainerTagProps {
  containerId: string
  avatarSize?: 'small' | 'medium' | 'large'
  showAvatar?: boolean
}

export const ContainerTag: React.FC<ContainerTagProps> = ({
  containerId,
  avatarSize,
  showAvatar = false,
}) => {
  const entity = useContainerSelector(containerId)

  return (
    <EntityTag
      primary={entity?.name}
      secondary={entity?.dimensionalLabel}
      avatarSize={avatarSize}
      showAvatar={showAvatar}
    />
  )
}
