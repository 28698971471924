import React, { useCallback } from 'react'
import { useSnackbarMessages } from '../../../Components/AppSnackbar/Hooks/useSnackbarMessages'
import { DeleteIconButton } from '../../../Components/Buttons/DeleteIconButton'
import { AppPermission } from '../../../Hooks/useAppPermissions'
import { ShipmentSchema } from '../../../Schema/ShipmentSchema'
import { useDeleteShipment } from '../Hooks/useDeleteShipment'

interface DeleteShipmentButtonProps {
  schema: ShipmentSchema
  onDeleted?: () => void
}

export const DeleteShipmentButton: React.FC<DeleteShipmentButtonProps> = ({
  schema,
  onDeleted = () => {},
}) => {
  const { addSuccessMessage, addErrorMessage } = useSnackbarMessages()
  const { deleteShipment, processing, errorMessage } = useDeleteShipment()

  const handleOnDelete = useCallback(() => {
    deleteShipment(schema.id).then(() => {
      addSuccessMessage('The carrier deleted successfully.')
      onDeleted()
    }).catch(() => {
      addErrorMessage(errorMessage)
    })
  }, [schema?.id, addSuccessMessage, addErrorMessage, deleteShipment, errorMessage, onDeleted])

  return (
    <DeleteIconButton permissions={[AppPermission.ShipmentDelete]} onDelete={handleOnDelete} disabled={processing} />
  )
}
