import React from 'react'

import { AppRouteProps } from '../../Layout/AppRoutes'
import { AppTabProps } from '../../Components/AppTabs/index'
import { AppFeatureContainer } from '../../Components/AppFeatureContainer'

const tabs: AppTabProps[] = [
  { value: 'consignees', route: '/consignee-management/consignees', label: 'Consignees' },
]

interface ConsigneeManagementProps {
  routes?: AppRouteProps[],
}

export const ConsigneeManagement: React.FC<ConsigneeManagementProps> = ({
  routes,
}) => {
  return (
    <AppFeatureContainer routes={routes} tabs={tabs} selected={'consignees'} />
  )
}
