import { Chip } from '@material-ui/core'
import React from 'react'
import { ConsigneeType } from '../../../Schema/ConsigneeSchema'

interface ConsigneeTypeChipProps {
  type: ConsigneeType
}

export const ConsigneeTypeChip: React.FC<ConsigneeTypeChipProps> = ({
  type,
}) => {
  return <Chip label={type} />
}
