import countryData from 'country-region-data'
import _ from 'lodash'

export const useCountryList = () => {
  const countryList = _.memoize(() => {
    return _.map(countryData, (country) => {
      return {
        value: country.countryShortCode,
        label: country.countryName,
      }
    })
  })

  return countryList()
}
