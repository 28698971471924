import { Box, Button, Paper, useTheme } from '@material-ui/core'
import _ from 'lodash'
import React, { useEffect, useMemo, useState } from 'react'
import { useHistory, useParams } from 'react-router'
import { useAppTabs } from '../../Components/AppTabs/useAppTabs'
import { AppToolbar } from '../../Components/AppToolbar'
import { AppPermission, useAppPermissions } from '../../Hooks/useAppPermissions'
import { useAppStyles } from '../../Hooks/useAppStyles'
import { LabsDataGrid } from './Components/LabsDataGrid'
import { useLabsSelector } from './Hooks/useLabsSelector'
import { LabAddDrawer } from './LabAddDrawer'
import { LabEditDrawer } from './LabEditDrawer'
import { LabViewDrawer } from './LabViewDrawer'

export const Labs: React.FC = () => {
  const theme = useTheme()
  const appStyles = useAppStyles()
  const appTabs = useAppTabs()
  const history = useHistory()
  const { checkPermissions } = useAppPermissions()

  const { labId: paramLabId, action } = useParams<{labId: string, action: string}>()

  const labsState = useLabsSelector()

  const labs = useMemo(() => {
    return _.map(labsState)
  }, [labsState])

  const [openAdd, setOpenAdd] = useState(false)
  const [viewLabId, setViewLabId] = useState<string>(null)

  useEffect(() => {
    setViewLabId(paramLabId)
  }, [paramLabId])

  useEffect(() => {
    if (appTabs) {
      appTabs.select('labs')
    }
  }, [appTabs])

  return (
    <Box className={appStyles.tabContent}>
      <AppToolbar>
        <Box flex={1} />
        {checkPermissions(AppPermission.LabAdd) && <Button variant={'contained'} color={'primary'} onClick={() => setOpenAdd(true)}>Add Lab</Button>}
      </AppToolbar>
      <Paper style={{ height: 'calc(100vh - 200px)', padding: theme.spacing(1) }}>
        <LabsDataGrid labs={labs} />
      </Paper>
      <LabAddDrawer
        open={openAdd}
        onClose={() => setOpenAdd(false)}
      />
      <LabViewDrawer
        open={Boolean(viewLabId) && !action}
        labId={viewLabId}
        onClose={() => history.push('/lab-management/labs')}
      />

      <LabEditDrawer
        open={Boolean(viewLabId) && action === 'edit'}
        labId={viewLabId}
        onClose={() => history.push(`/lab-management/labs/${viewLabId}`)}
      />
    </Box>
  )
}
