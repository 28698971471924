import _ from 'lodash'
import React, { useMemo, useState } from 'react'
import { AutocompleteInput } from '../../../Components/Inputs/AutocompleteInput'
import { InputOptionProps } from '../../../Components/Inputs/Types'
import { AppPermission, useAppPermissions } from '../../../Hooks/useAppPermissions'
import { ConsigneeSchema, ConsigneeType } from '../../../Schema/ConsigneeSchema'
import { useConsigneesSelector } from '../Hooks/useConsigneesSelector'
import { ConsigneeAddDrawer } from '../ConsigneeAddDrawer'

interface ConsigneePickerProps {
  consigneeType?: ConsigneeType
  value?: string
  className?: any
  label?: string
  helperText?: string
  onChange: (value: string) => void
  disabled?: boolean
  required?: boolean
  variant?: 'standard' | 'filled' | 'outlined'
  placeholder?: string
  freeSolo?: boolean
  noOptionsText?: string
  clearOnBlur?: boolean
  onCreateNew?: (text: string) => void
  enableCreate?: boolean
  error?: boolean
  showAvatar?: boolean
}

export const ConsigneePicker: React.FC<ConsigneePickerProps> = ({
  consigneeType,
  className,
  value,
  label,
  helperText,
  onChange,
  disabled,
  required,
  variant,
  placeholder,
  freeSolo,
  noOptionsText,
  clearOnBlur,
  enableCreate = true,
  error,
  showAvatar = false,
}) => {
  const { checkPermissions } = useAppPermissions()

  const [openAdd, setOpenAdd] = useState(false)
  const [text, setText] = useState<string>('')

  const entities = useConsigneesSelector()

  const filteredEntities = useMemo(() => {
    const enabled = _.filter(entities, e => e.isEnabled)
    if (!consigneeType) {
      return enabled
    }
    return _.filter(enabled, c => c.type === consigneeType)
  }, [entities, consigneeType])

  const sortedEntities = useMemo(() => {
    return _.sortBy(filteredEntities, ['rank', 'name'])
  }, [filteredEntities])

  const options: InputOptionProps[] = useMemo(() => {
    return _.map(sortedEntities, c => c.getInputOption())
  }, [sortedEntities])

  const handleOnCreate = (name?: string) => {
    setOpenAdd(true)
    setText(
      name,
    )
  }

  const handleOnCloseAdd = (schema?: ConsigneeSchema) => {
    if (schema) {
      onChange(schema.id)
    }
    setOpenAdd(false)
  }
  return (
    <>
    <AutocompleteInput
      value={value}
      className={className}
      label={label}
      helperText={helperText}
      onChange={value => onChange(value as string)}
      disabled={disabled}
      required={required}
      variant={variant}
      placeholder={placeholder}
      freeSolo={freeSolo}
      options={options}
      noOptionsText={noOptionsText}
      clearOnBlur={clearOnBlur}
      onCreateNew={handleOnCreate}
      enableCreate={checkPermissions([AppPermission.ConsigneeAdd]) && enableCreate}
      error={error}
      showAvatar={showAvatar}
    />
    {openAdd && <ConsigneeAddDrawer
      open={openAdd}
      onClose={handleOnCloseAdd}
      defaultData={{
        name: text,
        type: consigneeType,
      }}
    />}
    </>
  )
}
