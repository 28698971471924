import palette from './palette'

const styles = {
  fontFamily: 'Roboto, sans-serif',
  h1: {
    // color: palette.text.primary,
    fontWeight: 500,
    fontSize: '2.5rem',
    // letterSpacing: "-0.24px",
    lineHeight: '2.75rem',
  },
  h2: {
    // color: palette.text.primary,
    fontWeight: 500,
    fontSize: '2rem',
    letterSpacing: '-0.24px',
    lineHeight: '2.25rem',
  },
  h3: {
    // color: palette.text.primary,
    fontWeight: 500,
    fontSize: '1.7rem',
    letterSpacing: '-0.06px',
    lineHeight: '2rem',
  },
  h4: {
    // color: palette.text.primary,
    fontWeight: 500,
    fontSize: '1.4rem',
    letterSpacing: '-0.06px',
    lineHeight: '1.75rem',
  },
  h5: {
    // color: palette.text.primary,
    fontWeight: 500,
    fontSize: '1.15rem',
    letterSpacing: '-0.05px',
    lineHeight: '20px',
  },
  h6: {
    // color: palette.text.primary,
    fontWeight: 500,
    fontSize: '0.9rem',
    letterSpacing: '-0.05px',
    lineHeight: '1.25rem',
  },
  subtitle1: {
    // color: palette.text.primary,
    fontSize: '0.9rem',
    letterSpacing: '-0.05px',
    lineHeight: '25px',
  },
  subtitle2: {
    color: palette.text.secondary,
    fontWeight: 400,
    fontSize: '0.75rem',
    letterSpacing: '-0.05px',
    lineHeight: '21px',
  },
  body1: {
    // color: palette.text.primary,
    fontSize: '0.9rem',
    letterSpacing: '-0.05px',
    lineHeight: '21px',
  },
  body2: {
    // color: palette.text.secondary,
    fontSize: '0.75rem',
    letterSpacing: '-0.04px',
    lineHeight: '18px',
  },
  button: {
    // color: palette.text.primary,
    fontSize: '0.85rem',
  },
  caption: {
    // color: palette.text.secondary,
    fontSize: '0.685rem',
    letterSpacing: '0.33px',
    lineHeight: '13px',
  },
  overline: {
    // color: palette.text.secondary,
    fontSize: '0.6875rem',
    fontWeight: 500,
    letterSpacing: '0.33px',
    lineHeight: '13px',
    // textTransform: "uppercase"
  },
}

export default styles
