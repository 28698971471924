import React, { useMemo } from 'react'
import Circle from '@material-ui/icons/Lens'
import { Box, useTheme } from '@material-ui/core'
import { GridValueFormatterParams } from '@material-ui/data-grid'

interface DataGridBooleanProps {
  params: GridValueFormatterParams
}

export const DataGridBoolean: React.FC<DataGridBooleanProps> = ({
  params,
}) => {
  const theme = useTheme()

  const color = useMemo(() => {
    const value = params?.value
    if (value) {
      return theme.palette.success.main
    } else {
      return theme.palette.grey[200]
    }
  }, [params, theme])

  return <Box display={'flex'} alignContent={'center'}><Circle style={{ color: color }} /></Box>
}
