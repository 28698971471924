import React, { useMemo } from 'react'

import { OpenTrackingWebsiteButton } from '../../../Components/Buttons/OpenTrackingWebsiteButton'
import Configs from '../../../Configs'
import { ShipmentSchema } from '../../../Schema/ShipmentSchema'

interface ShipmentExternalTrackingButtonProps {
  schema: ShipmentSchema
  buttonSize?: 'small' | 'medium'
}

export const ShipmentExternalTrackingButton: React.FC<ShipmentExternalTrackingButtonProps> = ({
  schema,
  buttonSize,
}) => {
  const trackingUrl = useMemo(() => {
    const asTrackingUrl = Configs.asTrackingUrl
    if (!asTrackingUrl || !schema?.srNumber) {
      return null
    }
    return asTrackingUrl?.replace('{{TrackingNumber}}', schema.srNumber)
  }, [schema?.srNumber])

  return (
    <OpenTrackingWebsiteButton trackingUrl={trackingUrl} buttonSize={buttonSize} />
  )
}
