import { Grid, Typography } from '@material-ui/core'
import React from 'react'
import { ShipmentSchema } from '../../../Schema/ShipmentSchema'
import { ConsigneeType } from '../../../Schema/ConsigneeSchema'
import { useConsigneeSelector } from '../../Consignees/Hooks/useConsigneeSelector'
import { useLabContactSelector } from '../../LabContacts/Hooks/useLabContactSelector'
import { ContentItem } from '../../../Components/Content/ContentItem'
import { formatLocalDate, formatLocalDateTime } from '../../../Utils/DateFunctions'
import { ConsigneeTag } from '../../Consignees/Components/ConsigneeTag'
import { ServiceTag } from '../../Services/Components/ServiceTag'
import { LabTag } from '../../Labs/Components/LabTag'
import { LabContactTag } from '../../LabContacts/Components/LabContactTag'
import { CarrierTag } from '../../Carriers/Components/CarrierTag'
import { ChargeCodeTag } from '../../ChargeCodes/Components/ChargeCodeTag'
import { ShipmentDelays } from './ShipmentDelays'
import { ShipmentDelaysAdd } from './ShipmentDelaysAdd'
import { ShipmentSrNumber } from './ShipmentItemSrNumber'

interface ShipmentDetailsProps {
  schema: ShipmentSchema
}

export const ShipmentDetails: React.FC<ShipmentDetailsProps> = ({
  schema,
}) => {
  const consignee = useConsigneeSelector(schema.consigneeId)
  const labContact = useLabContactSelector(schema.labContactId)

  return (
    <Grid container>
      <Grid item lg={4} md={6} xs={12}>
        <ContentItem title={'SR Number'}>
          <Typography>
            <ShipmentSrNumber shipmentId={schema.id} />
          </Typography>
        </ContentItem>
      </Grid>
      <Grid item lg={4} md={6} xs={12}>
        <ContentItem title={'SR Pickup Date'}>
          <Typography>{formatLocalDateTime(schema.srPickupDate)}</Typography>
        </ContentItem>
      </Grid>
      <Grid item lg={4} md={6} xs={12}>
        <ContentItem title={'Shipment Date'}>
          <Typography>{formatLocalDate(schema.shipmentDate)}</Typography>
        </ContentItem>
      </Grid>
      <Grid item lg={12} md={12} xs={12}>
        <ContentItem title={'Consignee'}>
          <ConsigneeTag consigneeId={schema.consigneeId}/>
        </ContentItem>
      </Grid>
      <Grid item lg={6} md={6} xs={12}>
        <ContentItem title={'Service'}>
          <ServiceTag serviceId={schema.serviceId} />
        </ContentItem>
      </Grid>
      {consignee?.type === ConsigneeType.International &&
      <Grid item lg={6} md={6} xs={12}>
        <ContentItem title={'Courier?'}>
          <Typography>{schema.courier}</Typography>
        </ContentItem>
      </Grid>}
      <Grid item lg={12} md={12} xs={12}></Grid>
      <Grid item lg={6} md={6} xs={12}>
        <ContentItem title={'Lab'}>
          <LabTag labId={labContact?.labId} />
        </ContentItem>
      </Grid>
      <Grid item lg={6} md={6} xs={12}>
        <ContentItem title={'Lab Contact'}>
          <LabContactTag labContactId={schema?.labContactId} />
        </ContentItem>
      </Grid>
      <Grid item lg={4} md={6} xs={12}>
        <ContentItem title={'DG?'}>
          <Typography>{schema.dgFlag}</Typography>
        </ContentItem>
      </Grid>
      <Grid item lg={4} md={6} xs={12}>
        <ContentItem title={'Charge Code'}>
          <ChargeCodeTag chargeCodeId={schema.chargeCodeId} />
        </ContentItem>
      </Grid>
      <Grid item lg={4} md={6} xs={12}>
        <ContentItem title={'Carrier'}>
          <CarrierTag carrierId={schema.carrierId} />
        </ContentItem>
      </Grid>
      <Grid item lg={4} md={6} xs={12}>
        <ContentItem title={'Carrier ETA'}>
          <Typography>{formatLocalDate(schema.carrierEta)}</Typography>
        </ContentItem>
      </Grid>
      <Grid item md={6} xs={12}>
        <ContentItem title={'Public Tracking Number'}>
          <Typography>{schema.publicTrackingNumber}</Typography>
        </ContentItem>
      </Grid>
      <Grid item lg={12} md={12} xs={12}>
        <ContentItem title={'Delays'}>
          <ShipmentDelaysAdd shipment={schema} />
          <ShipmentDelays shipment={schema} />
        </ContentItem>
      </Grid>
    </Grid>
  )
}
