import { APIBase } from './APIBase'
import { Singleton } from 'typescript-ioc'
import { ServiceAPIClient } from './Clients/ServiceAPIClient'
import { ChargeCodeSchema } from '../../Schema/ChargeCodeSchema'

@Singleton
export class ChargeCodesAPI extends APIBase<ChargeCodeSchema> {
  constructor () {
    super(ChargeCodeSchema, ServiceAPIClient)
    this.resourcePath = 'charge-codes'
  }
}
