import { Box, Button, Paper, useTheme } from '@material-ui/core'
import _ from 'lodash'
import React, { useEffect, useMemo, useState } from 'react'
import { useHistory, useParams } from 'react-router'
import { useAppTabs } from '../../Components/AppTabs/useAppTabs'
import { AppToolbar } from '../../Components/AppToolbar'
import { useAppSelector } from '../../Hooks/useAppSelector'
import { useAppStyles } from '../../Hooks/useAppStyles'
import { UsersDataGrid } from './Components/UsersDataGrid'
import { UserAddDrawer } from './UserAddDrawer'
import { UserEditDrawer } from './UserEditDrawer'

export const Users: React.FC = () => {
  const theme = useTheme()
  const appStyles = useAppStyles()
  const appTabs = useAppTabs()
  const history = useHistory()

  const { userId: paramUserId } = useParams<{userId: string}>()

  const usersState = useAppSelector(state => state.users)

  const users = useMemo(() => {
    return _.map(usersState.entities)
  }, [usersState])

  const [openAdd, setOpenAdd] = useState(false)
  const [editUserId, setEditUserId] = useState<string>(null)

  useEffect(() => {
    setEditUserId(paramUserId)
  }, [paramUserId])

  if (appTabs) {
    appTabs.select('users')
  }

  return (
    <Box className={appStyles.tabContent}>
      <AppToolbar>
        <Box flex={1} />
        <Button variant={'contained'} color={'primary'} onClick={() => setOpenAdd(true)}>Add User</Button>
      </AppToolbar>
      <Paper style={{ height: 'calc(100vh - 200px)', padding: theme.spacing(1) }}>
        <UsersDataGrid users={users} />
      </Paper>
      <UserAddDrawer
        open={openAdd}
        onClose={() => setOpenAdd(false)}
      />
      <UserEditDrawer
        open={Boolean(editUserId)}
        userId={editUserId}
        onClose={() => history.push('/user-management/users')}
      />
    </Box>
  )
}
