import { AxiosInstance, AxiosRequestConfig } from 'axios'
import { ServiceLocator } from '../../ServiceLocator'
import { AxiosClientFactory } from '../../AxiosClientFactory'

export class APIClientBase {
  /**
   * Version
   */
  private version: string = 'v1'

  /**
   * API Client instance
   */
  private api: AxiosInstance

  /**
   * Service name
   */
  private apiName: string

  constructor (apiName: string) {
    this.apiName = apiName
    this.createClient()
  }

  /**
   * Instanciate Axios client
   */
  private createClient () {
    this.api = ServiceLocator.resolve(AxiosClientFactory).createClient(
      this.apiName,
    )
  }

  /**
   * Issue POST request
   * @param url
   * @param data
   * @param config
   */
  post<T = any> (url: string, data?: any, config?: AxiosRequestConfig) {
    return this.api.post<T>(url, data, config)
  }

  /**
   * Issue GET request
   * @param url
   * @param config
   */
  get<T = any> (url: string, config?: AxiosRequestConfig) {
    return this.api.get<T>(url, config)
  }

  /**
   * Issue PUT request
   * @param url
   * @param data
   * @param config
   */
  put<T = any> (url: string, data?: any, config?: AxiosRequestConfig) {
    return this.api.put<T>(url, data, config)
  }

  /**
   * Issue DELETE request
   * @param url
   * @param config
   */
  delete<T = any> (url: string, config?: AxiosRequestConfig) {
    return this.api.delete<T>(url, config)
  }

  /**
   * Return API URL prefix for company specific APIs
   */
  public getVersion (): string {
    return this.version
  }
}
