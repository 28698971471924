import { Box, Button, createStyles, Grid, makeStyles, Typography } from '@material-ui/core'
import { IsEmail, IsNotEmpty } from 'class-validator'
import {
  useGoogleReCaptcha,
} from 'react-google-recaptcha-v3'
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { BaseSchema } from '../Schema/BaseSchema'
import { useResetPassword } from './Hooks/useResetPassword'
import { useSnackbarMessages } from '../Components/AppSnackbar/Hooks/useSnackbarMessages'
import { SchemaTextInput } from '../Components/Inputs/Schema/SchemaTextInput'

class RequestObj extends BaseSchema {
  @IsNotEmpty({ message: 'Email is required.' })
  @IsEmail({}, { message: 'Valid Emails is required.' })
  email: string
}

export const ForgotPassword: React.FC = () => {
  const history = useHistory()
  const styles = useStyles()
  const { addSuccessMessage, addErrorMessage } = useSnackbarMessages()

  const { executeRecaptcha } = useGoogleReCaptcha()
  const { resetPassword, processing } = useResetPassword()

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [hash, setHash] = useState('')
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [obj, setObj] = useState(new RequestObj())

  const handleOnChange = (value: string, field: string) => {
    obj.setValue(field, value)
    setHash(obj.generateHash())
  }

  const handleOnReset = (e: React.FormEvent) => {
    e.preventDefault()
    if (!obj || !obj.validate()) {
      setHash(obj?.generateHash())
      return
    }
    if (executeRecaptcha) {
      executeRecaptcha('changePassword').then(token => {
        resetPassword(obj.email, token).then(() => {
          addSuccessMessage('Password reset request received. Please check your email for the instructions.')
          history.push('/login')
        }).catch(() => {
          addErrorMessage('Failed to submit the password reset request.')
        })
      }).catch(err => {
        console.log('captcha error', err)
      })
    }
    setHash(obj?.generateHash())
  }

  return (
    <Box className={styles.container}>
      <form onSubmit={handleOnReset}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography variant="body1">
            Please enter the email bellow that you used to create your account. We
            will send you instructions on how to reset your password.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <SchemaTextInput
              disabled={processing}
              label={'Email'}
              schema={obj}
              field={'email'}
              onChange={handleOnChange}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              type={'submit'}
              disabled={processing}
              color={'primary'}
              fullWidth
              variant={'contained'}
            >
        Request Password Reset
            </Button>
          </Grid>
        </Grid>
      </form>

      <Box marginTop={3} display={'flex'} alignItems={'center'}>
        <Button
          disabled={processing}
          onClick={() => history.push('/login')}
          color={'primary'}
        >
          Back to Login
        </Button>
      </Box>
    </Box>
  )
}

const useStyles = makeStyles(theme =>
  createStyles({
    container: {
      padding: theme.spacing(3),
    },
  }),
)
