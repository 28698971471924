import React, { useCallback, useEffect, useState } from 'react'
import { v4 as uuid } from 'uuid'
import { Box, Button, CircularProgress, Paper, Typography } from '@material-ui/core'

import { AppDrawer, AppDrawerToolbar, AppDrawerContent, AppDrawerActions } from '../../Components/AppDrawer'
import { useAppStyles } from '../../Hooks/useAppStyles'
import { useUpdateService } from './Hooks/useUpdateService'
import { BaseSchema } from '../../Schema/BaseSchema'
import { useServiceSelector } from './Hooks/useServiceSelector'
import { ServiceSchema } from '../../Schema/ServiceSchema'
import { ServiceForm } from './Components/ServiceForm'
import { useSnackbarMessages } from '../../Components/AppSnackbar/Hooks/useSnackbarMessages'
import { DeleteServiceButton } from './Components/DeleteServiceButton'

interface ServiceEditDrawerProps {
  schemaId: string
  open: boolean
  onClose: () => void,
}

export const ServiceEditDrawer: React.FC<ServiceEditDrawerProps> = ({
  open,
  onClose,
  schemaId,
}) => {
  const { addSuccessMessage, addErrorMessage } = useSnackbarMessages()
  const appStyles = useAppStyles()
  const { updateService, processing } = useUpdateService()

  const schemaState = useServiceSelector(schemaId)

  const [hash, setHash] = useState('')
  const [schema, setSchema] = React.useState<ServiceSchema>(null)

  const handleOnSchemaChanged = useCallback((schema: ServiceSchema) => {
    setHash(uuid())
  }, [])

  useEffect(() => {
    if (!schemaState || !(schemaState instanceof BaseSchema)) {
      setSchema(null)
      return
    }
    setSchema(schemaState.clone())
  }, [schemaState])

  const handleOnSave = useCallback(() => {
    if (schema.validate()) {
      updateService(schema).then(() => {
        addSuccessMessage('The service updated successfully.')
        onClose()
      }).catch(() => {
        addErrorMessage('Failed to udpate the service.')
      })
    }
    setHash(uuid())
  }, [schema, updateService, onClose, addErrorMessage, addSuccessMessage])

  return (
    <AppDrawer
      width={400}
      open={open}
      onClose={onClose}
    >
      <AppDrawerToolbar>
        <Typography style={{ flex: 1 }} variant={'h5'}>Edit Service</Typography>
        <DeleteServiceButton schema={schema} onDeleted={onClose} />
      </AppDrawerToolbar>
      <AppDrawerContent>
        <Box className={appStyles.content}>
          <Paper className={appStyles.content}>
            {!schema && <CircularProgress />}
            {schema && <ServiceForm schema={schema} onChange={handleOnSchemaChanged} />}
          </Paper>
        </Box>
      </AppDrawerContent>
      <AppDrawerActions>
        <Button disabled={processing} color={'primary'} onClick={handleOnSave}>Save</Button>
      </AppDrawerActions>
    </AppDrawer>
  )
}
