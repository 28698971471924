import { AppStore } from '../../../AppState/AppStore'
import { useAppSelector } from '../../../Hooks/useAppSelector'
import { ContainerSelectors } from '../StoreAdapter'

export const useContainerSelector = (entityId: string) => {
  const entityState = useAppSelector((state) =>
    ContainerSelectors.selectById(state, entityId),
  )

  return entityState
}
