/**
 * An adapter and slice definition for the redux store
 */

import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const STORE_NAME = 'shipmentFilters'

interface ShipmentFiltersState {
  filters: any
}

export const ShipmentFiltersSlice = createSlice({
  name: STORE_NAME,
  initialState: {
    filters: {},
  } as ShipmentFiltersState,
  reducers: {
    setFilters (state, action: PayloadAction<any>) {
      state.filters = action.payload
    },
    setFilter (state, action: PayloadAction<{ filter: string; value: any }>) {
      const payload = action.payload
      state.filters[payload.filter] = payload.value
    },
  },
})

export const ShipmentFiltersActions = ShipmentFiltersSlice.actions
export const ShipmentFiltersReducer = ShipmentFiltersSlice.reducer
