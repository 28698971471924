import { APIBase } from './APIBase'
import { Singleton } from 'typescript-ioc'
import { LabContactSchema } from '../../Schema/LabContactSchema'
import { ConsigneeAPIClient } from './Clients/ConsigneeAPIClient'

@Singleton
export class LabContactsAPI extends APIBase<LabContactSchema> {
  constructor () {
    super(LabContactSchema, ConsigneeAPIClient)
    this.resourcePath = 'lab-contacts'
  }
}
