import React, { useCallback, useEffect, useState } from 'react'
import { v4 as uuid } from 'uuid'
import { Box, Button, CircularProgress, Paper, Typography } from '@material-ui/core'

import { AppDrawer, AppDrawerToolbar, AppDrawerContent, AppDrawerActions } from '../../Components/AppDrawer'
import { useAppStyles } from '../../Hooks/useAppStyles'
import { useUpdateShipment } from './Hooks/useUpdateShipment'
import { BaseSchema } from '../../Schema/BaseSchema'
import { useShipmentSelector } from './Hooks/useShipmentSelector'
import { ShipmentSchema } from '../../Schema/ShipmentSchema'
import { ShipmentForm } from './Components/ShipmentForm'
import { useSnackbarMessages } from '../../Components/AppSnackbar/Hooks/useSnackbarMessages'

interface ShipmentEditDrawerProps {
  schemaId: string
  open: boolean
  onClose: () => void,
}

export const ShipmentEditDrawer: React.FC<ShipmentEditDrawerProps> = ({
  open,
  onClose,
  schemaId,
}) => {
  const appStyles = useAppStyles()
  const { addErrorMessage, addSuccessMessage } = useSnackbarMessages()
  const { updateShipment, processing, errors } = useUpdateShipment()

  const schemaState = useShipmentSelector(schemaId)

  const [hash, setHash] = useState('')
  const [schema, setSchema] = React.useState<ShipmentSchema>(null)

  const handleOnSchemaChanged = useCallback((schema: ShipmentSchema) => {
    setHash(uuid())
  }, [])

  useEffect(() => {
    if (!schemaState || !(schemaState instanceof BaseSchema)) {
      setSchema(null)
      return
    }
    setSchema(schemaState.clone())
  }, [schemaState])

  const handleOnSave = useCallback(() => {
    if (schema.validate()) {
      updateShipment(schema).then(() => {
        onClose()
      }).catch(() => {
        schema.setErrors(errors)
        addErrorMessage('Failed to create the shipment.')
        setHash(uuid())
      })
    }
    setHash(uuid())
  }, [schema, updateShipment, onClose])

  return (
    <AppDrawer
      width={400}
      open={open}
      onClose={onClose}
    >
      <AppDrawerToolbar>
        <Typography variant={'h5'}>Edit Shipment</Typography>
      </AppDrawerToolbar>
      <AppDrawerContent>
        <Box className={appStyles.content}>
          <Paper className={appStyles.content}>
            {!schema && <CircularProgress />}
            {schema && <ShipmentForm schema={schema} onChange={handleOnSchemaChanged} />}
          </Paper>
        </Box>
      </AppDrawerContent>
      <AppDrawerActions>
        <Button disabled={processing} color={'primary'} onClick={handleOnSave}>Save</Button>
      </AppDrawerActions>
    </AppDrawer>
  )
}
