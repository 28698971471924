/**
 * An adapter and slice definition for the redux store
 */

import {
  createEntityAdapter,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit'
import { AppRootState } from '../../AppState/AppStore'
import createFetchEntityThunk from '../../AppState/createFetchEntityThunk'
import createFetchPaginatedEntitiesThunk from '../../AppState/createFetchPaginatedEntitiesThunk'
import createUpdateManyReducer from '../../AppState/createUpdateManyReducer'
import createUpdateOneReducer from '../../AppState/createUpdateOneRecuder'
import { ContainerSchema } from '../../Schema/ContainerSchema'
import { ContainersAPI } from '../../Services/API/ContainersAPI'

const STORE_NAME = 'containers'

const fetchEntities = createFetchPaginatedEntitiesThunk<ContainerSchema>(
  STORE_NAME,
  ContainersAPI,
)

const fetchEntity = createFetchEntityThunk<ContainerSchema>(
  STORE_NAME,
  ContainersAPI,
)

const EntityAdapter = createEntityAdapter<ContainerSchema>({
  selectId: (entity) => entity.id,
  sortComparer: (a, b) =>
    a.getSortingValue().localeCompare(b.getSortingValue()),
})

const EntitySlice = createSlice({
  name: STORE_NAME,
  initialState: EntityAdapter.getInitialState(),
  reducers: {
    addOne: EntityAdapter.addOne,
    addMany: EntityAdapter.addMany,
    removeOne: EntityAdapter.removeOne,
    removeAll: EntityAdapter.removeAll,
    updateOne: (state, payload: PayloadAction<ContainerSchema>) =>
      createUpdateOneReducer<ContainerSchema>(state, payload),
  },
  extraReducers: (builder) =>
    builder
      .addCase(fetchEntities.fulfilled, (state, action) => {
        createUpdateManyReducer<ContainerSchema>(state, action)
      })
      .addCase(fetchEntity.fulfilled, (state, action) => {
        createUpdateOneReducer<ContainerSchema>(state, action)
      }),
})

export const ContainersActions = EntitySlice.actions
export const ContainersReducer = EntitySlice.reducer
export const ContainerSelectors = EntityAdapter.getSelectors(
  (state: AppRootState) => state.containers,
)
export const ContainersThunks = {
  fetchContainers: fetchEntities,
  fetchContainer: fetchEntity,
}
