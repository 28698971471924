import React, { useCallback, useState } from 'react'
import { AppDrawer, AppDrawerToolbar, AppDrawerContent, AppDrawerActions } from '../../Components/AppDrawer'
import { v4 as uuid } from 'uuid'
import { Box, Button, Paper, Typography } from '@material-ui/core'
import { useAppStyles } from '../../Hooks/useAppStyles'
import { useAddShipment } from './Hooks/useAddShipments'
import { ShipmentSchema } from '../../Schema/ShipmentSchema'
import { ShipmentForm } from './Components/ShipmentForm'
import { useSnackbarMessages } from '../../Components/AppSnackbar/Hooks/useSnackbarMessages'

interface ShipmentAddDrawerProps {
  open: boolean
  onClose: (schema?: ShipmentSchema) => void,
  defaultData?: Partial<ShipmentSchema>
}

export const ShipmentAddDrawer: React.FC<ShipmentAddDrawerProps> = ({
  open,
  onClose,
  defaultData,
}) => {
  const { addErrorMessage, addSuccessMessage } = useSnackbarMessages()
  const appStyles = useAppStyles()
  const { addShipment, processing, errors } = useAddShipment()

  const [hash, setHash] = useState('')
  const [schema, setSchema] = React.useState(new ShipmentSchema(defaultData))

  const handleOnSchemaChanged = useCallback((schema: ShipmentSchema) => {
    setHash(uuid())
  }, [])

  const handleOnSave = useCallback(() => {
    if (schema.validate()) {
      addShipment(schema).then(result => {
        setSchema(new ShipmentSchema(defaultData))
        addSuccessMessage('The shipment created successfully.')
        onClose(result)
      }).catch(() => {
        schema.setErrors(errors)
        addErrorMessage('Failed to create the shipment.')
        setHash(uuid())
      })
    }
    setHash(uuid())
  }, [schema, addShipment, onClose])

  const handleOnReset = useCallback(() => {
    setSchema(new ShipmentSchema(defaultData))
  }, [defaultData])

  return (
    <AppDrawer
      minWidth={400}
      width={'40vw'}
      open={open}
      onClose={onClose}
    >
      <AppDrawerToolbar
      >
        <Typography style={{ flex: 1 }} variant={'h5'}>Add Shipment</Typography>
        <Button onClick={handleOnReset}>Reset</Button>
      </AppDrawerToolbar>
      <AppDrawerContent>
        <Box className={appStyles.content}>
          <Paper className={appStyles.content}>
            <ShipmentForm schema={schema} onChange={handleOnSchemaChanged} />
          </Paper>
        </Box>
      </AppDrawerContent>
      <AppDrawerActions>
        <Button disabled={processing} color={'primary'} onClick={handleOnSave}>Save</Button>
      </AppDrawerActions>
    </AppDrawer>
  )
}
