import { Box, Container, createStyles, makeStyles, Paper } from '@material-ui/core'
import React from 'react'
import { Switch } from 'react-router-dom'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'

import { AppLogo } from '../Components/AppLogo'
import { AuthRoutes } from './AuthRoutes'
import { RouteWithSubRoutes } from '../Layout/Components/RouteWithSubRoutes'
import Configs from '../Configs'

export const Auth: React.FC = () => {
  const styles = useStyles()

  return (
    <GoogleReCaptchaProvider reCaptchaKey={Configs.recaptchaClientKey}>
      <Box className={styles.root}>
        <Container maxWidth={'xs'} className={styles.container}>
          <Box className={styles.logoContainer}>
            <AppLogo className={styles.logo} />
          </Box>
          <Paper>
            <Switch>
              {AuthRoutes.map((route, i) => (
                <RouteWithSubRoutes key={i} {...route} />
              ))}
            </Switch>
          </Paper>
        </Container>
      </Box>
    </GoogleReCaptchaProvider>
  )
}

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      display: 'flex',
      flex: 1,
      minHeight: '100vh',
      backgroundColor: theme.palette.background.default,
    },
    container: {
      paddingTop: theme.spacing(4),
      padding: theme.spacing(2),
    },
    logoContainer: {
      display: 'flex',
      justifyContent: 'center',
      margin: theme.spacing(2),
    },
    logo: {
      width: '100%',
      maxWidth: 140,
      height: 'auto',
    },
  }),
)
