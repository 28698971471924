/**
 * An adapter and slice definition for the redux store
 */

import {
  createEntityAdapter,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit'
import _ from 'lodash'
import { AppRootState } from '../../AppState/AppStore'
import createFetchEntityThunk from '../../AppState/createFetchEntityThunk'
import createFetchPaginatedEntitiesThunk from '../../AppState/createFetchPaginatedEntitiesThunk'
import createUpdateManyReducer from '../../AppState/createUpdateManyReducer'
import createUpdateOneReducer from '../../AppState/createUpdateOneRecuder'
import { ShipmentItemSchema } from '../../Schema/ShipmentItemSchema'
import { ShipmentItemsAPI } from '../../Services/API/ShipmentItemsAPI'

const STORE_NAME = 'shipmentItems'

const fetchEntities = createFetchPaginatedEntitiesThunk<ShipmentItemSchema>(
  STORE_NAME,
  ShipmentItemsAPI,
)
const fetchEntity = createFetchEntityThunk<ShipmentItemSchema>(
  STORE_NAME,
  ShipmentItemsAPI,
)

const EntityAdapter = createEntityAdapter<ShipmentItemSchema>({
  selectId: (entity) => entity.id,
  sortComparer: (a, b) =>
    a.getSortingValue().localeCompare(b.getSortingValue()),
})

const EntitySlice = createSlice({
  name: STORE_NAME,
  initialState: EntityAdapter.getInitialState(),
  reducers: {
    addOne: EntityAdapter.addOne,
    addMany: EntityAdapter.addMany,
    removeOne: EntityAdapter.removeOne,
    removeAll: EntityAdapter.removeAll,
    updateOne: (state, payload: PayloadAction<ShipmentItemSchema>) =>
      createUpdateOneReducer<ShipmentItemSchema>(state, payload),
    flagDeliveredByTrackingNumber: (
      state,
      payload: PayloadAction<{
        trackingNumber: string
        deliveryDate: Date
        signedBy: string
      }>,
    ) => {
      const { trackingNumber, deliveryDate, signedBy } = payload.payload
      _.forEach(state.entities, (entity) => {
        if (entity.carrierTrackingNumber === trackingNumber) {
          entity.isDelivered = true
          entity.deliveryDate = deliveryDate
          entity.signedBy = signedBy
        }
      })
    },
    flagDeliveredByShipmentId: (
      state,
      payload: PayloadAction<{
        shipmentId: string
        deliveryDate: Date
        signedBy: string
      }>,
    ) => {
      const { shipmentId, deliveryDate, signedBy } = payload.payload
      _.forEach(state.entities, (entity) => {
        if (entity.shipmentId === shipmentId) {
          entity.deliveryDate = deliveryDate
          entity.signedBy = signedBy
        }
      })
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(fetchEntities.fulfilled, (state, action) => {
        createUpdateManyReducer<ShipmentItemSchema>(state, action)
      })
      .addCase(fetchEntity.fulfilled, (state, action) => {
        createUpdateOneReducer<ShipmentItemSchema>(state, action)
      }),
})

export const ShipmentItemsActions = EntitySlice.actions
export const ShipmentItemsReducer = EntitySlice.reducer
export const ShipmentItemsSelectors = EntityAdapter.getSelectors(
  (state: AppRootState) => state.shipmentItems,
)
export const ShipmentItemsThunks = {
  fetchShipmentItems: fetchEntities,
  fetchShipmentItem: fetchEntity,
}
