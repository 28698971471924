import { Grid, Typography, Box } from '@material-ui/core'
import _ from 'lodash'
import React from 'react'
import { EntityAvatar } from './EntityAvatar'

interface EntityTagProps {
  showAvatar?: boolean
  primary?: string
  secondary?: string | React.ReactNode
  onClick?: (event: React.MouseEvent) => void
  avatarSize?: 'small' | 'medium' | 'large'
  fontSize?: number
}

export const EntityTag: React.FC<EntityTagProps> = ({
  showAvatar = true,
  primary,
  secondary,
  onClick = (event: React.MouseEvent) => {},
  avatarSize,
  fontSize,
}) => {
  return (
    <Box onClick={onClick} style={{ minWidth: 0 }}>
      <Grid container wrap={'nowrap'} style={{ minWidth: 0, alignContent: 'center', alignItems: 'center' }}>
        {showAvatar && <Grid item>
          <EntityAvatar text={primary} size={avatarSize} />
        </Grid>}
        <Grid item style={{ minWidth: 0 }}>
          <Typography noWrap style={{ minWidth: 0, fontSize: fontSize }}>{primary}</Typography>
          {_.isString(secondary) && <Typography noWrap variant={'body2'}>{secondary}</Typography>}
          {React.isValidElement(secondary) && secondary}
        </Grid>
      </Grid>
    </Box>
  )
}
