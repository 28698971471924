import React, { useCallback, useEffect, useState } from 'react'
import { v4 as uuid } from 'uuid'
import { Box, Button, CircularProgress, Paper, Typography } from '@material-ui/core'

import { AppDrawer, AppDrawerToolbar, AppDrawerContent, AppDrawerActions } from '../../Components/AppDrawer'
import { useAppStyles } from '../../Hooks/useAppStyles'
import { useUpdateChargeCode } from './Hooks/useUpdateChargeCode'
import { BaseSchema } from '../../Schema/BaseSchema'
import { useChargeCodeSelector } from './Hooks/useChargeCodeSelector'
import { ChargeCodeSchema } from '../../Schema/ChargeCodeSchema'
import { ChargeCodeForm } from './Components/ChargeCodeForm'
import { useSnackbarMessages } from '../../Components/AppSnackbar/Hooks/useSnackbarMessages'
import { DeleteChargeCodeButton } from './Components/DeleteChargeCodeButton'

interface ChargeCodeEditDrawerProps {
  schemaId: string
  open: boolean
  onClose: () => void,
}

export const ChargeCodeEditDrawer: React.FC<ChargeCodeEditDrawerProps> = ({
  open,
  onClose,
  schemaId,
}) => {
  const { addSuccessMessage, addErrorMessage } = useSnackbarMessages()

  const appStyles = useAppStyles()
  const { updateChargeCode, processing } = useUpdateChargeCode()

  const schemaState = useChargeCodeSelector(schemaId)

  const [hash, setHash] = useState('')
  const [schema, setSchema] = React.useState<ChargeCodeSchema>(null)

  const handleOnSchemaChanged = useCallback((schema: ChargeCodeSchema) => {
    setHash(uuid())
  }, [])

  useEffect(() => {
    if (!schemaState || !(schemaState instanceof BaseSchema)) {
      setSchema(null)
      return
    }
    setSchema(schemaState.clone())
  }, [schemaState])

  const handleOnSave = useCallback(() => {
    if (schema.validate()) {
      updateChargeCode(schema).then(() => {
        addSuccessMessage('The Charge Code updated successfuly.')
        onClose()
      }).catch(() => {
        addErrorMessage('Failed to update the charge code.')
      })
    }
    setHash(uuid())
  }, [schema, updateChargeCode, onClose, addSuccessMessage, addErrorMessage])

  return (
    <AppDrawer
      width={400}
      open={open}
      onClose={onClose}
    >
      <AppDrawerToolbar>
        <Typography variant={'h5'} style={{ flex: 1 }}>Edit Charge Code</Typography>
        <DeleteChargeCodeButton schema={schema} onDeleted={onClose} />
      </AppDrawerToolbar>
      <AppDrawerContent>
        <Box className={appStyles.content}>
          <Paper className={appStyles.content}>
            {!schema && <CircularProgress />}
            {schema && <ChargeCodeForm schema={schema} onChange={handleOnSchemaChanged} />}
          </Paper>
        </Box>
      </AppDrawerContent>
      <AppDrawerActions>
        <Button disabled={processing} color={'primary'} onClick={handleOnSave}>Save</Button>
      </AppDrawerActions>
    </AppDrawer>
  )
}
