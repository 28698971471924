import React from 'react'
import { AutocompleteInput } from '../../../Components/Inputs/AutocompleteInput'
import { InputOptionProps } from '../../../Components/Inputs/Types'
import { SearchTextField } from '../../../Components/SearchTextField'
import { useAppDispatch } from '../../../Hooks/useAppDispatch'
import { useShipmentFiltersSelector } from '../Hooks/useShipmentFiltersSelector'
import { ShipmentFiltersActions } from '../StoreFilterAdapter'

export const ShipmentItemStates: InputOptionProps[] = [
  {
    label: 'All',
    value: null,
  },
  {
    label: 'In Transit',
    value: 'in_transit',
  },
  {
    label: 'Delayed',
    value: 'delayed',
  },
  {
    label: 'Late',
    value: 'late',
  },
  {
    label: 'Delivered',
    value: 'delivered',
  },
  {
    label: 'Pending',
    value: 'pending',
  },
]

export const ShipmentFilters: React.FC = () => {
  const dispatch = useAppDispatch()
  const shipmentFilters: any = useShipmentFiltersSelector()

  const handleOnChange = (filter: string, value: string | number) => {
    dispatch(ShipmentFiltersActions.setFilter({ filter, value }))
  }

  return (<>
    <AutocompleteInput
      placeholder={'Select shipment state(late, undelivered)'}
      style={{ width: 200 }}
      showAvatar={false}
      options={ShipmentItemStates}
      value={shipmentFilters.state || null}
      onChange={value => handleOnChange('state', value) }
      enableCreate={false}
    />
    <SearchTextField
      style={{ width: 200, marginLeft: 10 }}
      placeholder={'Enter search value'}
      value={shipmentFilters.search || null}
      onChange={value => handleOnChange('search', value) }
    />
  </>)
}
