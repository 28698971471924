import React from 'react'
import { EntityTag } from '../../../Components/EntityTag'
import { useChargeCodeSelector } from '../Hooks/useChargeCodeSelector'

interface ChargeCodeTagProps {
  chargeCodeId: string
  avatarSize?: 'small' | 'medium' | 'large'
  showAvatar?: boolean
  fontSize?: number
}

export const ChargeCodeTag: React.FC<ChargeCodeTagProps> = ({
  chargeCodeId,
  avatarSize,
  showAvatar = false,
  fontSize,
}) => {
  const entity = useChargeCodeSelector(chargeCodeId)

  return (
    <EntityTag
      primary={entity?.code}
      secondary={entity?.description}
      avatarSize={avatarSize}
      showAvatar={showAvatar}
      fontSize={fontSize}
    />
  )
}
