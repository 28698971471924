import { Type } from 'class-transformer'
import {
  IsBoolean,
  IsDate,
  IsNotEmpty,
  IsNumber,
  IsOptional,
  IsString,
  IsUUID,
  Min,
  ValidateIf,
} from 'class-validator'
import _ from 'lodash'
import { BaseSchema } from './BaseSchema'
import { ShipmentSchema } from './ShipmentSchema'

export class ShipmentItemSchema extends BaseSchema {
  /**
   * ID
   */
  @IsOptional()
  @IsUUID()
  id: string

  /**
   * Shipment ID
   */
  @IsUUID()
  @IsNotEmpty()
  shipmentId: string = null

  /**
   * Container Identifier
   */
  @IsNotEmpty()
  @IsUUID()
  containerId: string = null

  /**
   * Carrier Tracking Number
   */
  @IsString()
  @IsNotEmpty()
  carrierTrackingNumber: string = null

  /**
   * Number of pieces shipped
   */
  @Type(() => Number)
  @IsNumber()
  @IsNotEmpty()
  @Min(1)
  pieces: number = 1

  /**
   * Weight
   */
  @Type(() => Number)
  @IsNumber()
  @IsNotEmpty()
  weight: number

  /**
   * Is Delivered?
   */
  @Type(() => Boolean)
  @IsBoolean()
  @IsNotEmpty()
  isDelivered = false

  /**
   * Delivery Date
   */
  @Type(() => Date)
  @ValidateIf((o) => o.isDelivered)
  @IsNotEmpty()
  @IsDate()
  deliveryDate: Date

  /**
   * Signed by
   */
  @IsString()
  @ValidateIf((o) => o.isDelivered)
  @IsNotEmpty()
  signedBy: string

  /**
   * Date created
   */
  @Type(() => Date)
  created: Date

  /**
   * Updated by
   */
  createdBy: string

  /**
   * Date updated
   */
  @Type(() => Date)
  updated: Date

  /**
   * Updated by
   */
  updatedBy: string

  /**
   * Date deleted
   */
  @Type(() => Date)
  deleted: Date

  /**
   * Deleted by
   */
  deletedBy: string

  /**
   *
   * @param shipment
   */
  shipment?: ShipmentSchema

  /**
   * Construct
   * @param data
   */
  constructor (data?: Partial<ShipmentItemSchema>) {
    super()
    _.assign(this, data)
  }

  /**
   * Returns a string for sorting
   * @returns string
   */
  getSortingValue () {
    return `${this.created}`
  }
}
