import { useCallback, useState } from 'react'
import { useMounted } from '../../../Hooks/useMounted'
import { LabSchema } from '../../../Schema/LabSchema'
import { useAppDispatch } from '../../../Hooks/useAppDispatch'
import { LabsActions } from '../StoreAdapter'
import { ServiceLocator } from '../../../Services/ServiceLocator'
import { LabsAPI } from '../../../Services/API/LabsAPI'

export const useUpdateLab = () => {
  const dispatch = useAppDispatch()
  const mounted = useMounted()

  const [processing, setProcessing] = useState(false)
  const [errors, setErrors] = useState<
    Record<string, string> | Record<string, string>[]
  >(null)
  const [errorMessage, setErrorMessage] = useState<string>(null)

  const updateLab = useCallback(
    async (schema: LabSchema): Promise<LabSchema> => {
      setProcessing(true)
      return ServiceLocator.resolve(LabsAPI)
        .updateEntity(schema)
        .then((schema) => {
          dispatch(LabsActions.updateOne(schema))
          if (mounted.current) {
            setProcessing(false)
            setErrors(null)
            setErrorMessage(null)
          }
          return schema
        })
        .catch((err) => {
          if (mounted.current) {
            setProcessing(false)
            setErrorMessage(err?.response?.data?.errorMessage)
            setErrors(err?.response?.data?.errors)
            throw err
          }
          return null
        })
    },
    [dispatch, mounted],
  )

  return {
    processing,
    errors,
    errorMessage,
    updateLab,
  }
}
