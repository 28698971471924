import { APIBase } from './APIBase'
import { Singleton } from 'typescript-ioc'
import { LabSchema } from '../../Schema/LabSchema'
import { ConsigneeAPIClient } from './Clients/ConsigneeAPIClient'

@Singleton
export class LabsAPI extends APIBase<LabSchema> {
  constructor () {
    super(LabSchema, ConsigneeAPIClient)
    this.resourcePath = 'labs'
  }
}
