import { Grid } from '@material-ui/core'
import React, { useCallback } from 'react'
import { SchemaLabPicker } from '../../../Components/Inputs/Schema/SchemaLabPicker'
import { SchemaLabsAutocompleteInput } from '../../../Components/Inputs/Schema/SchemaLabsAutocompleteInput'
import { SchemaSwitchInput } from '../../../Components/Inputs/Schema/SchemaSwitchInput'
import { SchemaTextInput } from '../../../Components/Inputs/Schema/SchemaTextInput'
import { LabContactSchema } from '../../../Schema/LabContactSchema'

interface LabContactFormProps {
  schema: LabContactSchema
  onChange: (schema: LabContactSchema) => void
}

export const LabContactForm: React.FC<LabContactFormProps> = ({
  schema,
  onChange,
}) => {
  const handleOnSchemaChanged = useCallback((value: any, field: string) => {
    schema.setValue(field, value)
    onChange(schema)
  }, [onChange, schema])

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <SchemaLabPicker
          schema={schema}
          onChange={handleOnSchemaChanged}
          enableCreate={true}
        />
      </Grid>
      <Grid item xs={12}>
        <SchemaTextInput
          required
          label={'Name'}
          placeholder={'Enter a name'}
          schema={schema}
          field={'name'}
          onChange={handleOnSchemaChanged}
        />
      </Grid>
      <Grid item xs={12}>
        <SchemaTextInput
          required
          label={'Email'}
          placeholder={'Enter an email'}
          schema={schema}
          field={'email'}
          onChange={handleOnSchemaChanged}
        />
      </Grid>
      <Grid item xs={12}>
        <SchemaSwitchInput
          label={'Is Default?'}
          schema={schema}
          field={'isDefault'}
          onChange={handleOnSchemaChanged}
        />
      </Grid>
    </Grid>
  )
}
