import { Box, createStyles, makeStyles, Typography } from '@material-ui/core'
import { GridCellParams, GridColDef, DataGrid, GridSortDirection, GridValueFormatterParams } from '@material-ui/data-grid'
import _ from 'lodash'
import React, { useMemo } from 'react'
import { useHistory } from 'react-router'
import { CopyToClipboardButton } from '../../../Components/CopyToClipboardButton'
import { ShipmentItemSchema } from '../../../Schema/ShipmentItemSchema'
import { ShipmentSchema } from '../../../Schema/ShipmentSchema'
import { formatLocalDate, formatLocalDateTime, removeTimeFromDate } from '../../../Utils/DateFunctions'
import { CarrierTag } from '../../Carriers/Components/CarrierTag'
import { ChargeCodeTag } from '../../ChargeCodes/Components/ChargeCodeTag'
import { ConsigneeTag } from '../../Consignees/Components/ConsigneeTag'
import { LabContactTag } from '../../LabContacts/Components/LabContactTag'
import { ServiceTag } from '../../Services/Components/ServiceTag'
import { useShipmentsSelector } from '../../Shippments/Hooks/useShipmentsSelector'

const columns: GridColDef[] = [
  {
    field: 'srNumber',
    headerName: 'SR Number',
    width: 200,
    valueFormatter: (params: GridValueFormatterParams) => {
      const shipment = params.getValue(params.id, 'shipment') as ShipmentSchema
      if (!shipment) {
        return null
      }
      return shipment.srNumber
    },
  },
  {
    field: 'srPickupDate',
    headerName: 'PD',
    width: 100,
    type: 'date',
    valueGetter: (params: GridValueFormatterParams) => {
      if (!params?.value) {
        return null
      }
      return removeTimeFromDate(params.value as Date)
    },
  },
  {
    field: 'shipmentDate',
    headerName: 'SD',
    width: 100,
    type: 'date',
    sortable: true,
    valueGetter: (params: GridValueFormatterParams) => {
      if (!params?.value) {
        return null
      }
      return removeTimeFromDate(params.value as Date)
    },
  },
  {
    field: 'consigneeId',
    headerName: 'Consignee',
    width: 250,
    renderCell: (params: GridValueFormatterParams) => {
      const shipment = params.getValue(params.id, 'shipment') as ShipmentSchema
      if (!shipment) {
        return null
      }
      return <ConsigneeTag consigneeId={shipment.consigneeId} />
    },
  },
  {
    field: 'serviceId',
    headerName: 'Service',
    width: 300,
    renderCell: (params: GridValueFormatterParams) => {
      const shipment = params.getValue(params.id, 'shipment') as ShipmentSchema
      if (!shipment) {
        return null
      }
      return <ServiceTag serviceId={shipment.serviceId} />
    },
  },
  {
    field: 'labContactId',
    headerName: 'Lab Contact',
    width: 300,
    renderCell: (params: GridValueFormatterParams) => {
      const shipment = params.getValue(params.id, 'shipment') as ShipmentSchema
      if (!shipment) {
        return null
      }
      return <LabContactTag labContactId={shipment.labContactId} />
    },
  },
  {
    field: 'carrierId',
    headerName: 'Carrier',
    width: 300,
    renderCell: (params: GridValueFormatterParams) => {
      const shipment = params.getValue(params.id, 'shipment') as ShipmentSchema
      if (!shipment) {
        return null
      }
      return <CarrierTag carrierId={shipment.carrierId} />
    },
  },
  {
    field: 'carrierTrackingNumber',
    headerName: 'Tracking #',
    width: 200,
    renderCell: (params: GridValueFormatterParams) => {
      return (
        <Box display={'flex'} flexWrap={'nowrap'} alignItems={'center'} flex={1} style={{ minWidth: 0 }}>
          <Typography noWrap style={{ minWidth: 0 }}>{params.value?.toString()}</Typography>
          <CopyToClipboardButton text={params.value?.toString()} />
        </Box>
      )
    },
  },
  {
    field: 'pieces',
    headerName: 'Pieces',
    width: 200,
    type: 'number',
  },
  {
    field: 'weight',
    headerName: 'Weight',
    width: 200,
    type: 'number',
  },
  {
    field: 'deliveryDate',
    headerName: 'Delivery Date',
    width: 200,
    type: 'date',
    valueGetter: (params: GridValueFormatterParams) => {
      if (!params?.value) {
        return null
      }
      return removeTimeFromDate(params.value as Date)
    },
  },
  {
    field: 'signedBy',
    headerName: 'Signed By',
    width: 200,
  },
  {
    field: 'chargeCodeId',
    headerName: 'Charge Code',
    width: 300,
    renderCell: (params: GridValueFormatterParams) => {
      const shipment = params.getValue(params.id, 'shipment') as ShipmentSchema
      if (!shipment) {
        return null
      }
      return <ChargeCodeTag chargeCodeId={shipment.chargeCodeId} />
    },
  },
]

interface ShipmentItemsDataGridProps {
  entities: ShipmentItemSchema[] | Record<string, ShipmentItemSchema>
}

export const ShipmentItemsDataGrid: React.FC<ShipmentItemsDataGridProps> = ({
  entities,
}) => {
  const history = useHistory()
  const styles = useStyles()
  const shipments = useShipmentsSelector()

  const [sortModel, setSortModel] = React.useState([
    {
      field: 'shipmentDate',
      sort: 'desc' as GridSortDirection,
    },
    {
      field: 'carrierTrackingNumber',
      sort: 'asc' as GridSortDirection,
    },
  ])

  const rows: ShipmentItemSchema[] = useMemo(() => {
    if (_.isArray(entities)) {
      return _.map(entities, e => {
        e.shipment = shipments[e.shipmentId]
        return e
      })
    } else if (_.isObject(entities)) {
      return _.map(entities, e => {
        e.shipment = shipments[e.shipmentId]
        return e
      })
    } else {
      return []
    }
  }, [entities, shipments])

  const handleOnCellClick = (params: GridCellParams) => {
    /* if (_.includes(['status', 'assigneeId'], cellParams.field)) {
      return
    } */
    history.push(`/shipment-management/items/${params.getValue(params.id, 'id')}`)
  }

  return (
    <DataGrid
      sortModel={sortModel}
      onSortModelChange={(model) => setSortModel(model)}
      className={styles.grid}
      columns={columns}
      rows={rows}
      showColumnRightBorder
      onCellClick={handleOnCellClick}
    />
  )
}

const useStyles = makeStyles(theme =>
  createStyles({
    grid: {
      fontSize: 14,
    },
  }),
)
