import { TextField } from '@material-ui/core'
import React from 'react'
import { BaseSchema } from '../../../Schema/BaseSchema'
import { v4 as uuid } from 'uuid'

interface SchemaTextInputProps {
  inputClassName?: any
  className?: any
  schema: BaseSchema
  label?: String
  field: string
  helperText?: string
  onChange: Function
  disabled?: boolean
  multiline?: boolean
  required?: boolean
  type?: string
  variant?: 'standard' | 'filled' | 'outlined'
  rowsMax?: number
  placeholder?: string
  rows?: number
  autoFocus?: boolean
}

export const SchemaTextInput: React.FC<SchemaTextInputProps> = ({
  className,
  inputClassName,
  schema,
  field,
  label,
  helperText,
  onChange,
  disabled,
  multiline,
  required,
  type,
  variant,
  rowsMax,
  placeholder,
  rows,
  autoFocus,
}) => {
  return (
    <TextField
      autoFocus={autoFocus}
      inputProps={{
        className: inputClassName,
        autoComplete: uuid(),
      }}
      className={className}
      disabled={disabled}
      label={label}
      value={String(schema.getValue(field) || '')}
      error={schema.isPropertyInvalid(field)}
      helperText={schema.isPropertyInvalid(field) ? schema.getPropertyError(field) : helperText }
      onChange={event => onChange(event.target.value, field)}
      multiline={multiline}
      required={required}
      fullWidth
      type={type}
      variant={variant}
      rowsMax={rowsMax}
      placeholder={placeholder}
      rows={rows}
    />
  )
}
