import { Box, ButtonGroup, createStyles, makeStyles, Typography } from '@material-ui/core'
import {
  GridCellParams,
  GridColDef,
  DataGrid,
  GridSortDirection,
  GridValueFormatterParams,
  GridToolbarContainer,
  GridToolbarExport,
} from '@material-ui/data-grid'
import _ from 'lodash'
import React, { useCallback, useMemo } from 'react'
import { CopyToClipboardButton } from '../../../Components/CopyToClipboardButton'
import { useDataGridContext } from '../../../Components/DataGrid/Hooks/useDataGridContext'
import { ShipmentItemSchema } from '../../../Schema/ShipmentItemSchema'
import { formatLocalDateTime, removeTimeFromDate } from '../../../Utils/DateFunctions'
import { CarrierExternalTrackingButton } from '../../Carriers/Components/CarrierExternalTrackingButton'
import { ContainerTag } from '../../Containers/Components/ContainerTag'
import { ShipmentItemExternalTrackingButton } from '../../ShipmentItems/Components/ShipmentItemExternalTrackingButton'
import { useShipmentSelector } from '../Hooks/useShipmentSelector'

const columns: GridColDef[] = [
  {
    field: 'carrierTrackingNumber',
    headerName: 'Tracking #',
    width: 250,
    renderCell: (params: GridValueFormatterParams) => {
      const trackingNumber = params?.value?.toString()
      return (
        <Box display={'flex'} flexWrap={'nowrap'} alignItems={'center'} flex={1} style={{ minWidth: 0 }}>
          <Typography noWrap style={{ minWidth: 0, flex: 1 }}>{trackingNumber}</Typography>
          <ButtonGroup>
            <CopyToClipboardButton buttonSize={'small'} text={trackingNumber} />
            <ShipmentItemExternalTrackingButton buttonSize={'small'} schema={params.row as ShipmentItemSchema} />
          </ButtonGroup>
        </Box>
      )
    },
  },
  {
    field: 'isDelivered',
    headerName: 'Is Delivered?',
    width: 120,
    type: 'boolean',
  },
  {
    field: 'deliveryDate',
    headerName: 'Delivery Date',
    width: 120,
    type: 'date',
    valueGetter: (params: GridValueFormatterParams) => {
      if (!params?.value) {
        return null
      }
      return removeTimeFromDate(params.value as Date)
    },
  },
  {
    field: 'signedBy',
    headerName: 'Signed By',
    width: 120,
  },
  {
    field: 'pieces',
    headerName: 'Pieces',
    width: 150,
    type: 'number',
  },
  {
    field: 'weight',
    headerName: 'Weight',
    width: 150,
    type: 'number',
  },
  {
    field: 'containerId',
    headerName: 'Container',
    width: 200,
    renderCell: (params: GridValueFormatterParams) => {
      return <ContainerTag containerId={params.value?.toString()} />
    },
  },
  {
    field: 'updated',
    headerName: 'Updated',
    width: 180,
    type: 'dateTime',
    valueFormatter: (params: GridValueFormatterParams) => {
      return formatLocalDateTime(params.getValue(params.id, 'updated') as Date)
    },
  },
  {
    field: 'created',
    headerName: 'Created',
    width: 180,
    type: 'dateTime',
    valueFormatter: (params: GridValueFormatterParams) => {
      return formatLocalDateTime(params.getValue(params.id, 'created') as Date)
    },
  },
]

interface ShipmentItemsDataGridProps {
  shipmentId: string
  entities: ShipmentItemSchema[] | Record<string, ShipmentItemSchema>
  onClick?: (item: ShipmentItemSchema) => void
}

export const ShipmentItemsDataGrid: React.FC<ShipmentItemsDataGridProps> = ({
  shipmentId,
  entities,
  onClick = (item: ShipmentItemSchema) => {},
}) => {
  const styles = useStyles()
  const { setRowSelection } = useDataGridContext()

  const [sortModel, setSortModel] = React.useState([
    {
      field: 'carrierTrackingNumber',
      sort: 'asc' as GridSortDirection,
    },
  ])

  const rows: ShipmentItemSchema[] = useMemo(() => {
    let arr
    if (_.isArray(entities)) {
      arr = entities
    } else {
      arr = _.map(entities)
    }

    return arr
  }, [entities])

  const handleOnCellClick = (cellParams: GridCellParams) => {
    onClick(cellParams.row as ShipmentItemSchema)
  }

  return (
    <DataGrid
      sortModel={sortModel}
      onSortModelChange={(model) => setSortModel(model)}
      className={styles.grid}
      columns={columns}
      rows={rows}
      showColumnRightBorder
      onCellClick={handleOnCellClick}
      checkboxSelection
      onSelectionModelChange={data => setRowSelection(data)}
    />
  )
}

const useStyles = makeStyles(theme =>
  createStyles({
    grid: {
      fontSize: 14,
    },
  }),
)
