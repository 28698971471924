import React, { useCallback, useEffect } from 'react'

import { AppRouteProps } from '../../Layout/AppRoutes'
import { AppTabProps } from '../../Components/AppTabs/index'
import { AppFeatureContainer } from '../../Components/AppFeatureContainer'
import { CarriersThunks } from '../Carriers/StoreAdapter'
import { useDispatch } from 'react-redux'
import { ChargeCodeThunks } from '../ChargeCodes/StoreAdapter'
import { ContainersThunks } from '../Containers/StoreAdapter'
import { ServicesThunks } from '../Services/StoreAdapter'
import { DelaysThunks } from '../Delays/StoreAdapter'

const tabs: AppTabProps[] = [
  { value: 'carriers', route: '/settings/carriers', label: 'Carriers' },
  { value: 'chargeCodes', route: '/settings/charge-codes', label: 'Charge Codes' },
  { value: 'containers', route: '/settings/containers', label: 'Containers' },
  { value: 'services', route: '/settings/services', label: 'Services' },
  { value: 'delays', route: '/settings/delays', label: 'Delays' },
]

interface SettingsProps {
  routes?: AppRouteProps[],
}

export const Settings: React.FC<SettingsProps> = ({
  routes,
}) => {
  const dispatch = useDispatch()

  const loadData = useCallback(() => {
    dispatch(DelaysThunks.fetchDelays())
    dispatch(CarriersThunks.fetchCarriers())
    dispatch(ChargeCodeThunks.fetchChargeCodes({}))
    dispatch(ContainersThunks.fetchContainers({}))
    dispatch(ServicesThunks.fetchServices({}))
  }, [dispatch])

  useEffect(() => {
    loadData()
  }, [loadData])

  return (
    <AppFeatureContainer routes={routes} tabs={tabs} selected={'carriers'} />
  )
}
