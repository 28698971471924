import React, { ReactNode, useCallback, useContext } from 'react'
import { Drawer, Box, Paper, makeStyles, Theme, createStyles, IconButton, useTheme, Divider } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import BackIcon from '@material-ui/icons/ArrowBack'
import clsx from 'clsx'
import _ from 'lodash'
import { useAppStyles } from '../../Hooks/useAppStyles'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    actionsContainer: {
      marginBottom: theme.spacing(1),
      padding: theme.spacing(2),
      display: 'flex',
      flexDirection: 'row',
    },
    topToolbar: {
      borderRadius: 0,
      flexDirection: 'row',
      display: 'flex',
      flexWrap: 'nowrap',
      height: 55,
      alignItems: 'center',
      justifyContent: 'flex-end',
    },
    botomToolbar: {
      borderRadius: 0,
      flexDirection: 'row',
      display: 'flex',
      flexWrap: 'nowrap',
      height: 55,
      alignItems: 'center',
      justifyContent: 'flex-end',
      overflow: 'auto',
    },
  }),
)

interface AppDrawerProps {
  anchor?: 'bottom' | 'left' | 'right' | 'top'
  width?: number | string;
  minWidth?: number | string;
  children: any;
  actions?: any;
  open?: boolean;
  onClose?: Function;
  variant?: 'permanent' | 'persistent' | 'temporary'
  BackdropProps?: any
  ModalProps?: any
  PaperProps?: any
  ContainerProps?: any
  closeOnClickOutside?: boolean
}

const DrawerContext = React.createContext({
  onClose: null,
})

export const AppDrawer: React.FC< AppDrawerProps > = ({
  anchor = 'right',
  width = '60vw',
  minWidth,
  ContainerProps = {},
  children,
  actions,
  open,
  onClose = () => {},
  variant,
  BackdropProps,
  ModalProps,
  PaperProps,
  closeOnClickOutside,
}) => {
  const theme = useTheme()
  const classes = useStyles()

  const contextValue = {
    onClose,
  }

  const handleClickOutsideBoundaries = useCallback(() => {
    if (closeOnClickOutside) {
      onClose()
    }
  }, [closeOnClickOutside, onClose])

  return (
    <Drawer
      anchor={anchor}
      open={open}
      onClose={handleClickOutsideBoundaries}
      variant={variant}
      BackdropProps={BackdropProps}
      ModalProps={ModalProps}
      PaperProps={PaperProps}
    >
      <DrawerContext.Provider value={contextValue}>
        <Box display="flex" flexDirection={'column'} style={_.merge({ width: width, minWidth: minWidth, flex: 1, backgroundColor: theme.palette.background.default }, ContainerProps || {})}>
          {actions && <Paper className={classes.actionsContainer}>
            <Box style={{ flex: 1, alignItems: 'center' }}>
              {actions}
            </Box>
            <IconButton onClick={() => onClose()} size={'small'}><CloseIcon /></IconButton>
          </Paper>}
          {children}
        </Box>
      </DrawerContext.Provider>
    </Drawer>
  )
}

interface AppDrawerToolbarProps {
  children: ReactNode;
  title?: boolean
}

export const AppDrawerToolbar: React.FC<AppDrawerToolbarProps> = ({ children }) => {
  const styles = useStyles()
  const appStyles = useAppStyles()
  const context = useContext(DrawerContext)
  const theme = useTheme()

  const handleOnClose = () => {
    if (context.onClose) {
      context.onClose()
    }
  }

  return (
    <Paper
      className={clsx([appStyles.content, styles.topToolbar])}
    >
      <IconButton onClick={() => handleOnClose()}><BackIcon /></IconButton>
      <Divider orientation={'vertical'} />
      <Box display={'flex'} style={{ flex: 1, marginLeft: theme.spacing(1) }} alignItems={'center'}>
        {children}
      </Box>
      <Box>
        <IconButton onClick={() => handleOnClose()} size={'small'}><CloseIcon /></IconButton>
      </Box>
    </Paper>
  )
}

interface AppDrawerContentProps {
  children: ReactNode;
  actions?: boolean
}

export const AppDrawerContent: React.FC<AppDrawerContentProps> = ({ children }) => {
  return (
    <Box display="flex" flexDirection={'column'} style={{ height: 'calc(100vh - 110px)', overflow: 'auto' }}>
      {children}
    </Box>
  )
}

interface AppDrawerActionsProps {
  children: ReactNode;
  actions?: boolean
}

export const AppDrawerActions: React.FC<AppDrawerActionsProps> = ({ children }) => {
  const styles = useStyles()
  const appStyles = useAppStyles()

  return (
    <Paper
      elevation={3}
      className={clsx([appStyles.content, styles.botomToolbar])}
    >
      {children}
    </Paper>
  )
}
