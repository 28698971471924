import React, { useCallback } from 'react'
import { useSnackbarMessages } from '../../../Components/AppSnackbar/Hooks/useSnackbarMessages'
import { DeleteIconButton } from '../../../Components/Buttons/DeleteIconButton'
import { AppPermission } from '../../../Hooks/useAppPermissions'
import { ShipmentItemSchema } from '../../../Schema/ShipmentItemSchema'
import { useDeleteShipmentItem } from '../Hooks/useDeleteShipmentItem'

interface DeleteShipmentItemButtonProps {
  schema: ShipmentItemSchema
  onDeleted?: () => void
}

export const DeleteShipmentItemButton: React.FC<DeleteShipmentItemButtonProps> = ({
  schema,
  onDeleted = () => {},
}) => {
  const { addSuccessMessage, addErrorMessage } = useSnackbarMessages()
  const { deleteShipmentItem, processing, errorMessage } = useDeleteShipmentItem()

  const handleOnDelete = useCallback(() => {
    deleteShipmentItem(schema.id).then(() => {
      addSuccessMessage('The carrier deleted successfully.')
      onDeleted()
    }).catch(() => {
      addErrorMessage(errorMessage)
    })
  }, [schema?.id, addSuccessMessage, addErrorMessage, deleteShipmentItem, errorMessage, onDeleted])

  return (
    <DeleteIconButton permissions={[AppPermission.ShipmentItemDelete]} onDelete={handleOnDelete} disabled={processing} />
  )
}
