import React, { useState, useEffect, useCallback } from 'react'
import { v4 as uuid } from 'uuid'

import TextField from '@material-ui/core/TextField'
import Autocomplete, { AutocompleteChangeReason, AutocompleteInputChangeReason } from '@material-ui/lab/Autocomplete'
import _ from 'lodash'
import { Box, Typography } from '@material-ui/core'
import { InputOptionProps } from './Types'
import { EntityAvatar } from '../EntityAvatar'
import { Add } from '@material-ui/icons'

export interface AutocompleteInputProps {
  value?: string;
  onChange: (value: string | number) => void;
  onTextChange?: Function;
  label?: string;
  className?: any;
  style?: any;
  placeholder?: string;
  variant?: string;
  error?: boolean;
  helperText?: string;
  filter?: Function;
  disabled?: boolean;
  freeSolo?: boolean
  options: InputOptionProps[]
  required?: boolean
  noOptionsText?: string
  clearOnBlur?: boolean
  onCreateNew?: (text: string) => void
  enableCreate?: boolean
  showAvatar?: boolean
}

export const AutocompleteInput: React.FC<AutocompleteInputProps> = ({
  style,
  className,
  value,
  onChange,
  onTextChange,
  label,
  placeholder = 'Enter Text',
  variant,
  error,
  helperText,
  filter,
  disabled,
  freeSolo,
  options,
  required,
  noOptionsText,
  clearOnBlur,
  onCreateNew,
  enableCreate,
  showAvatar = false,
}) => {
  let selected = null
  if (value) {
    selected = _.find(options, option => {
      return option.value === value
    })
  }

  const [text, setText] = useState('')
  const [selectedValue, setSelectedValue] = useState<InputOptionProps>(selected)
  const [highlightedValue, setHighlightedValue] = useState<InputOptionProps>()

  useEffect(() => {
    if (value) {
      const index = _.findIndex(options, option => {
        return option.value === value
      })
      if (index >= 0) {
        setSelectedValue(options[index])
      }
    } else {
      setSelectedValue(null)
    }
  }, [options, value])

  const filterOptions = useCallback(() => {
    if (filter) {
      return _.filter(options, option => {
        return Boolean(filter(option))
      })
    } else {
      return options
    }
  }, [filter, options])

  const handleOnHighligted = useCallback((value: InputOptionProps) => {
    setHighlightedValue(value)
  }, [])

  const handleOnChange = useCallback((value: string, reason: string) => {
    if (!value && text && onCreateNew && reason !== 'clear') {
      onCreateNew(text)
    }
    onChange(value)
  }, [onChange, onCreateNew, text])

  const handleOnKeyDown = useCallback((event: React.KeyboardEvent<HTMLDivElement>) => {
    if (highlightedValue && _.includes(['Tab'], event?.key)) {
      setSelectedValue(highlightedValue)
      handleOnChange(highlightedValue?.value, '')
    }
  }, [highlightedValue, handleOnChange])

  return (
    <Autocomplete
      clearOnBlur={enableCreate || clearOnBlur}
      noOptionsText={noOptionsText}
      disabled={disabled}
      freeSolo={enableCreate || freeSolo}
      style={style}
      className={className}
      options={filterOptions()}
      value={selectedValue}
      getOptionLabel={(option: InputOptionProps) => option.label}
      onBlur={(event: React.FocusEvent<HTMLDivElement>) => {
      }}
      renderOption={(option: InputOptionProps) => (
        <Box>
          {enableCreate && !option.value && (<Box display={'flex'} alignItems={'center'}>
            <Add fontSize={'inherit'} color={'primary'} /><Typography color={'primary'}>New Record:</Typography>
            </Box>)}
          <Typography>{option.label}</Typography>
          {option.subtitle && <Typography variant={'body2'}>{option.subtitle}</Typography>}
        </Box>
      )}
      filterOptions={(options: InputOptionProps[], state: any) => {
        const filtered = _.filter(options, option => {
          const name = option.label.toLocaleLowerCase()
          return !(name.indexOf(state.inputValue.toLowerCase()) === -1)
        })
        if (_.isEmpty(filtered) && enableCreate && state.inputValue !== '') {
          setText(state.inputValue)
          const option: InputOptionProps = {
            label: state.inputValue,
            value: null,
          }
          filtered.push(option)
        }
        return filtered
      }}
      onChange={(event, value: any, reason: AutocompleteChangeReason) => {
        setSelectedValue(value || null)
        handleOnChange(value ? value.value : null, reason)
      }}
      onInputChange={(event: React.ChangeEvent<{}>, label: string, reason: AutocompleteInputChangeReason) => {
        const selected = _.find(options, option => {
          return option.label === label
        })
        if (!selected) {
          setSelectedValue(null)
          onChange(null)
        }
      }}
      onHighlightChange={(event, option, reason) => {
        handleOnHighligted(option)
      }}
      onKeyDown={(event => {
        handleOnKeyDown(event)
      })}
      autoHighlight={true}
      renderInput={(params: any) => {
        return (
          <Box style={{ display: 'flex', flexDirection: 'row' }}>
            {showAvatar && <EntityAvatar text={selectedValue?.label} />}
            <TextField
              {...params}
              label={label}
              placeholder={placeholder}
              error={Boolean(error)}
              helperText={helperText}
              fullWidth
              variant={variant}
              // onBlur={handleOnTextChange}
              required={required}
              inputProps={{
                ...params.inputProps,
                autoComplete: uuid(), // disable autocomplete and autofill
              }}
            />
          </Box>
        )
      }}
    />
  )
}
