import { Divider, IconButton, List, ListItem, ListItemIcon, ListItemSecondaryAction, ListItemText, Typography } from '@material-ui/core'
import { Delete, Warning } from '@material-ui/icons'
import { Alert } from '@material-ui/lab'
import de from 'date-fns/esm/locale/de/index.js'
import _ from 'lodash'
import React, { useCallback, useMemo } from 'react'
import { useSnackbarMessages } from '../../../Components/AppSnackbar/Hooks/useSnackbarMessages'
import { DeleteIconButton } from '../../../Components/Buttons/DeleteIconButton'
import { ShipmentSchema } from '../../../Schema/ShipmentSchema'
import { formatLocalDateTime } from '../../../Utils/DateFunctions'
import { useDelaysSelector } from '../../Delays/Hooks/useDelaysSelector'
import { useDeleteShipmentDelay } from '../Hooks/useDeleteShipmentDelay'

interface ShipmentDelaysProps {
  shipment: ShipmentSchema
}

export const ShipmentDelays: React.FC<ShipmentDelaysProps> = ({
  shipment,
}) => {
  const delays = useDelaysSelector()
  const { addErrorMessage } = useSnackbarMessages()
  const { deleteShipmentDelay, processing: deleting } = useDeleteShipmentDelay()

  const delaysMap = useMemo(() => {
    return _.keyBy(delays, d => d.id)
  }, [delays])

  const getDelayName = useCallback((delayId: string) => {
    console.log('delays', delaysMap, delayId)
    const delay = _.get(delaysMap, delayId)
    if (!delay) {
      return '-'
    }
    return delay.name
  }, [delaysMap])

  const handleDeleteShipmentDelay = useCallback((delayId: string) => {
    deleteShipmentDelay(shipment, delayId).catch(() => {
      addErrorMessage('Failed to delete the shipment delay.')
    })
  }, [addErrorMessage, deleteShipmentDelay, shipment])

  if (_.isEmpty(shipment?.shipmentDelays)) {
    return (
      <Alert severity={'info'}>There are not shipment delays.</Alert>
    )
  }
  return (
    <List>
      {_.map(shipment?.shipmentDelays, delay => (
        <React.Fragment key={delay.id}>
          <ListItem key={delay.id} disabled={deleting}>
            <ListItemIcon>
              <Warning />
            </ListItemIcon>
            <ListItemText
              primary={getDelayName(delay.delayId)}
              secondary={formatLocalDateTime(delay.created)}
            />
            <ListItemSecondaryAction>
              <DeleteIconButton disabled={deleting} onDelete={() => handleDeleteShipmentDelay(delay.delayId)}/>
            </ListItemSecondaryAction>
          </ListItem>
          <Divider />
        </React.Fragment>
      ))}
    </List>
  )
}
