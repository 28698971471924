import { createStyles, makeStyles } from '@material-ui/core'
import { GridCellParams, GridColDef, DataGrid, GridSortDirection, GridValueFormatterParams } from '@material-ui/data-grid'
import _ from 'lodash'
import React, { useMemo } from 'react'
import { useHistory } from 'react-router'
import { DataGridBoolean } from '../../../Components/DataGrid/DataGridBoolean'
import { CarrierSchema } from '../../../Schema/CarrierSchema'
import { formatLocalDateTime } from '../../../Utils/DateFunctions'
import { CarrierTag } from './CarrierTag'
// import { UserTag } from '../UserTag'

const columns: GridColDef[] = [
  {
    field: 'name',
    headerName: 'Name',
    width: 300,
    renderCell: (params: GridValueFormatterParams) => {
      return <CarrierTag carrierId={params.getValue(params.id, 'id')?.toString()} />
    },
  },
  {
    field: 'trackingNumberPrefix',
    headerName: 'Prefix',
    width: 300,
  },
  {
    field: 'isEnabled',
    headerName: 'Enabled?',
    width: 120,
    headerAlign: 'center',
    align: 'center',
    renderCell: (params: GridValueFormatterParams) => {
      return (
        <DataGridBoolean params={params} />
      )
    },
  },
  {
    field: 'updated',
    headerName: 'Updated',
    width: 180,
    type: 'dateTime',
    valueFormatter: (params: GridValueFormatterParams) => {
      return formatLocalDateTime(params.getValue(params.id, 'updated') as Date)
    },
  },
  {
    field: 'created',
    headerName: 'Created',
    width: 180,
    type: 'dateTime',
    valueFormatter: (params: GridValueFormatterParams) => {
      return formatLocalDateTime(params.getValue(params.id, 'created') as Date)
    },
  },
]

interface CharegeCodesDataGridProps {
  entities: CarrierSchema[] | Record<string, CarrierSchema>
}

export const CharegeCodesDataGrid: React.FC<CharegeCodesDataGridProps> = ({
  entities,
}) => {
  const history = useHistory()
  const styles = useStyles()

  const [sortModel, setSortModel] = React.useState([
    {
      field: 'name',
      sort: 'asc' as GridSortDirection,
    },,
  ])

  const rows: CarrierSchema[] = useMemo(() => {
    return _.map(entities)
  }, [entities])

  const handleOnCellClick = (params: GridCellParams) => {
    /* if (_.includes(['status', 'assigneeId'], cellParams.field)) {
      return
    } */
    history.push(`/settings/carriers/${params.getValue(params.id, 'id')}`)
  }

  return (
    <DataGrid
      sortModel={sortModel}
      onSortModelChange={(model) => setSortModel(model)}
      className={styles.grid}
      columns={columns}
      rows={rows}
      showColumnRightBorder
      onCellClick={handleOnCellClick}
    />
  )
}

const useStyles = makeStyles(theme =>
  createStyles({
    grid: {
      fontSize: 14,
    },
  }),
)
