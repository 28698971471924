import React from 'react'
import { BaseSchema } from '../../../Schema/BaseSchema'
import { AutocompleteInput } from '../AutocompleteInput'
import { InputOptionProps } from '../Types'

interface SchemaAutocompleteInputProps {
  inputClassName?: any
  className?: any
  schema: BaseSchema
  label?: string
  field: string
  helperText?: string
  onChange: Function
  disabled?: boolean
  required?: boolean
  variant?: 'standard' | 'filled' | 'outlined'
  placeholder?: string
  freeSolo?: boolean
  options: InputOptionProps[]
  noOptionsText?: string
  clearOnBlur?: boolean
  onCreateNew?: (text: string) => void
  enableCreate?: boolean
}

export const SchemaAutocompleteInput: React.FC<SchemaAutocompleteInputProps> = ({
  className,
  inputClassName,
  schema,
  field,
  label,
  helperText,
  onChange,
  disabled,
  required,
  variant,
  placeholder,
  freeSolo,
  options,
  noOptionsText,
  clearOnBlur,
  onCreateNew,
  enableCreate,
}) => {
  return (
    <AutocompleteInput
      className={className}
      disabled={disabled}
      label={label}
      value={schema.getValue(field) || ''}
      error={schema.isPropertyInvalid(field)}
      helperText={schema.isPropertyInvalid(field) ? schema.getPropertyError(field) : helperText }
      onChange={value => onChange(value, field)}
      required={required}
      variant={variant}
      placeholder={placeholder}
      freeSolo={freeSolo}
      options={options}
      noOptionsText={noOptionsText}
      clearOnBlur={clearOnBlur}
      onCreateNew={onCreateNew}
      enableCreate={enableCreate}
    />
  )
}
