import { APIBase } from './APIBase'
import { Singleton } from 'typescript-ioc'
import { ShippingAPIClient } from './Clients/ShippingAPIClient'
import { ShipmentSchema } from '../../Schema/ShipmentSchema'
import { ShipmentDelaySchema } from '../../Schema/ShipmentDelaySchema'
import { plainToClass } from 'class-transformer'

@Singleton
export class ShipmentsAPI extends APIBase<ShipmentSchema> {
  constructor () {
    super(ShipmentSchema, ShippingAPIClient)
    this.resourcePath = 'shipments'
  }

  public async addDelay (data: { shipmentId: string; delayId: string }) {
    if (!data || !data.delayId || !data.shipmentId) {
      throw Error('Invalid data.')
    }
    const endpoint = this.buildBaseEndpoint() + `/${data.shipmentId}/delays`
    return this.apiInstance
      .post<ShipmentDelaySchema>(endpoint, data)
      .then((result) => {
        return plainToClass(ShipmentDelaySchema, result.data)
      })
  }

  public async removeDelay (data: { shipmentId: string; delayId: string }) {
    if (!data || !data.delayId || !data.shipmentId) {
      throw Error('Invalid data.')
    }
    const endpoint =
      this.buildBaseEndpoint() + `/${data.shipmentId}/delays/${data.delayId}`
    return this.apiInstance
      .delete<ShipmentDelaySchema>(endpoint)
      .then((result) => {
        return plainToClass(ShipmentDelaySchema, result.data)
      })
  }

  public async export (data: { shipmentIds: string[] }) {
    if (!data || !data.shipmentIds) {
      throw Error('Invalid data.')
    }
    const endpoint = this.buildBaseEndpoint() + '/export'
    return this.apiInstance
      .post<{ documentUrl: string }>(endpoint, data)
      .then((result) => {
        return result.data
      })
  }
}
