import { CircularProgress } from '@material-ui/core'
import React, { useCallback, useEffect, useState } from 'react'
import { CarriersThunks } from '../App/Carriers/StoreAdapter'
import { ChargeCodeThunks } from '../App/ChargeCodes/StoreAdapter'
import { ConsigneeThunks } from '../App/Consignees/StoreAdapter'
import { ContainersThunks } from '../App/Containers/StoreAdapter'
import { DelaysThunks } from '../App/Delays/StoreAdapter'
import { LabContactsThunks } from '../App/LabContacts/StoreAdapter'
import { LabsThunks } from '../App/Labs/StoreAdapter'
import { ServicesThunks } from '../App/Services/StoreAdapter'
import { ShipmentItemsThunks } from '../App/ShipmentItems/StoreAdapter'
import { ShipmentThunks } from '../App/Shippments/StoreAdapter'
import { UsersThunks } from '../App/Users/StoreAdapter'
import { useAppDispatch } from '../Hooks/useAppDispatch'

export const AppDataPreloader: React.FC = ({ children }) => {
  const dispatch = useAppDispatch()
  const [loaded, setLoaded] = useState(false)

  const preloadData = useCallback(() => {
    // load data asyncronously
    dispatch(DelaysThunks.fetchDelays())
    dispatch(ServicesThunks.fetchServices({}))
    dispatch(ContainersThunks.fetchContainers({}))
    dispatch(ChargeCodeThunks.fetchChargeCodes({}))
    dispatch(CarriersThunks.fetchCarriers())
    dispatch(UsersThunks.fetchUsers({}))
    dispatch(LabsThunks.fetchLabs({}))
    dispatch(LabContactsThunks.fetchLabContacts({}))
    dispatch(ConsigneeThunks.fetchConsignees({}))
    dispatch(ShipmentThunks.fetchShipments({}))
    dispatch(ShipmentItemsThunks.fetchShipmentItems({}))

    // load data that requires the app to wait
    Promise.all([]).finally(() => {
      setLoaded(true)
    })
  }, [dispatch])

  useEffect(() => {
    preloadData()
  }, [preloadData])

  return (
    <>
      {!loaded && <CircularProgress />}
      {loaded && children}
    </>
  )
}
