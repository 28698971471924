import { Box, useTheme } from '@material-ui/core'
import React from 'react'
import { useAppTabs } from './useAppTabs'

interface AppTabPanelProps {
  value: string
  className?: any,
}

export const AppTabPanel: React.FC<AppTabPanelProps> = ({
  children,
  value,
  className,
}) => {
  const appTabs = useAppTabs()
  const theme = useTheme()

  if (!appTabs) {
    return null
  }

  if (!appTabs.hidden && value !== appTabs.selected) {
    return null
  }

  return (
    <Box className={className} style={{ marginTop: theme.spacing(1) }} hidden={value !== appTabs.selected}>
      {children}
    </Box>
  )
}
