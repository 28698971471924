import { APIBase } from './APIBase'
import { Singleton } from 'typescript-ioc'
import { ServiceAPIClient } from './Clients/ServiceAPIClient'
import { DelaySchema } from '../../Schema/DelaySchema'

@Singleton
export class DelaysAPI extends APIBase<DelaySchema> {
  constructor () {
    super(DelaySchema, ServiceAPIClient)
    this.resourcePath = 'delays'
  }
}
