import React from 'react'
import { BaseSchema } from '../../../Schema/BaseSchema'
import { CountryPicker } from '../CountryInput'

interface SchemaCountryPickerProps {
  className?: any
  schema: BaseSchema
  label?: string
  field?: string
  helperText?: string
  onChange: Function
  disabled?: boolean
  required?: boolean
  variant?: 'standard' | 'filled' | 'outlined'
  placeholder?: string
  freeSolo?: boolean
  noOptionsText?: string
  clearOnBlur?: boolean
  enableCreate?: boolean
}

export const SchemaCountryPicker: React.FC<SchemaCountryPickerProps> = ({
  className,
  schema,
  field = 'country',
  label = 'Country',
  helperText,
  onChange = (value: string, field: string) => {},
  disabled,
  required,
  variant,
  placeholder = 'Select a Country',
  freeSolo,
  noOptionsText,
  clearOnBlur,
  enableCreate,
}) => {
  return (
    <CountryPicker
      className={className}
      label={label}
      onChange={value => onChange(value, field)}
      disabled={disabled}
      required={required}
      variant={variant}
      placeholder={placeholder}
      freeSolo={freeSolo}
      noOptionsText={noOptionsText}
      clearOnBlur={clearOnBlur}
      error={schema.isPropertyInvalid(field)}
      helperText={schema.isPropertyInvalid(field) ? schema.getPropertyError(field) : helperText }
      value={schema.getValue(field) || null}
    />
  )
}
