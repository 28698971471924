import countryData from 'country-region-data'
import _ from 'lodash'

const regionsByCountry = _.memoize((countryCode: string) => {
  return _.map(
    _.find(countryData, (country) => country.countryShortCode === countryCode)
      ?.regions,
    (region) => {
      return {
        value: region.shortCode,
        label: region.name,
      }
    },
  )
})

export const useCountryRegionList = (countryCode: string) => {
  return regionsByCountry(countryCode)
}
