import React, { useCallback, useEffect, useState } from 'react'
import { v4 as uuid } from 'uuid'
import { Box, Button, CircularProgress, Paper, Typography } from '@material-ui/core'

import { AppDrawer, AppDrawerToolbar, AppDrawerContent, AppDrawerActions } from '../../Components/AppDrawer'
import { useAppStyles } from '../../Hooks/useAppStyles'
import { useUpdateContainer } from './Hooks/useUpdateContainer'
import { BaseSchema } from '../../Schema/BaseSchema'
import { useContainerSelector } from './Hooks/useContainerSelector'
import { ContainerSchema } from '../../Schema/ContainerSchema'
import { ContainerForm } from './Components/ContainerForm'
import { useSnackbarMessages } from '../../Components/AppSnackbar/Hooks/useSnackbarMessages'
import { DeleteContainerButton } from './Components/DeleteContainerButton'

interface ContainerEditDrawerProps {
  schemaId: string
  open: boolean
  onClose: () => void,
}

export const ContainerEditDrawer: React.FC<ContainerEditDrawerProps> = ({
  open,
  onClose,
  schemaId,
}) => {
  const { addSuccessMessage, addErrorMessage } = useSnackbarMessages()
  const appStyles = useAppStyles()
  const { updateContainer, processing } = useUpdateContainer()

  const schemaState = useContainerSelector(schemaId)

  const [hash, setHash] = useState('')
  const [schema, setSchema] = React.useState<ContainerSchema>(null)

  const handleOnSchemaChanged = useCallback((schema: ContainerSchema) => {
    setHash(uuid())
  }, [])

  useEffect(() => {
    if (!schemaState || !(schemaState instanceof BaseSchema)) {
      setSchema(null)
      return
    }
    setSchema(schemaState.clone())
  }, [schemaState])

  const handleOnSave = useCallback(() => {
    if (schema.validate()) {
      updateContainer(schema).then(() => {
        addSuccessMessage('The container created successfully.')
        onClose()
      }).catch(() => {
        addErrorMessage('Failed to create the container.')
      })
    }
    setHash(uuid())
  }, [schema, updateContainer, onClose, addSuccessMessage, addErrorMessage])

  return (
    <AppDrawer
      width={400}
      open={open}
      onClose={onClose}
    >
      <AppDrawerToolbar>
        <Typography variant={'h5'} style={{ flex: 1 }}>Edit Container</Typography>
        <DeleteContainerButton schema={schema} onDeleted={onClose} />
      </AppDrawerToolbar>
      <AppDrawerContent>
        <Box className={appStyles.content}>
          <Paper className={appStyles.content}>
            {!schema && <CircularProgress />}
            {schema && <ContainerForm schema={schema} onChange={handleOnSchemaChanged} />}
          </Paper>
        </Box>
      </AppDrawerContent>
      <AppDrawerActions>
        <Button disabled={processing} color={'primary'} onClick={handleOnSave}>Save</Button>
      </AppDrawerActions>
    </AppDrawer>
  )
}
