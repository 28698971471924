import { useCallback, useState } from 'react'
import { useMounted } from '../../../Hooks/useMounted'
import { useAppDispatch } from '../../../Hooks/useAppDispatch'
import { LabContactsActions } from '../StoreAdapter'
import { LabContactSchema } from '../../../Schema/LabContactSchema'
import { ServiceLocator } from '../../../Services/ServiceLocator'
import { LabContactsAPI } from '../../../Services/API/LabContactsAPI'

export const useDeleteLabContact = () => {
  const dispatch = useAppDispatch()
  const mounted = useMounted()

  const [processing, setProcessing] = useState(false)
  const [error, setError] = useState(false)
  const [errorMessage, setErrorMessage] = useState<string>(null)

  const deleteLabContact = useCallback(
    async (schemaId: string): Promise<LabContactSchema> => {
      setProcessing(true)
      return ServiceLocator.resolve(LabContactsAPI)
        .deleteEntity(schemaId)
        .then((schema) => {
          dispatch(LabContactsActions.removeOne(schemaId))
          if (mounted.current) {
            setProcessing(false)
            setError(true)
            setErrorMessage(null)
          }
          return schema
        })
        .catch((err) => {
          if (mounted.current) {
            setProcessing(false)
            setError(true)
            setErrorMessage(err?.response?.data?.errorMessage)
            throw err
          }
          return null
        })
    },
    [dispatch, mounted],
  )

  return {
    processing,
    error,
    errorMessage,
    deleteLabContact,
  }
}
