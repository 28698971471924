import { useAppSelector } from '../../../Hooks/useAppSelector'
import { ChargeCodeSelectors } from '../StoreAdapter'

export const useChargeCodeSelector = (entityId: string) => {
  const entityState = useAppSelector((state) =>
    ChargeCodeSelectors.selectById(state, entityId),
  )

  return entityState
}
