import { createSelector } from '@reduxjs/toolkit'
import _, { memoize } from 'lodash'
import { AppRootState } from '../../AppState/AppStore'
import { LabContactsSelectors } from './StoreAdapter'

export const getLabContactSelector = createSelector(
  (state: AppRootState) => state,
  (state) =>
    memoize((id: string) => {
      return LabContactsSelectors.selectById(state, id)
    }),
)
