import { Box, Button, Paper, Toolbar, Typography } from '@material-ui/core'
import _ from 'lodash'
import React, { useMemo, useState } from 'react'
import { useHistory } from 'react-router'
import { DataGridProvider } from '../../../Components/DataGrid/DataGridProvider'
import { useDataGridContext } from '../../../Components/DataGrid/Hooks/useDataGridContext'
import { AppPermission, useAppPermissions } from '../../../Hooks/useAppPermissions'
import { useAppStyles } from '../../../Hooks/useAppStyles'
import { ShipmentItemSchema } from '../../../Schema/ShipmentItemSchema'
import { useCarrierSelector } from '../../Carriers/Hooks/useCarrierSelector'
import { useShipmentItemsForShipmentSelector } from '../../ShipmentItems/Hooks/useShipmentItemsForShipmentSelector'
import { ShipmentItemAddDrawer } from '../../ShipmentItems/ShipmentItemAddDrawer'
import { ShipmentItemEditDrawer } from '../../ShipmentItems/ShipmentItemEditDrawer'
import { ShipmentItemsBulkUpdateDrawer } from '../../ShipmentItems/ShipmentItemsBulkUpdateDrawer'
import { useShipmentSelector } from '../Hooks/useShipmentSelector'
import { ShipmentItemsDataGrid } from './ShipmentItemsDataGrid'

interface ShipmentItemsForShipmentProps {
  shipmentId: string
}

interface ShipmentItemsToolbarProps {
  shipmentId: string
  shipmentItems: ShipmentItemSchema[]
}

const ShipmentItemsToolbar: React.FC<ShipmentItemsToolbarProps> = ({
  shipmentId,
  shipmentItems,
}) => {
  const { checkPermissions } = useAppPermissions()
  const { rowSelection } = useDataGridContext()

  const shipment = useShipmentSelector(shipmentId)
  const carrier = useCarrierSelector(shipment?.carrierId)

  const [openAdd, setOpenAdd] = useState(false)
  const [openBulkUpdate, setOpenBulkUpdate] = useState(false)

  const totalWeight = useMemo(() => {
    return _.sum(_.map(shipmentItems, e => e.weight))
  }, [shipmentItems])

  const totalPieces = useMemo(() => {
    return _.sum(_.map(shipmentItems, e => e.pieces))
  }, [shipmentItems])

  return (
    <Toolbar>
      {_.isEmpty(rowSelection) && (
        <>
          <Box flex={1}>
            <Box display={'flex'}>
              <Typography variant={'h6'}>Total Weight: </Typography>
              <Typography style={{ marginLeft: 10 }}>{totalWeight}</Typography>
            </Box>
            <Box display={'flex'}>
            <Typography variant={'h6'}>Total Pieces: </Typography>
              <Typography style={{ marginLeft: 10 }}>{totalPieces}</Typography>
            </Box>
          </Box>
          {checkPermissions(AppPermission.ShipmentItemAdd) && <Button variant={'contained'} color={'primary'} onClick={() => setOpenAdd(true)}>Add Item</Button>}
          <ShipmentItemAddDrawer
            open={openAdd}
            onClose={() => setOpenAdd(false)}
            defaultData={{
              shipmentId: shipmentId,
              carrierTrackingNumber: carrier?.trackingNumberPrefix || null,
            }}
          />
        </>
      )}
      {checkPermissions(AppPermission.ShipmentItemEdit) && !_.isEmpty(rowSelection) && (
        <>
        <Button variant={'contained'} color={'primary'} onClick={() => setOpenBulkUpdate(true)}>Bulk Update</Button>
        <ShipmentItemsBulkUpdateDrawer open={openBulkUpdate} shipmentItemIds={rowSelection as string[]} onClose={() => setOpenBulkUpdate(false)}/>
        </>
      )}
    </Toolbar>
  )
}

export const ShipmentItemsForShipment: React.FC<ShipmentItemsForShipmentProps> = ({
  shipmentId,
}) => {
  const { checkPermissions } = useAppPermissions()
  const history = useHistory()
  const appStyles = useAppStyles()

  const [editSchemaId, setEditSchemaId] = useState<string>(null)

  const entities = useShipmentItemsForShipmentSelector(shipmentId)

  return (
    <DataGridProvider>
      <Paper className={appStyles.contentItem}>
        <ShipmentItemsToolbar shipmentId={shipmentId} shipmentItems={entities} />
      </Paper>
      <Paper className={appStyles.content} style={{ height: 'calc(100vh - 200px)', overflow: 'auto' }}>
        <ShipmentItemsDataGrid
          entities={entities} shipmentId={shipmentId}
          onClick={item => {
            setEditSchemaId(item?.id)
          }}
          />
      </Paper>
      {checkPermissions(AppPermission.ShipmentItemEdit) && <ShipmentItemEditDrawer
        open={Boolean(editSchemaId)}
        schemaId={editSchemaId}
        onClose={() => setEditSchemaId(null)}
      />}
  </DataGridProvider>
  )
}
