import React, { useCallback, useState } from 'react'
import { AppDrawer, AppDrawerToolbar, AppDrawerContent, AppDrawerActions } from '../../Components/AppDrawer'
import { LabContactForm } from './Components/LabContactForm'
import { v4 as uuid } from 'uuid'
import { Box, Button, Paper, Typography } from '@material-ui/core'
import { useAppStyles } from '../../Hooks/useAppStyles'
import { useAddLabContact } from './Hooks/useAddLabContact'
import { LabContactSchema } from '../../Schema/LabContactSchema'

interface LabContactAddDrawerProps {
  open: boolean
  onClose: (schema?: LabContactSchema) => void,
  defaultData?: Partial<LabContactSchema>
}

export const LabContactAddDrawer: React.FC<LabContactAddDrawerProps> = ({
  open,
  onClose,
  defaultData,
}) => {
  const appStyles = useAppStyles()
  const { addLabContact, processing } = useAddLabContact()

  const [hash, setHash] = useState('')
  const [labContact, setLabContact] = React.useState(new LabContactSchema(defaultData))

  const handleOnSchemaChanged = useCallback((schema: LabContactSchema) => {
    setHash(uuid())
  }, [])

  const handleOnSave = useCallback(() => {
    if (labContact.validate()) {
      addLabContact(labContact).then(result => {
        setLabContact(new LabContactSchema(defaultData))
        onClose(result)
      }).catch(() => {
        setHash(uuid())
      })
    }
    setHash(uuid())
  }, [labContact, addLabContact, onClose])

  return (
    <AppDrawer
      width={400}
      open={open}
      onClose={onClose}
    >
      <AppDrawerToolbar>
        <Typography variant={'h5'}>Add Lab Contact</Typography>
      </AppDrawerToolbar>
      <AppDrawerContent>
        <Box className={appStyles.content}>
          <Paper className={appStyles.content}>
            <LabContactForm schema={labContact} onChange={handleOnSchemaChanged} />
          </Paper>
        </Box>
      </AppDrawerContent>
      <AppDrawerActions>
        <Button disabled={processing} color={'primary'} onClick={handleOnSave}>Save</Button>
      </AppDrawerActions>
    </AppDrawer>
  )
}
