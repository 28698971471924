import React from 'react'
import { AppDrawer, AppDrawerToolbar, AppDrawerContent, AppDrawerActions } from '../../Components/AppDrawer'
import { Box, Button, CircularProgress, Typography } from '@material-ui/core'
import { useAppStyles } from '../../Hooks/useAppStyles'
import { useUpdateLabContact } from './Hooks/useUpdateLabContact'
import { useLabContactSelector } from './Hooks/useLabContactSelector'
import { ContentContainer } from '../../Components/Content/ContentContainer'
import { ContentItem } from '../../Components/Content/ContentItem'
import { EditIconButton } from '../../Components/Buttons/EditIconButton'
import { AppPermission } from '../../Hooks/useAppPermissions'
import { useHistory } from 'react-router'
import { LabTag } from '../Labs/Components/LabTag'
import { DeleteLabContactButton } from './Components/DeleteLabContactButton'

interface LabContactViewDrawerProps {
  labContactId: string
  open: boolean
  onClose: () => void,
}

export const LabContactViewDrawer: React.FC<LabContactViewDrawerProps> = ({
  open,
  onClose,
  labContactId,
}) => {
  const appStyles = useAppStyles()
  const history = useHistory()

  const labContact = useLabContactSelector(labContactId)

  return (
    <AppDrawer
      width={400}
      open={open}
      onClose={onClose}
    >
      <AppDrawerToolbar>
        <Typography variant={'h5'} style={{ flex: 1 }}>View Lab Contact</Typography>
        <EditIconButton
          size={'small'}
          permissions={AppPermission.LabContactEdit}
          onClick={() => history.push(`/lab-management/contacts/${labContactId}/edit`)}
        />
        <DeleteLabContactButton schema={labContact} onDeleted={onClose} />
      </AppDrawerToolbar>
      <AppDrawerContent>
        <Box className={appStyles.content}>
        {!labContact && <CircularProgress />}
        {labContact && (
          <>
            <ContentContainer title={'Lab'}>
              <LabTag labId={labContact.labId} button onClick={() => history.push(`/lab-management/labs/${labContact.labId}`)} />
            </ContentContainer>

            <ContentContainer title={'Lab Contact'}>
              <ContentItem title={'Name'}>
                <Typography>{labContact.name}</Typography>
              </ContentItem>
              <ContentItem title={'Email'}>
                <Typography>{labContact.email}</Typography>
              </ContentItem>
            </ContentContainer>
          </>
        )}
        </Box>
      </AppDrawerContent>
      <AppDrawerActions>
        <Button color={'primary'} onClick={onClose}>Close</Button>
      </AppDrawerActions>
    </AppDrawer>
  )
}
