import { createStyles, makeStyles } from '@material-ui/core'
import { GridCellParams, GridColDef, DataGrid, GridSortDirection, GridValueFormatterParams } from '@material-ui/data-grid'
import React from 'react'
import { useHistory } from 'react-router'
import { UserSchema } from '../../../Schema/UserSchema'
import { formatLocalDateTime } from '../../../Utils/DateFunctions'
import { UserTag } from './UserTag'

const columns: GridColDef[] = [
  {
    field: 'lastName',
    headerName: 'Name',
    width: 300,
    renderCell: (params: GridValueFormatterParams) => {
      return (
        <UserTag userId={params.getValue(params.id, 'id')?.toString()} />
      )
    },
  },
  {
    field: 'email',
    headerName: 'Email',
    width: 250,
  },
  {
    field: 'role',
    headerName: 'Role',
    width: 180,
  },
  {
    field: 'updated',
    headerName: 'Updated',
    width: 180,
    type: 'dateTime',
    valueFormatter: (params: GridValueFormatterParams) => {
      return formatLocalDateTime(params.getValue(params.id, 'updated') as Date)
    },
  },
  {
    field: 'created',
    headerName: 'Created',
    width: 180,
    type: 'dateTime',
    valueFormatter: (params: GridValueFormatterParams) => {
      return formatLocalDateTime(params.getValue(params.id, 'created') as Date)
    },
  },
]

interface UsersDataGridProps {
  users: UserSchema[]
}

export const UsersDataGrid: React.FC<UsersDataGridProps> = ({
  users,
}) => {
  const history = useHistory()
  const styles = useStyles()

  const [sortModel, setSortModel] = React.useState([
    {
      field: 'lastName',
      sort: 'asc' as GridSortDirection,
    },
  ])

  const handleOnCellClick = (params: GridCellParams) => {
    /* if (_.includes(['status', 'assigneeId'], cellParams.field)) {
      return
    } */
    history.push(`/user-management/users/${params.getValue(params.id, 'id')}`)
  }

  return (
    <DataGrid
      sortModel={sortModel}
      onSortModelChange={(model) => setSortModel(model)}
      className={styles.grid}
      columns={columns}
      rows={users}
      showColumnRightBorder
      onCellClick={handleOnCellClick}
    />
  )
}

const useStyles = makeStyles(theme =>
  createStyles({
    grid: {
      fontSize: 14,
    },
  }),
)
