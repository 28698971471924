import { useCallback, useState } from 'react'

import { v4 as uuid } from 'uuid'

import { useMounted } from '../../../Hooks/useMounted'
import { useAppDispatch } from '../../../Hooks/useAppDispatch'
import { ShipmentsActions } from '../StoreAdapter'
import { ShipmentSchema } from '../../../Schema/ShipmentSchema'
import { ServiceLocator } from '../../../Services/ServiceLocator'
import { ShipmentsAPI } from '../../../Services/API/ShipmentsAPI'
import { plainToClass } from 'class-transformer'

export const useAddShipmentDelay = () => {
  const dispatch = useAppDispatch()
  const mounted = useMounted()

  const [processing, setProcessing] = useState(false)
  const [errors, setErrors] = useState<
    Record<string, string> | Record<string, string>[]
  >(null)
  const [errorMessage, setErrorMessage] = useState<string>(null)

  const addShipmentDelay = useCallback(
    async (
      shipment: ShipmentSchema,
      delayId: string,
    ): Promise<ShipmentSchema> => {
      setProcessing(true)
      return ServiceLocator.resolve(ShipmentsAPI)
        .addDelay({ shipmentId: shipment?.id, delayId })
        .then((schema) => {
          shipment.attachDelay(delayId)
          dispatch(
            ShipmentsActions.updateOne(plainToClass(ShipmentSchema, shipment)),
          )
          if (mounted.current) {
            setProcessing(false)
            setErrors(null)
            setErrorMessage(null)
          }
          return schema
        })
        .catch((err) => {
          if (mounted.current) {
            setProcessing(false)
            setErrorMessage(err?.response?.data?.errorMessage)
            setErrors(err?.response?.data?.errors)
            throw err
          }
          return null
        })
    },
    [dispatch, mounted],
  )

  return {
    processing,
    errors,
    errorMessage,
    addShipmentDelay,
  }
}
