import { Box } from '@material-ui/core'
import clsx from 'clsx'
import React, { ReactNode } from 'react'
import { useAppStyles } from '../../Hooks/useAppStyles'
import { ContentHeader } from './ContentHeader'

interface ContentItemProps {
  title: string
  action?: ReactNode,
}

export const ContentItem: React.FC<ContentItemProps> = ({
  title,
  children,
  action,
}) => {
  const appStyles = useAppStyles()

  return (
    <Box className={clsx([appStyles.content, appStyles.contentItem])}>
      <ContentHeader title={title} action={action} />
      {children}
    </Box>
  )
}
