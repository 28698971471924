import _ from 'lodash'
import { useMemo } from 'react'
import { useLabContactsSelector } from './useLabContactsSelector'

export const useLabContactsForLabSelector = (labId: string) => {
  const labContacts = useLabContactsSelector()

  const labContactForLab = useMemo(() => {
    return _.filter(labContacts, (lc) => lc.labId === labId)
  }, [labContacts, labId])

  return labContactForLab
}
