import { useCallback, useState } from 'react'
import { useMounted } from '../../Hooks/useMounted'
import { useAppDispatch } from '../../Hooks/useAppDispatch'
import { AuthAPI } from '../../Services/API/AuthAPI'
import { ServiceLocator } from '../../Services/ServiceLocator'
import { AuthActions } from '../StoreAdapter'
import { UserSchema } from '../../Schema/UserSchema'
import { UsersAPI } from '../../Services/API/UsersAPI'

export const useFetchProfile = () => {
  const dispatch = useAppDispatch()
  const mounted = useMounted()

  const [processing, setProcessing] = useState(false)
  const [error, setError] = useState(false)
  const [errorMessage, setErrorMessage] = useState<string>(null)

  const fetchProfile = useCallback(async (): Promise<UserSchema> => {
    try {
      setProcessing(true)
      return ServiceLocator.resolve(UsersAPI)
        .fetchProfile()
        .then((tokens) => {
          if (mounted.current) {
            setError(false)
            setErrorMessage(null)
            setProcessing(false)
            return tokens
          }
        })
        .catch((err) => {
          dispatch(AuthActions.setTokens(null))
          if (mounted.current) {
            setError(true)
            setErrorMessage(err.message)
            setProcessing(false)
            throw Error(err)
          }
          return null
        })
    } catch (err) {
      if (mounted.current) {
        setError(true)
        setErrorMessage(err.message)
        setProcessing(false)
        throw Error(err)
      }
    }
  }, [mounted, dispatch])

  return {
    processing,
    error,
    errorMessage,
    fetchProfile,
  }
}
