import { Box, Button, Paper, useTheme } from '@material-ui/core'
import _, { filter } from 'lodash'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useHistory, useParams } from 'react-router'
import { AppTabPanel } from '../../Components/AppTabs/AppTabPanel'
import { useAppTabs } from '../../Components/AppTabs/useAppTabs'
import { AppToolbar } from '../../Components/AppToolbar'
import { DataGridContext, DataGridProvider } from '../../Components/DataGrid/DataGridProvider'
import { AppPermission, useAppPermissions } from '../../Hooks/useAppPermissions'
import { useAppSelector } from '../../Hooks/useAppSelector'
import { useAppStyles } from '../../Hooks/useAppStyles'
import { ExportShipmentsButton } from './Components/ExportShipmentsButton'
import { ShipmentFilters } from './Components/ShipmentFilters'
import { ShipmentsDataGrid } from './Components/ShipmentsDataGrid'
import { useShipmentFiltersSelector } from './Hooks/useShipmentFiltersSelector'
import { useShipmentsSelector } from './Hooks/useShipmentsSelector'
import { getDelayedShipmentIdsSelector, getDeliveredShipmentIdsSelector, getLateShipmentIdsSelector, getPendingShipmentIdsSelector, getShipmentIdsMatchingSearchSelector, getUndeliveredShipmentIdsSelector } from './Selectors'
import { ShipmentAddDrawer } from './ShipmentAddDrawer'
import { ShipmentEditDrawer } from './ShipmentEditDrawer'
import { ShipmentViewDrawer } from './ShipmentViewDrawer'

export const Shipments: React.FC = () => {
  const theme = useTheme()
  const appStyles = useAppStyles()
  const history = useHistory()
  const { checkPermissions } = useAppPermissions()

  const { entityId: paramEntityId, action } = useParams<{entityId: string, action: string}>()

  const getUndeliveredShipmentIds = useAppSelector(getUndeliveredShipmentIdsSelector)
  const getLateShipmentIds = useAppSelector(getLateShipmentIdsSelector)
  const getShipmentIdsMatchingSearch = useAppSelector(getShipmentIdsMatchingSearchSelector)
  const getDelayedShipmentIds = useAppSelector(getDelayedShipmentIdsSelector)
  const getDeliveredShipmentIds = useAppSelector(getDeliveredShipmentIdsSelector)
  const getPendingShipmentIds = useAppSelector(getPendingShipmentIdsSelector)

  const filters: any = useShipmentFiltersSelector()
  const shipments = useShipmentsSelector()

  const [openAdd, setOpenAdd] = useState(false)
  const [selectedSchemaId, setSelectedSchemaId] = useState<string>(null)

  const filteredShipments = useMemo(() => {
    let ids = _.map(shipments, shipment => shipment.id)
    if (filters.state === 'late') {
      ids = _.intersection(ids, getLateShipmentIds())
    } else if (filters.state === 'in_transit') {
      ids = _.intersection(ids, getUndeliveredShipmentIds())
    } else if (filters.state === 'delayed') {
      ids = _.intersection(ids, getDelayedShipmentIds())
    } else if (filters.state === 'delivered') {
      ids = _.intersection(ids, getDeliveredShipmentIds())
    } else if (filters.state === 'pending') {
      ids = _.intersection(ids, getPendingShipmentIds())
    }

    if (filters.search) {
      ids = _.intersection(ids, getShipmentIdsMatchingSearch(filters.search))
    }

    return _.filter(shipments, shipment => _.includes(ids, shipment.id))
  }, [filters.search, filters.state, getDelayedShipmentIds, getDeliveredShipmentIds, getLateShipmentIds, getPendingShipmentIds, getShipmentIdsMatchingSearch, getUndeliveredShipmentIds, shipments])

  const openView = useMemo(() => {
    return Boolean(selectedSchemaId && !action)
  }, [action, selectedSchemaId])

  useEffect(() => {
    setSelectedSchemaId(paramEntityId)
  }, [paramEntityId])

  const handleOnCloseView = useCallback(() => {
    history.push('/shipment-management/shipments')
  }, [history])

  return (
    <AppTabPanel value={'shipments'}>
      <DataGridProvider>
    <Box className={appStyles.tabContent}>
      <AppToolbar>
        <ExportShipmentsButton />
        <ShipmentFilters />
        <Box flex={1} />
        {checkPermissions(AppPermission.ShipmentAdd) && <Button variant={'contained'} color={'primary'} onClick={() => setOpenAdd(true)}>Add Shipment</Button>}
      </AppToolbar>
      <Paper style={{ height: 'calc(100vh - 200px)', padding: theme.spacing(1) }}>
        <ShipmentsDataGrid entities={filteredShipments} />
      </Paper>
      <ShipmentAddDrawer
        open={openAdd}
        onClose={shipment => {
          setOpenAdd(false)
          if (shipment?.id) {
            history.push(`/shipment-management/shipments/${shipment?.id}`)
          } else {
            history.push('/shipment-management/shipments')
          }
        }}
      />
      <ShipmentViewDrawer
        open={openView}
        schemaId={selectedSchemaId}
        onClose={handleOnCloseView}
      />
      {checkPermissions(AppPermission.ShipmentEdit) && <ShipmentEditDrawer
        open={Boolean(selectedSchemaId) && action === 'edit'}
        schemaId={selectedSchemaId}
        onClose={() => history.push(`/shipment-management/shipments/${selectedSchemaId}`)}
      />}
    </Box>
    </DataGridProvider>
    </AppTabPanel>
  )
}
