import { Type } from 'class-transformer'
import {
  IsBoolean,
  IsEmail,
  IsNotEmpty,
  IsOptional,
  IsString,
  IsUUID,
} from 'class-validator'
import _ from 'lodash'
import { BaseSchema } from './BaseSchema'

export class LabContactSchema extends BaseSchema {
  /**
   * Lab Contact ID
   */
  @IsOptional()
  @IsUUID()
  id: string

  /**
   * Lab ID
   */
  @IsNotEmpty()
  @IsUUID()
  labId: string

  /**
   * Name
   */
  @IsNotEmpty()
  @IsString()
  name: string = null

  /**
   * Email
   */
  @IsNotEmpty()
  @IsEmail()
  email: string = null

  /**
   * Location
   */
  @Type(() => Boolean)
  @IsBoolean()
  @IsNotEmpty()
  isDefault: boolean = false

  /**
   * Location
   */
  @Type(() => Boolean)
  @IsBoolean()
  @IsNotEmpty()
  isEnabled: boolean = true

  /**
   * Date created
   */
  @Type(() => Date)
  created: Date

  /**
   * Updated by
   */
  createdBy: string

  /**
   * Date updated
   */
  @Type(() => Date)
  updated: Date

  /**
   * Updated by
   */
  updatedBy: string

  /**
   * Date deleted
   */
  @Type(() => Date)
  deleted: Date

  /**
   * Deleted by
   */
  deletedBy: string

  /**
   * Construct
   * @param data
   */
  constructor (data?: Partial<LabContactSchema>) {
    super()
    _.assign(this, data)
  }
}
