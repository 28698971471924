import React from 'react'
import { EntityAvatar } from '../../../Components/EntityAvatar'
import { useUserSelector } from '../Hooks/useUserSelector'

interface UserAvatarProps {
  userId: string
  size: 'small' | 'medium' | 'large'
}

export const UserAvatar: React.FC<UserAvatarProps> = ({
  userId,
  size,
}) => {
  const entity = useUserSelector(userId)
  return (
    <EntityAvatar text={entity?.getFullName() + entity?.id} size={size} />
  )
}
