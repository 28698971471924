import { createAsyncThunk } from '@reduxjs/toolkit'
import { APIBase } from '../Services/API/APIBase'
import { ServiceLocator } from '../Services/ServiceLocator'
import { Class } from 'type-fest'

export default function createFetchPaginatedEntitiesThunk<S> (
  storeName: string,
  API: Class<APIBase<S>>,
) {
  return createAsyncThunk(
    `${storeName}/fetchEntities`,
    async (params?: any) => {
      let entities: S[] = []
      try {
        const response = await ServiceLocator.resolve(
          API,
        ).fetchPaginatedEntityList({ limit: 10000 })
        entities = response.pageItems
      } catch (err) {
        console.log(`${storeName}/fetchEntities`, err)
      }
      return entities
    },
  )
}
