import React, { useCallback } from 'react'
import { useSnackbarMessages } from '../../../Components/AppSnackbar/Hooks/useSnackbarMessages'
import { DeleteIconButton } from '../../../Components/Buttons/DeleteIconButton'
import { AppPermission } from '../../../Hooks/useAppPermissions'
import { DelaySchema } from '../../../Schema/DelaySchema'
import { useDeleteDelay } from '../Hooks/useDeleteDelay'

interface DeleteDelayButtonProps {
  schema: DelaySchema
  onDeleted?: () => void
}

export const DeleteDelayButton: React.FC<DeleteDelayButtonProps> = ({
  schema,
  onDeleted = () => {},
}) => {
  const { addSuccessMessage, addErrorMessage } = useSnackbarMessages()
  const { deleteDelay, processing, errorMessage } = useDeleteDelay()

  const handleOnDelete = useCallback(() => {
    deleteDelay(schema.id).then(() => {
      addSuccessMessage('The delay deleted successfully.')
      onDeleted()
    }).catch(() => {
      addErrorMessage(errorMessage)
    })
  }, [schema?.id, addSuccessMessage, addErrorMessage, deleteDelay, errorMessage, onDeleted])

  return (
    <DeleteIconButton permissions={[AppPermission.DelayDelete]} onDelete={handleOnDelete} disabled={processing} />
  )
}
