import { Grid } from '@material-ui/core'
import React, { useCallback } from 'react'
import { SchemaAutocompleteInput } from '../../../Components/Inputs/Schema/SchemaAutocompleteInput'
import { SchemaNumberInput } from '../../../Components/Inputs/Schema/SchemaNumberInput'
import { SchemaSwitchInput } from '../../../Components/Inputs/Schema/SchemaSwitchInput'
import { SchemaTextInput } from '../../../Components/Inputs/Schema/SchemaTextInput'
import { ContainerSchema } from '../../../Schema/ContainerSchema'

interface ContainerFormProps {
  schema: ContainerSchema
  onChange: (schema: ContainerSchema) => void
}

export const ContainerForm: React.FC<ContainerFormProps> = ({
  schema,
  onChange,
}) => {
  const handleOnSchemaChanged = useCallback((value: any, field: string) => {
    schema.setValue(field, value)
    schema.generateDimensionalLabel()
    schema.calculateDimensionalWeight()
    onChange(schema)
  }, [onChange, schema])

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <SchemaTextInput
          label={'Name'}
          placeholder={'Enter a name'}
          schema={schema}
          field={'name'}
          required
          onChange={handleOnSchemaChanged}
        />
      </Grid>
      <Grid item xs={12}>
        <SchemaNumberInput
          label={'Length'}
          placeholder={'Enter length'}
          schema={schema}
          field={'length'}
          required
          onChange={handleOnSchemaChanged}
        />
      </Grid>
      <Grid item xs={12}>
      <SchemaNumberInput
          label={'Width'}
          placeholder={'Enter width'}
          schema={schema}
          field={'width'}
          required
          onChange={handleOnSchemaChanged}
        />
      </Grid>
      <Grid item xs={12}>
        <SchemaNumberInput
          label={'Height'}
          placeholder={'Enter height'}
          schema={schema}
          field={'height'}
          required
          onChange={handleOnSchemaChanged}
        />
      </Grid>
      <Grid item xs={12}>
        <SchemaTextInput
          helperText={'Auto-generated'}
          label={'Dimensional Label'}
          schema={schema}
          field={'dimensionalLabel'}
          onChange={() => {}}
        />
      </Grid>
      <Grid item xs={12}>
        <SchemaTextInput
          helperText={'Auto-generated'}
          label={'Dimensional Weight'}
          schema={schema}
          field={'dimensionalWeight'}
          onChange={() => {}}
        />
      </Grid>
    </Grid>
  )
}
