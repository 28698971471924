import React, { useCallback, useState } from 'react'
import { AppDrawer, AppDrawerToolbar, AppDrawerContent, AppDrawerActions } from '../../Components/AppDrawer'
import { UserSchema } from '../../Schema/UserSchema'
import { UserForm } from './Components/UserForm'
import { v4 as uuid } from 'uuid'
import { Box, Button, Paper, Typography } from '@material-ui/core'
import { useAppStyles } from '../../Hooks/useAppStyles'
import { useAddUser } from './Hooks/useAddUser'
import { useSnackbarMessages } from '../../Components/AppSnackbar/Hooks/useSnackbarMessages'

interface UserAddDrawerProps {
  open: boolean
  onClose: () => void,
}

export const UserAddDrawer: React.FC<UserAddDrawerProps> = ({
  open,
  onClose,
}) => {
  const { addSuccessMessage, addErrorMessage } = useSnackbarMessages()
  const appStyles = useAppStyles()
  const { addUser, processing } = useAddUser()

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [hash, setHash] = useState('')
  const [user, setUser] = React.useState(new UserSchema())

  const handleOnSchemaChanged = useCallback((schema: UserSchema) => {
    setHash(uuid())
  }, [])

  const handleOnSave = useCallback(() => {
    if (user.validate()) {
      addUser(user).then(() => {
        addSuccessMessage('User created successfully.')
        setUser(new UserSchema())
        onClose()
      }).catch(() => {
        addErrorMessage('Failed to create a user.')
      })
    }
    setHash(uuid())
  }, [user, addUser, onClose, addSuccessMessage, addErrorMessage])

  return (
    <AppDrawer
      width={400}
      open={open}
      onClose={onClose}
    >
      <AppDrawerToolbar>
        <Typography variant={'h5'}>Add User</Typography>
      </AppDrawerToolbar>
      <AppDrawerContent>
        <Box className={appStyles.content}>
          <Paper className={appStyles.content}>
            <UserForm schema={user} onChange={handleOnSchemaChanged} />
          </Paper>
        </Box>
      </AppDrawerContent>
      <AppDrawerActions>
        <Button disabled={processing} color={'primary'} onClick={handleOnSave}>Save</Button>
      </AppDrawerActions>
    </AppDrawer>
  )
}
