import { colors } from '@material-ui/core'

const palette = {
  primary: {
    main: '#186EAD',
    contrastText: '#ffffff',
  },
  secondary: {
    main: '#FF2847',
  },
  text: {
    primary: colors.grey[900],
    secondary: colors.grey[600],
    // link: colors.blue[600],
  },
  icon: colors.grey[600],
  background: {
    default: '#F4F6F8',
  },
}

export default palette
