import React from 'react'
import { EntityTag } from '../../../Components/EntityTag'
import { useLabSelector } from '../../Labs/Hooks/useLabSelector'
import { useLabContactSelector } from '../Hooks/useLabContactSelector'

interface LabContactTagProps {
  labContactId: string
  avatarSize?: 'small' | 'medium' | 'large'
  button?: true
  onClick?: Function
  showAvatar?: boolean
}

export const LabContactTag: React.FC<LabContactTagProps> = ({
  labContactId,
  avatarSize,
  button,
  onClick = () => {},
  showAvatar = false,
}) => {
  const entity = useLabContactSelector(labContactId)
  const lab = useLabSelector(entity?.labId)

  return (
    <EntityTag
      onClick={event => onClick(event)}
      primary={entity?.name}
      secondary={entity?.email}
      avatarSize={avatarSize}
      showAvatar={showAvatar}
    />
  )
}
