import { Box, Button, Paper, useTheme } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router'
import { AppTabPanel } from '../../Components/AppTabs/AppTabPanel'
import { useAppTabs } from '../../Components/AppTabs/useAppTabs'
import { AppToolbar } from '../../Components/AppToolbar'
import { AppPermission, useAppPermissions } from '../../Hooks/useAppPermissions'
import { useAppStyles } from '../../Hooks/useAppStyles'
import { ShipmentItemsDataGrid } from './Components/ShipmentItemsDataGrid'
import { useShipmentItemsSelector } from './Hooks/useShipmentItemsSelector'
import { ShipmentItemAddDrawer } from './ShipmentItemAddDrawer'
import { ShipmentItemEditDrawer } from './ShipmentItemEditDrawer'

export const ShipmentItems: React.FC = () => {
  const theme = useTheme()
  const appStyles = useAppStyles()
  const appTabs = useAppTabs()
  const history = useHistory()
  const { checkPermissions } = useAppPermissions()

  const { entityId: paramEntityId } = useParams<{entityId: string}>()

  const entities = useShipmentItemsSelector()

  const [openAdd, setOpenAdd] = useState(false)
  const [editSchemaId, setEditSchemaId] = useState<string>(null)

  useEffect(() => {
    setEditSchemaId(paramEntityId)
  }, [paramEntityId])

  useEffect(() => {
    if (appTabs) {
      // appTabs.select('shipmentItems')
    }
  }, [appTabs])

  return (
    <AppTabPanel value={'items'}>
    <Box className={appStyles.tabContent}>
      <AppToolbar>
        <Box flex={1} />
      </AppToolbar>
      <Paper style={{ height: 'calc(100vh - 200px)', padding: theme.spacing(1) }}>
        <ShipmentItemsDataGrid entities={entities} />
      </Paper>
      <ShipmentItemAddDrawer
        open={openAdd}
        onClose={() => setOpenAdd(false)}
      />
      {checkPermissions(AppPermission.ShipmentEdit) && <ShipmentItemEditDrawer
        open={Boolean(editSchemaId)}
        schemaId={editSchemaId}
        onClose={() => history.push('/shipment-management/items')}
      />}
    </Box>
    </AppTabPanel>
  )
}
