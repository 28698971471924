import { useCallback, useState } from 'react'

import { v4 as uuid } from 'uuid'

import { useMounted } from '../../../Hooks/useMounted'
import { useAppDispatch } from '../../../Hooks/useAppDispatch'
import { ServicesActions } from '../StoreAdapter'
import { ServiceSchema } from '../../../Schema/ServiceSchema'
import { ServiceLocator } from '../../../Services/ServiceLocator'
import { ServicesAPI } from '../../../Services/API/ServicesAPI'

export const useAddService = () => {
  const dispatch = useAppDispatch()
  const mounted = useMounted()

  const [processing, setProcessing] = useState(false)
  const [error, setError] = useState(false)
  const [errorMessage, setErrorMessage] = useState<string>(null)

  const addService = useCallback(
    async (schema: ServiceSchema): Promise<ServiceSchema> => {
      setProcessing(true)
      return ServiceLocator.resolve(ServicesAPI)
        .createEntity(schema)
        .then((schema) => {
          dispatch(ServicesActions.addOne(schema))
          if (mounted.current) {
            setProcessing(false)
            setError(true)
            setErrorMessage(null)
          }
          return schema
        })
        .catch((err) => {
          if (mounted.current) {
            setProcessing(false)
            setError(true)
            setErrorMessage(err.message)
            throw err
          }
          return null
        })
    },
    [dispatch, mounted],
  )

  return {
    processing,
    error,
    errorMessage,
    addService,
  }
}
