import React, { useCallback, useState } from 'react'
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles'
import _ from 'lodash'
import { IconButton, InputAdornment, TextField } from '@material-ui/core'
import Close from '@material-ui/icons/Close'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: '2px 4px',
      display: 'flex',
      alignItems: 'center',
      width: 400,
    },
    input: {
      marginLeft: theme.spacing(1),
      flex: 1,
    },
    iconButton: {
      padding: 10,
    },
    divider: {
      height: 28,
      margin: 4,
    },
  }),
)

interface SearchTextFieldProps {
  style?: any;
  placeholder?: string;
  onChange?: (value: string) => void;
  value?: string;
}

const debounceOnChanged = _.debounce((onChange: Function, search: string) => {
  onChange(search)
}, 500)

export const SearchTextField: React.FC<SearchTextFieldProps> = ({
  style,
  placeholder,
  onChange,
  value,
}) => {
  const classes = useStyles({})

  const [text, setText] = useState(value || '')

  const handleOnChange = useCallback(
    (text: string) => {
      if (onChange) {
        debounceOnChanged.cancel()
        debounceOnChanged(onChange, text)
      }
    },
    [onChange],
  )

  const handleOnCear = useCallback(
    () => {
      if (onChange) {
        setText('')
        onChange('')
      }
    },
    [onChange],
  )

  return (
      <TextField
        autoComplete={'off'}
        style={style}
        value={text}
        className={classes.input}
        placeholder={placeholder}
        onChange={event => {
          setText(event.target.value)
          handleOnChange(event.target.value)
        }}
        InputProps={{
          endAdornment: <InputAdornment position="end">
            <IconButton size={'small'} onClick={handleOnCear} >
              <Close fontSize={'inherit'} />
            </IconButton>
          </InputAdornment>,
        }}

      />
  )
}
