import { Box, Button, Paper, useTheme } from '@material-ui/core'
import _ from 'lodash'
import React, { useEffect, useMemo, useState } from 'react'
import { useHistory, useParams } from 'react-router'
import { useAppTabs } from '../../Components/AppTabs/useAppTabs'
import { AppToolbar } from '../../Components/AppToolbar'
import { AppPermission, useAppPermissions } from '../../Hooks/useAppPermissions'
import { useAppStyles } from '../../Hooks/useAppStyles'
import { LabContactsDataGrid } from './Components/LabContactsDataGrid'
import { useLabContactsSelector } from './Hooks/useLabContactsSelector'
import { LabContactAddDrawer } from './LabContactAddDrawer'
import { LabContactViewDrawer } from './LabContactViewDrawer'
import { LabContactEditDrawer } from './LabContactEditDrawer'

export const LabContacts: React.FC = () => {
  const theme = useTheme()
  const appStyles = useAppStyles()
  const appTabs = useAppTabs()
  const history = useHistory()
  const { checkPermissions } = useAppPermissions()

  const { labContactId: paramLabContactId, action } = useParams<{labContactId: string, action: string}>()

  const labContactsState = useLabContactsSelector()

  const labContacts = useMemo(() => {
    return _.map(labContactsState)
  }, [labContactsState])

  const [openAdd, setOpenAdd] = useState(false)
  const [viewLabContactId, setViewLabContactId] = useState<string>(null)

  useEffect(() => {
    setViewLabContactId(paramLabContactId)
  }, [paramLabContactId])

  if (appTabs) {
    appTabs.select('labContacts')
  }

  return (
    <Box className={appStyles.tabContent}>
      <AppToolbar>
        <Box flex={1} />
        {checkPermissions(AppPermission.LabAdd) && <Button variant={'contained'} color={'primary'} onClick={() => setOpenAdd(true)}>Add Contact</Button>}
      </AppToolbar>
      <Paper style={{ height: 'calc(100vh - 200px)', padding: theme.spacing(1) }}>
        <LabContactsDataGrid labs={labContacts} />
      </Paper>
      <LabContactAddDrawer
        open={openAdd}
        onClose={() => setOpenAdd(false)}
      />
      <LabContactViewDrawer
        open={Boolean(viewLabContactId) && !action}
        labContactId={viewLabContactId}
        onClose={() => history.push('/lab-management/contacts')}
      />

      <LabContactEditDrawer
        open={Boolean(viewLabContactId) && action === 'edit'}
        labContactId={viewLabContactId}
        onClose={() => history.push(`/lab-management/contacts/${viewLabContactId}`)}
      />
    </Box>
  )
}
