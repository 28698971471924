import { Box, Button, createStyles, Grid, makeStyles, Typography } from '@material-ui/core'
import { IsEmail, IsNotEmpty } from 'class-validator'
import {
  useGoogleReCaptcha,
} from 'react-google-recaptcha-v3'
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { IsValidPassword } from './Utils'
import _ from 'lodash'
import { BaseSchema } from '../Schema/BaseSchema'
import { useLogin } from './Hooks/useLogin'
import { useSnackbarMessages } from '../Components/AppSnackbar/Hooks/useSnackbarMessages'
import { SchemaTextInput } from '../Components/Inputs/Schema/SchemaTextInput'

class LoginObject extends BaseSchema {
  @IsNotEmpty({ message: 'Invalid Email.' })
  @IsEmail()
  username: string

  @IsNotEmpty({ message: 'Invalid or missing password.' })
  @IsValidPassword({ message: 'Invalid Password.' })
  password: string

  constructor (data?: Partial<LoginObject>) {
    super()
    _.assign(this, data)
  }
}

export const Login: React.FC = () => {
  const history = useHistory()
  const styles = useStyles()
  const { addSuccessMessage, addErrorMessage } = useSnackbarMessages()

  const { login, processing } = useLogin()

  const { executeRecaptcha } = useGoogleReCaptcha()

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [hash, setHash] = useState('')
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [loginObj, setLoginObj] = useState(new LoginObject())

  const handleOnChange = (value: string, field: string) => {
    loginObj.setValue(field, value)
    setHash(loginObj.generateHash())
  }

  const handleOnLogin = () => {
    if (!loginObj || !loginObj.validate()) {
      setHash(loginObj?.generateHash())
      return
    }
    if (executeRecaptcha) {
      executeRecaptcha('Login').then(token => {
        login(loginObj.username, loginObj.password, token).then(tokens => {
          addSuccessMessage('Login successful.')
        }).catch(() => {
          addErrorMessage('Login failed. Please make sure you have entered correct email and password.')
        })
      }).catch(err => {
        console.log('captcha error', err)
      })
    }
    setHash(loginObj?.generateHash())
  }

  const handleOnSubmit = (event: React.FormEvent) => {
    event.preventDefault()
    handleOnLogin()
  }

  return (
    <Box className={styles.container}>
      <form onSubmit={e => handleOnSubmit(e)}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <SchemaTextInput
              disabled={processing}
              label={'Email'}
              schema={loginObj}
              field={'username'}
              onChange={handleOnChange}
            />
          </Grid>
          <Grid item xs={12}>
            <SchemaTextInput
              disabled={processing}
              type={'password'}
              label={'Password'}
              schema={loginObj}
              field={'password'}
              onChange={handleOnChange}
            />
            <Button
              disabled={processing}
              color={'primary'}
              onClick={() => history.push('/forgot-password')}
            >
            Forgot Password?
            </Button>
          </Grid>
        </Grid>
        <Box margin={1}>
          <Typography variant={'caption'}>*By clicking the "Login" button, you accept our Terms of Service and Privacy Policy.</Typography>
        </Box>
        <Button
          type={'submit'}
          disabled={processing}
          color={'primary'}
          fullWidth
          variant={'contained'}
        >
        Login
        </Button>
      </form>
    </Box>
  )
}

const useStyles = makeStyles(theme =>
  createStyles({
    container: {
      padding: theme.spacing(3),
    },
  }),
)
