import { createStyles, makeStyles } from '@material-ui/core'
import { GridCellParams, GridColDef, DataGrid, GridSortDirection, GridValueFormatterParams } from '@material-ui/data-grid'
import React from 'react'
import { useHistory } from 'react-router'
import { LabContactSchema } from '../../../Schema/LabContactSchema'
import { formatLocalDateTime } from '../../../Utils/DateFunctions'
import { LabTag } from '../../Labs/Components/LabTag'
import { LabContactTag } from './LabContactTag'

const columns: GridColDef[] = [
  {
    field: 'name',
    headerName: 'Name',
    width: 300,
    renderCell: (params: GridValueFormatterParams) => {
      return <LabContactTag labContactId={params.getValue(params.id, 'id')?.toString()} />
    },
  },
  {
    field: 'email',
    headerName: 'Email',
    width: 200,
  },
  {
    field: 'labId',
    headerName: 'Lab',
    width: 300,
    renderCell: (params: GridValueFormatterParams) => {
      return <LabTag labId={params.getValue(params.id, 'labId')?.toString()} />
    },
  },
  {
    field: 'updated',
    headerName: 'Updated',
    width: 180,
    type: 'dateTime',
    valueFormatter: (params: GridValueFormatterParams) => {
      return formatLocalDateTime(params.getValue(params.id, 'updated') as Date)
    },
  },
  {
    field: 'created',
    headerName: 'Created',
    width: 180,
    type: 'dateTime',
    valueFormatter: (params: GridValueFormatterParams) => {
      return formatLocalDateTime(params.getValue(params.id, 'created') as Date)
    },
  },
]

interface LabContactsDataGridProps {
  labs: LabContactSchema[]
}

export const LabContactsDataGrid: React.FC<LabContactsDataGridProps> = ({
  labs,
}) => {
  const history = useHistory()
  const styles = useStyles()

  const [sortModel, setSortModel] = React.useState([
    {
      field: 'name',
      sort: 'asc' as GridSortDirection,
    },
  ])

  const handleOnCellClick = (params: GridCellParams) => {
    /* if (_.includes(['status', 'assigneeId'], cellParams.field)) {
      return
    } */
    history.push(`/lab-management/contacts/${params.getValue(params.id, 'id')}`)
  }

  return (
    <DataGrid
      sortModel={sortModel}
      onSortModelChange={(model) => setSortModel(model)}
      className={styles.grid}
      columns={columns}
      rows={labs}
      showColumnRightBorder
      onCellClick={handleOnCellClick} />
  )
}

const useStyles = makeStyles(theme =>
  createStyles({
    grid: {
      fontSize: 14,
    },
  }),
)
