import { Box, ButtonGroup, makeStyles, Popover, Typography } from '@material-ui/core'
import _ from 'lodash'
import React, { useMemo } from 'react'
import { CopyToClipboardButton } from '../../../Components/CopyToClipboardButton'
import { ShipmentItemSchema } from '../../../Schema/ShipmentItemSchema'
import { ShipmentItemExternalTrackingButton } from '../../ShipmentItems/Components/ShipmentItemExternalTrackingButton'
import { useShipmentItemsForShipmentSelector } from '../../ShipmentItems/Hooks/useShipmentItemsForShipmentSelector'

const useStyles = makeStyles((theme) => ({
  popover: {
    pointerEvents: 'none',
  },
  paper: {
    pointerEvents: 'auto',
    padding: theme.spacing(1),
    minWidth: 220,
  },
}))

interface ShipmentItemTrackingNumbersProps {
  shipmentId: string
}

export const ShipmentItemTrackingNumbers: React.FC<ShipmentItemTrackingNumbersProps> = ({
  shipmentId,
}) => {
  const classes = useStyles()
  const shipmentItems = useShipmentItemsForShipmentSelector(shipmentId)

  const [anchorEl, setAnchorEl] = React.useState(null)

  const shipmentItemsWithTrackingNumbers = useMemo(() => {
    return _.filter(shipmentItems, item => !_.isEmpty(item.carrierTrackingNumber))
  }, [shipmentItems])

  const trackingNumbers = useMemo(() => {
    return _.map(shipmentItemsWithTrackingNumbers, item => item.carrierTrackingNumber)
  }, [shipmentItemsWithTrackingNumbers])

  const trackingNumbersString = useMemo(() => {
    if (_.isEmpty(trackingNumbers)) {
      return null
    }
    return trackingNumbers.join(', ')
  }, [trackingNumbers])

  const handlePopoverOpen = (event: any) => {
    if (!anchorEl && !_.isEmpty(trackingNumbers)) {
      setAnchorEl(event.currentTarget)
    } else {
      setAnchorEl(null)
    }
  }

  const handlePopoverClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)

  return (
    <Box>
      <Typography
        aria-owns={open ? 'mouse-over-popover' : undefined}
        aria-haspopup="true"
        style={{ minWidth: 0, fontSize: 'inherit' }}
        onMouseEnter={handlePopoverOpen}
        // onMouseLeave={handlePopoverClose}
      >({trackingNumbers.length}){trackingNumbersString}
      </Typography>
      {anchorEl && <Popover
        id="mouse-over-popover"
        className={classes.popover}
        classes={{
          paper: classes.paper,
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        /* transformOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }} */
        onMouseLeave={handlePopoverClose}
        onClose={handlePopoverClose}
      >
        {_.map(shipmentItemsWithTrackingNumbers, item => (
          <Box display={'flex'} key={item.id}>
            <Typography>{item.carrierTrackingNumber}</Typography>
            <ButtonGroup>
              <CopyToClipboardButton buttonSize={'small'} size={18} text={item.carrierTrackingNumber} />
              <ShipmentItemExternalTrackingButton buttonSize={'small'} schema={item} />
            </ButtonGroup>
          </Box>
        ))}
      </Popover>}
    </Box>
  )
}
