import { Box, Button, Paper, useTheme } from '@material-ui/core'
import _ from 'lodash'
import React, { useEffect, useMemo, useState } from 'react'
import { useHistory, useParams } from 'react-router'
import { useAppTabs } from '../../Components/AppTabs/useAppTabs'
import { AppToolbar } from '../../Components/AppToolbar'
import { AppPermission, useAppPermissions } from '../../Hooks/useAppPermissions'
import { useAppStyles } from '../../Hooks/useAppStyles'
import { ConsigneesDataGrid } from './Components/ConsigneesDataGrid'
import { useConsigneesSelector } from './Hooks/useConsigneesSelector'
import { ConsigneeAddDrawer } from './ConsigneeAddDrawer'
import { ConsigneeEditDrawer } from './ConsigneeEditDrawer'

export const Consignees: React.FC = () => {
  const theme = useTheme()
  const appStyles = useAppStyles()
  const appTabs = useAppTabs()
  const history = useHistory()
  const { checkPermissions } = useAppPermissions()

  const { entityId: paramEntityId } = useParams<{entityId: string}>()

  const services = useConsigneesSelector()

  const [openAdd, setOpenAdd] = useState(false)
  const [editSchemaId, setEditSchemaId] = useState<string>(null)

  useEffect(() => {
    setEditSchemaId(paramEntityId)
  }, [paramEntityId])

  useEffect(() => {
    if (appTabs) {
      appTabs.select('consignees')
    }
  }, [appTabs])

  return (
    <Box className={appStyles.tabContent}>
      <AppToolbar>
        <Box flex={1} />
        {checkPermissions(AppPermission.ConsigneeAdd) && <Button variant={'contained'} color={'primary'} onClick={() => setOpenAdd(true)}>Add Consignee</Button>}
      </AppToolbar>
      <Paper style={{ height: 'calc(100vh - 200px)', padding: theme.spacing(1) }}>
        <ConsigneesDataGrid entities={services} />
      </Paper>
      <ConsigneeAddDrawer
        open={openAdd}
        onClose={() => setOpenAdd(false)}
      />
      {checkPermissions(AppPermission.ConsigneeEdit) && <ConsigneeEditDrawer
        open={Boolean(editSchemaId)}
        schemaId={editSchemaId}
        onClose={() => history.push('/consignee-management/consignees')}
      />}
    </Box>
  )
}
