import React from 'react'

import MomentUtils from '@date-io/moment'
import {
  DateTimePicker,
  DatePicker,
  TimePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers'

import TimeIcon from '@material-ui/icons/AccessTime'
import CalendarIcon from '@material-ui/icons/CalendarToday'
import TodayIcon from '@material-ui/icons/Today'

import { InputAdornment } from '@material-ui/core'
import _ from 'lodash'
import { Moment } from 'moment'

const FieldTypeIconMap = {
  date: TimeIcon,
  time: TodayIcon,
  datetime: CalendarIcon,
}

const pickerMap = {
  date: DatePicker,
  time: TimePicker,
  datetime: DateTimePicker,
}

interface DateTimeInputProps {
  type?: 'date' | 'time' | 'datetime'
  className?: any
  label?: String
  error?: boolean
  value?: Date
  helperText?: string
  onChange: Function
  disabled?: boolean
  required?: boolean
}

export const DateTimeInput: React.FC<DateTimeInputProps> = ({
  type = 'datetime',
  className,
  label,
  error,
  value,
  helperText,
  onChange,
  disabled,
  required,
}) => {
  const PickerComponent = _.get(pickerMap, type)
  const InputIcon = _.get(FieldTypeIconMap, type)

  const handleOnChange = (value: Moment) => {
    onChange(value ? value.toDate() : null)
  }

  if (!PickerComponent) {
    return null
  }

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <PickerComponent
        variant="inline"
        className={className}
        required={required}
        clearable={true}
        disabled={disabled}
        style={{ width: '100%' }}
        error={error}
        helperText={helperText}
        value={value}
        label={label}
        onChange={handleOnChange}
        InputLabelProps={{
          shrink: true,
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <InputIcon />
            </InputAdornment>
          ),
        }}
      />
    </MuiPickersUtilsProvider>
  )
}
