import { isNumberString } from 'class-validator'
import _ from 'lodash'
import React from 'react'
import { BaseSchema } from '../../../Schema/BaseSchema'
import { SchemaTextInput } from './SchemaTextInput'

interface SchemaNumberInputProps {
  inputClassName?: any
  className?: any
  schema: BaseSchema
  label?: String
  field: string
  helperText?: string
  onChange: Function
  disabled?: boolean
  multiline?: boolean
  required?: boolean
  type?: string
  variant?: 'standard' | 'filled' | 'outlined'
  placeholder?: string
}

export const SchemaNumberInput: React.FC<SchemaNumberInputProps> = ({
  className,
  inputClassName,
  schema,
  field,
  label,
  helperText,
  onChange,
  disabled,
  multiline,
  required,
  type,
  variant,
  placeholder,
}) => {
  const handleOnChange = (value: string) => {
    if (_.isEmpty(value)) {
      onChange(null, field)
      return
    }
    if (isNumberString(value)) {
      onChange(Number(value), field)
    }
  }
  return (
    <SchemaTextInput
      field={field}
      schema={schema}
      inputClassName={inputClassName}
      className={className}
      disabled={disabled}
      label={label}
      helperText={helperText }
      onChange={handleOnChange}
      multiline={multiline}
      required={required}
      type={type}
      variant={variant}
      placeholder={placeholder}
    />
  )
}
