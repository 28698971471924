import React, { useCallback } from 'react'
import { useSnackbarMessages } from '../../../Components/AppSnackbar/Hooks/useSnackbarMessages'
import { DeleteIconButton } from '../../../Components/Buttons/DeleteIconButton'
import { AppPermission } from '../../../Hooks/useAppPermissions'
import { ChargeCodeSchema } from '../../../Schema/ChargeCodeSchema'
import { useDeleteChargeCode } from '../Hooks/useDeleteChargeCode'

interface DeleteChargeCodeButtonProps {
  schema: ChargeCodeSchema
  onDeleted?: () => void
}

export const DeleteChargeCodeButton: React.FC<DeleteChargeCodeButtonProps> = ({
  schema,
  onDeleted = () => {},
}) => {
  const { addSuccessMessage, addErrorMessage } = useSnackbarMessages()
  const { deleteChargeCode, processing, errorMessage } = useDeleteChargeCode()

  const handleOnDelete = useCallback(() => {
    deleteChargeCode(schema.id).then(() => {
      addSuccessMessage('The charge code deleted successfully.')
      onDeleted()
    }).catch(() => {
      addErrorMessage(errorMessage)
    })
  }, [schema?.id, addSuccessMessage, addErrorMessage, deleteChargeCode, errorMessage, onDeleted])

  return (
    <DeleteIconButton permissions={[AppPermission.ChargeCodeDelete]} onDelete={handleOnDelete} disabled={processing} />
  )
}
