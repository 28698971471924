import { ShipmentItemsSelectors } from './StoreAdapter'
import { createSelector } from '@reduxjs/toolkit'
import _, { memoize } from 'lodash'
import { AppRootState } from '../../AppState/AppStore'

export const getShipmentItemsForShipmentSelector = createSelector(
  ShipmentItemsSelectors.selectAll,
  (items) =>
    memoize((shipmentId: string) => {
      return _.filter(items, (item) => item.shipmentId === shipmentId)
    }),
)

export const getShipmentItemSelector = createSelector(
  (state: AppRootState) => state,
  (state) =>
    memoize((id: string) => {
      return ShipmentItemsSelectors.selectById(state, id)
    }),
)
