import React from 'react'
import { useCountryList } from '../../Hooks/useCountryList'
import { AutocompleteInput } from './AutocompleteInput'

interface CountryPickerProps {
  value?: string
  className?: any
  label?: string
  helperText?: string
  onChange: (value: string) => void
  disabled?: boolean
  required?: boolean
  variant?: 'standard' | 'filled' | 'outlined'
  placeholder?: string
  freeSolo?: boolean
  noOptionsText?: string
  clearOnBlur?: boolean
  error?: boolean
}

export const CountryPicker: React.FC<CountryPickerProps> = ({
  className,
  value,
  label,
  helperText,
  onChange,
  disabled,
  required,
  variant,
  placeholder,
  freeSolo,
  noOptionsText,
  clearOnBlur,
  error,
}) => {
  const entities = useCountryList()

  return (
    <AutocompleteInput
      value={value}
      className={className}
      label={label}
      helperText={helperText}
      onChange={value => onChange(value as string)}
      disabled={disabled}
      required={required}
      variant={variant}
      placeholder={placeholder}
      freeSolo={freeSolo}
      options={entities}
      noOptionsText={noOptionsText}
      clearOnBlur={clearOnBlur}
      error={error}
    />
  )
}
