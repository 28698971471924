import { Grid } from '@material-ui/core'
import React, { useCallback } from 'react'
import { SchemaAutocompleteInput } from '../../../Components/Inputs/Schema/SchemaAutocompleteInput'
import { SchemaTextInput } from '../../../Components/Inputs/Schema/SchemaTextInput'
import { LabSchema } from '../../../Schema/LabSchema'

interface LabFormProps {
  schema: LabSchema
  onChange: (schema: LabSchema) => void
}

export const LabForm: React.FC<LabFormProps> = ({
  schema,
  onChange,
}) => {
  const handleOnSchemaChanged = useCallback((value: any, field: string) => {
    schema.setValue(field, value)
    onChange(schema)
  }, [onChange, schema])

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <SchemaTextInput
          label={'Name'}
          placeholder={'Enter a name'}
          schema={schema}
          field={'name'}
          onChange={handleOnSchemaChanged}
        />
      </Grid>
    </Grid>
  )
}
