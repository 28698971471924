import { APIBase } from './APIBase'
import { Singleton } from 'typescript-ioc'
import { UserSchema } from '../../Schema/UserSchema'
import { UsersAPIClient } from './Clients/UsersAPIClient'

@Singleton
export class UsersAPI extends APIBase<UserSchema> {
  constructor () {
    super(UserSchema, UsersAPIClient)
    this.resourcePath = 'users'
  }

  protected buildEndpoint (endpoint: string) {
    return `${this.apiInstance.getVersion()}${endpoint}`
  }

  async fetchProfile (): Promise<UserSchema> {
    const endpoint = this.buildEndpoint('/my/profile')
    return this.apiInstance.get(endpoint).then((result) => {
      return result.data
    })
  }
}
