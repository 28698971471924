import { Box, IconButton, Menu, MenuItem, Typography } from '@material-ui/core'
import { ArrowDropDownRounded } from '@material-ui/icons'
import React, { useCallback, useRef } from 'react'
import { useLogout } from '../Hooks/useLogout'
import { useProfile } from '../Hooks/useProfile'

export const UserProfile: React.FC = () => {
  const profile = useProfile()
  const menuButtonRef = useRef()
  const logout = useLogout()

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

  const handleLogout = useCallback(() => {
    if (logout) {
      logout()
    }
  }, [logout])

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleOpen = () => {
    setAnchorEl(menuButtonRef.current)
  }

  return (
    <>
    <Box display={'flex'} alignContent={'center'}>
      <Typography style={{ alignSelf: 'center' }}>{profile.firstName + ' ' + profile.lastName}</Typography>
      <IconButton ref={menuButtonRef} onClick={handleOpen}><ArrowDropDownRounded /></IconButton>
    </Box>
    <Menu
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        getContentAnchorEl={null}
      >
        <MenuItem onClick={handleLogout}>Logout</MenuItem>
      </Menu>
    </>
  )
}
