import { APIClientBase } from './Clients/APIClientBase'
import { Singleton } from 'typescript-ioc'
import { AuthAPIClient } from './Clients/AuthAPIClient'
import { ServiceLocator } from '../ServiceLocator'
import { UserSchema } from '../../Schema/UserSchema'

@Singleton
export class AuthAPI {
  apiInstance: APIClientBase

  constructor () {
    this.apiInstance = ServiceLocator.resolve(AuthAPIClient)
  }

  protected buildEndpoint (endpoint: string) {
    return `${this.apiInstance.getVersion()}${endpoint}`
  }

  async login (data: {
    email: string
    password: string
    challenge?: string
  }): Promise<{ accessToken: string; refreshToken: string }> {
    const endpoint = this.buildEndpoint('/auth/login')
    return this.apiInstance
      .post<{ accessToken: string; refreshToken: string }>(endpoint, data)
      .then((result) => {
        return result.data
      })
  }

  async resetPassword (email: string, challenge?: string): Promise<void> {
    const endpoint = this.buildEndpoint('/password/reset')
    return this.apiInstance
      .post(endpoint, { email: email, challenge: challenge })
      .then((result) => {
        return result.data
      })
  }

  async changePassword (data: {
    currentPassword: string
    password: string
  }): Promise<void> {
    const endpoint = this.buildEndpoint('/my/password')
    return this.apiInstance.post(endpoint, data).then((result) => {
      return result.data
    })
  }

  async changeForgottenPassword (data: {
    token: string
    password: string
    challenge?: string
  }): Promise<void> {
    const endpoint = this.buildEndpoint('/password/change')
    return this.apiInstance.post(endpoint, data).then((result) => {
      return result.data
    })
  }

  async register (data: {
    token: string
    password: string
    challenge?: string
  }): Promise<void> {
    const endpoint = this.buildEndpoint('/register')
    return this.apiInstance.post(endpoint, data).then((result) => {
      return result.data
    })
  }
}
