import { Box, Button, Paper, useTheme } from '@material-ui/core'
import _ from 'lodash'
import React, { useEffect, useMemo, useState } from 'react'
import { useHistory, useParams } from 'react-router'
import { useAppTabs } from '../../Components/AppTabs/useAppTabs'
import { AppToolbar } from '../../Components/AppToolbar'
import { useAppStyles } from '../../Hooks/useAppStyles'
import { CharegeCodesDataGrid } from './Components/ChargeCodesDataGrid'
import { useChargeCodesSelector } from './Hooks/useChargeCodesSelector'
import { ChargeCodeAddDrawer } from './ChargeCodeAddDrawer'
import { ChargeCodeEditDrawer } from './ChargeCodeEditDrawer'

export const ChargeCodes: React.FC = () => {
  const theme = useTheme()
  const appStyles = useAppStyles()
  const appTabs = useAppTabs()
  const history = useHistory()

  const { entityId: paramEntityId } = useParams<{entityId: string}>()

  const chargeCodes = useChargeCodesSelector()

  const [openAdd, setOpenAdd] = useState(false)
  const [editSchemaId, setEditSchemaId] = useState<string>(null)

  useEffect(() => {
    setEditSchemaId(paramEntityId)
  }, [paramEntityId])

  useEffect(() => {
    if (appTabs) {
      appTabs.select('chargeCodes')
    }
  }, [appTabs])

  return (
    <Box className={appStyles.tabContent}>
      <AppToolbar>
        <Box flex={1} />
        <Button variant={'contained'} color={'primary'} onClick={() => setOpenAdd(true)}>Add Charge Code</Button>
      </AppToolbar>
      <Paper style={{ height: 'calc(100vh - 200px)', padding: theme.spacing(1) }}>
        <CharegeCodesDataGrid entities={chargeCodes} />
      </Paper>
      <ChargeCodeAddDrawer
        open={openAdd}
        onClose={() => setOpenAdd(false)}
      />
      <ChargeCodeEditDrawer
        open={Boolean(editSchemaId)}
        schemaId={editSchemaId}
        onClose={() => history.push('/settings/charge-codes')}
      />
    </Box>
  )
}
