import React, { useCallback, useState } from 'react'
import { AppDrawer, AppDrawerToolbar, AppDrawerContent, AppDrawerActions } from '../../Components/AppDrawer'
import { LabForm } from './Components/LabForm'
import { v4 as uuid } from 'uuid'
import { Box, Button, Paper, Typography } from '@material-ui/core'
import { useAppStyles } from '../../Hooks/useAppStyles'
import { useAddLab } from './Hooks/useAddLab'
import { LabSchema } from '../../Schema/LabSchema'
import { useSnackbarMessages } from '../../Components/AppSnackbar/Hooks/useSnackbarMessages'

interface LabAddDrawerProps {
  open: boolean
  onClose: (lab?: LabSchema) => void,
  defaultData?: Partial<LabSchema>
}

export const LabAddDrawer: React.FC<LabAddDrawerProps> = ({
  open,
  onClose,
  defaultData,
}) => {
  const { addSuccessMessage, addErrorMessage } = useSnackbarMessages()
  const appStyles = useAppStyles()
  const { addLab, processing } = useAddLab()

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [hash, setHash] = useState('')
  const [lab, setLab] = React.useState(new LabSchema(defaultData))

  const handleOnSchemaChanged = useCallback((schema: LabSchema) => {
    setHash(uuid())
  }, [])

  const handleOnSave = useCallback(() => {
    if (lab.validate()) {
      addLab(lab).then(() => {
        setLab(new LabSchema(defaultData))
        addSuccessMessage('Lab created successfully.')
        onClose(lab)
      }).catch(() => {
        addErrorMessage('Failed to create the Lab.')
      })
    }
    setHash(uuid())
  }, [lab, addLab, onClose, addSuccessMessage, addErrorMessage, defaultData])

  return (
    <AppDrawer
      width={400}
      open={open}
      onClose={onClose}
    >
      <AppDrawerToolbar>
        <Typography variant={'h5'}>Add Lab</Typography>
      </AppDrawerToolbar>
      <AppDrawerContent>
        <Box className={appStyles.content}>
          <Paper className={appStyles.content}>
            <LabForm schema={lab} onChange={handleOnSchemaChanged} />
          </Paper>
        </Box>
      </AppDrawerContent>
      <AppDrawerActions>
        <Button disabled={processing} color={'primary'} onClick={handleOnSave}>Save</Button>
      </AppDrawerActions>
    </AppDrawer>
  )
}
