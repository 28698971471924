import { useAppSelector } from '../../../Hooks/useAppSelector'
import { DelaysSelectors } from '../StoreAdapter'

export const useDelaySelector = (entityId: string) => {
  const entityState = useAppSelector((state) =>
    DelaysSelectors.selectById(state, entityId),
  )

  return entityState
}
