import React, { useCallback, useState, useEffect } from 'react'
import { AppDrawer, AppDrawerToolbar, AppDrawerContent, AppDrawerActions } from '../../Components/AppDrawer'
import { v4 as uuid } from 'uuid'
import { Box, Button, Paper, Typography } from '@material-ui/core'
import { useAppStyles } from '../../Hooks/useAppStyles'
import { useAddShipmentItem } from './Hooks/useAddShipmentItem'
import { ShipmentItemSchema } from '../../Schema/ShipmentItemSchema'
import { ShipmentItemForm } from './Components/ShipmentItemForm'
import { usePrevious } from '../../Hooks/usePrevious'

interface ShipmentItemAddDrawerProps {
  open: boolean
  onClose: (schema?: ShipmentItemSchema) => void,
  defaultData?: Partial<ShipmentItemSchema>
}

export const ShipmentItemAddDrawer: React.FC<ShipmentItemAddDrawerProps> = ({
  open,
  onClose,
  defaultData,
}) => {
  const appStyles = useAppStyles()

  const { addShipmentItem, processing } = useAddShipmentItem()

  const [hash, setHash] = useState('')
  const [schema, setSchema] = React.useState<ShipmentItemSchema>(null)
  const previousOpen = usePrevious(open)

  useEffect(() => {
    if (!previousOpen && open) {
      setSchema(new ShipmentItemSchema(defaultData))
    }
  }, [defaultData, open, previousOpen])

  const handleOnSchemaChanged = useCallback((schema: ShipmentItemSchema) => {
    setHash(uuid())
  }, [])

  const handleOnSave = useCallback(() => {
    if (schema.validate()) {
      addShipmentItem(schema).then(result => {
        setSchema(new ShipmentItemSchema(defaultData))
        onClose(result)
      }).catch(() => {

      })
    }
    setHash(uuid())
  }, [schema, addShipmentItem, onClose, defaultData])

  const handleOnReset = useCallback(() => {
    setSchema(new ShipmentItemSchema(defaultData))
  }, [defaultData])

  return (
    <AppDrawer
      width={400}
      open={open}
      onClose={onClose}
    >
      {schema && (
        <>
        <AppDrawerToolbar>
          <Typography variant={'h5'} className={appStyles.flexOne}>Add Shipment Item</Typography>
          <Button onClick={handleOnReset} color={'primary'}>Reset</Button>
        </AppDrawerToolbar>
        <AppDrawerContent>
          <Box className={appStyles.content}>
            <Paper className={appStyles.content}>
              <ShipmentItemForm schema={schema} onChange={handleOnSchemaChanged} />
            </Paper>
          </Box>
        </AppDrawerContent>
        <AppDrawerActions>
          <Button disabled={processing} color={'primary'} onClick={handleOnSave}>Save</Button>
        </AppDrawerActions>
        </>
      )}
    </AppDrawer>
  )
}
