/**
 * An adapter and slice definition for the redux store
 */

import {
  createEntityAdapter,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit'
import { AppRootState } from '../../AppState/AppStore'
import createFetchEntityThunk from '../../AppState/createFetchEntityThunk'
import createFetchPaginatedEntitiesThunk from '../../AppState/createFetchPaginatedEntitiesThunk'
import createUpdateManyReducer from '../../AppState/createUpdateManyReducer'
import createUpdateOneReducer from '../../AppState/createUpdateOneRecuder'
import { LabContactSchema } from '../../Schema/LabContactSchema'
import { LabContactsAPI } from '../../Services/API/LabContactsAPI'

const STORE_NAME = 'labContacts'

const fetchEntities = createFetchPaginatedEntitiesThunk<LabContactSchema>(
  STORE_NAME,
  LabContactsAPI,
)
const fetchEntity = createFetchEntityThunk<LabContactSchema>(
  STORE_NAME,
  LabContactsAPI,
)

const EntityAdapter = createEntityAdapter<LabContactSchema>({
  selectId: (entity) => entity.id,
  sortComparer: (a, b) =>
    a.getSortingValue().localeCompare(b.getSortingValue()),
})

const EntitySlice = createSlice({
  name: STORE_NAME,
  initialState: EntityAdapter.getInitialState(),
  reducers: {
    addOne: EntityAdapter.addOne,
    addMany: EntityAdapter.addMany,
    removeOne: EntityAdapter.removeOne,
    removeAll: EntityAdapter.removeAll,
    updateOne: (state, payload: PayloadAction<LabContactSchema>) =>
      createUpdateOneReducer<LabContactSchema>(state, payload),
  },
  extraReducers: (builder) =>
    builder
      .addCase(fetchEntities.fulfilled, (state, action) => {
        createUpdateManyReducer<LabContactSchema>(state, action)
      })
      .addCase(fetchEntity.fulfilled, (state, action) => {
        createUpdateOneReducer<LabContactSchema>(state, action)
      }),
})

export const LabContactsActions = EntitySlice.actions
export const LabContactsReducer = EntitySlice.reducer
export const LabContactsSelectors = EntityAdapter.getSelectors(
  (state: AppRootState) => state.labContacts,
)
export const LabContactsThunks = {
  fetchLabContacts: fetchEntities,
  fetchLabContact: fetchEntity,
}
