import React, { useEffect, useMemo } from 'react'
import FileSaver from 'file-saver'

import { DownloadButton } from '../../../Components/Buttons/DownloadButton'
import { useDataGridContext } from '../../../Components/DataGrid/Hooks/useDataGridContext'
import { useSnackbarMessages } from '../../../Components/AppSnackbar/Hooks/useSnackbarMessages'
import { useExportShipments } from '../Hooks/useExportShipments'

import _ from 'lodash'
import { AppPermission, useAppPermissions } from '../../../Hooks/useAppPermissions'

interface ExportShipmentsButtonProps {
  disabled?: boolean
  onProcessing?: (processing: boolean) => void
}

export const ExportShipmentsButton: React.FC<ExportShipmentsButtonProps> = ({
  disabled,
  onProcessing = () => {},
}) => {
  const { checkPermissions } = useAppPermissions()
  const { addErrorMessage } = useSnackbarMessages()
  const { visibleRows } = useDataGridContext()
  const { exportShipments, processing } = useExportShipments()

  useEffect(() => {
    onProcessing(processing)
  }, [onProcessing, processing])

  const shipmentIds = useMemo(() => {
    return _.map(visibleRows, row => row.toString())
  }, [visibleRows])

  const handleDownload = React.useCallback(() => {
    exportShipments({ shipmentIds }).then(response => {
      FileSaver.saveAs(response.documentUrl)
    }).catch(() => {
      addErrorMessage('Failed to download the CSV.')
    })
  }, [exportShipments, shipmentIds, addErrorMessage])

  if (!checkPermissions(AppPermission.ShipmentExport)) {
    return null
  }

  return (
    <DownloadButton disabled={disabled || processing} onClick={handleDownload} />
  )
}
