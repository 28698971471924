import { createAsyncThunk } from '@reduxjs/toolkit'
import { APIBase } from '../Services/API/APIBase'
import { ServiceLocator } from '../Services/ServiceLocator'
import { Class } from 'type-fest'

export default function createFetchEntityThunk<S> (
  storeName: string,
  API: Class<APIBase<S>>,
) {
  return createAsyncThunk(
    `${storeName}/fetchEntity`,
    async (entityId: string) => {
      let entity: S = null
      try {
        entity = await ServiceLocator.resolve(API).getEntity(entityId)
      } catch (err) {
        console.log(`${storeName}/fetchEntity`, err)
      }
      return entity
    },
  )
}
