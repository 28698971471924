import _ from 'lodash'
import React, { useMemo, useState } from 'react'
import { AutocompleteInput } from '../../../Components/Inputs/AutocompleteInput'
import { InputOptionProps } from '../../../Components/Inputs/Types'
import { AppPermission, useAppPermissions } from '../../../Hooks/useAppPermissions'
import { LabContactSchema } from '../../../Schema/LabContactSchema'
import { LabSchema } from '../../../Schema/LabSchema'
import { useLabContactsSelector } from '../Hooks/useLabContactsSelector'
import { LabContactAddDrawer } from '../LabContactAddDrawer'

interface LabContactPickerProps {
  value?: string
  className?: any
  label?: string
  helperText?: string
  onChange: (value: string) => void
  disabled?: boolean
  required?: boolean
  variant?: 'standard' | 'filled' | 'outlined'
  placeholder?: string
  freeSolo?: boolean
  noOptionsText?: string
  clearOnBlur?: boolean
  onCreateNew?: (text: string) => void
  enableCreate?: boolean
  error?: boolean
  labId?: string
}

export const LabContactPicker: React.FC<LabContactPickerProps> = ({
  className,
  value,
  label,
  helperText,
  onChange,
  disabled,
  required,
  variant,
  placeholder,
  freeSolo,
  noOptionsText,
  clearOnBlur,
  enableCreate = true,
  error,
  labId,
}) => {
  const { checkPermissions } = useAppPermissions()

  const [openAdd, setOpenAdd] = useState(false)
  const [text, setText] = useState<string>('')

  const labContacts = useLabContactsSelector()

  const filteredContacts = useMemo(() => {
    if (!labId) {
      return _.map(labContacts)
    }
    return _.filter(labContacts, c => c.labId === labId)
  }, [labContacts, labId])

  const options: InputOptionProps[] = useMemo(() => {
    return _.map(filteredContacts, c => c.getInputOption())
  }, [filteredContacts])

  const handleOnCreate = (name?: string) => {
    setOpenAdd(true)
    setText(
      name,
    )
  }

  const handleOnCloseAdd = (schema?: LabContactSchema) => {
    if (schema) {
      onChange(schema.id)
    }
    setOpenAdd(false)
  }
  return (
    <>
    <AutocompleteInput
      value={value}
      className={className}
      label={label}
      helperText={helperText}
      onChange={value => onChange(value as string)}
      disabled={disabled}
      required={required}
      variant={variant}
      placeholder={placeholder}
      freeSolo={freeSolo}
      options={options}
      noOptionsText={noOptionsText}
      clearOnBlur={clearOnBlur}
      onCreateNew={handleOnCreate}
      enableCreate={checkPermissions([AppPermission.LabContactAdd]) && enableCreate}
      error={error}
    />
    {openAdd && <LabContactAddDrawer
      open={openAdd}
      onClose={handleOnCloseAdd}
      defaultData={{
        labId: labId,
        name: text,
      }}
    />}
    </>
  )
}
