import React, { useCallback } from 'react'
import { useSnackbarMessages } from '../../../Components/AppSnackbar/Hooks/useSnackbarMessages'
import { DeleteIconButton } from '../../../Components/Buttons/DeleteIconButton'
import { AppPermission } from '../../../Hooks/useAppPermissions'
import { ContainerSchema } from '../../../Schema/ContainerSchema'
import { useDeleteContainer } from '../Hooks/useDeleteContainer'

interface DeleteContainerButtonProps {
  schema: ContainerSchema
  onDeleted?: () => void
}

export const DeleteContainerButton: React.FC<DeleteContainerButtonProps> = ({
  schema,
  onDeleted = () => {},
}) => {
  const { addSuccessMessage, addErrorMessage } = useSnackbarMessages()
  const { deleteContainer, processing, errorMessage } = useDeleteContainer()

  const handleOnDelete = useCallback(() => {
    deleteContainer(schema.id).then(() => {
      addSuccessMessage('The carrier deleted successfully.')
      onDeleted()
    }).catch(() => {
      addErrorMessage(errorMessage)
    })
  }, [schema?.id, addSuccessMessage, addErrorMessage, deleteContainer, errorMessage, onDeleted])

  return (
    <DeleteIconButton permissions={[AppPermission.ContainerDelete]} onDelete={handleOnDelete} disabled={processing} />
  )
}
