import React, { useCallback } from 'react'
import { useSnackbarMessages } from '../../../Components/AppSnackbar/Hooks/useSnackbarMessages'
import { DeleteIconButton } from '../../../Components/Buttons/DeleteIconButton'
import { AppPermission } from '../../../Hooks/useAppPermissions'
import { LabSchema } from '../../../Schema/LabSchema'
import { useDeleteLab } from '../Hooks/useDeleteLab'

interface DeleteLabButtonProps {
  schema: LabSchema
  onDeleted?: () => void
}

export const DeleteLabButton: React.FC<DeleteLabButtonProps> = ({
  schema,
  onDeleted = () => {},
}) => {
  const { addSuccessMessage, addErrorMessage } = useSnackbarMessages()
  const { deleteLab, processing, errorMessage } = useDeleteLab()

  const handleOnDelete = useCallback(() => {
    deleteLab(schema.id).then(() => {
      addSuccessMessage('The carrier deleted successfully.')
      onDeleted()
    }).catch(() => {
      addErrorMessage(errorMessage)
    })
  }, [schema?.id, addSuccessMessage, addErrorMessage, deleteLab, errorMessage, onDeleted])

  return (
    <DeleteIconButton permissions={[AppPermission.LabDelete]} onDelete={handleOnDelete} disabled={processing} />
  )
}
