import { Paper, Toolbar, useTheme } from '@material-ui/core'
import React from 'react'

export const AppToolbar: React.FC = ({
  children,
}) => {
  const theme = useTheme()

  return (
    <Paper style={{ marginBottom: theme.spacing(1), padding: theme.spacing(1) }}>
      <Toolbar variant="dense" >
        {children}
      </Toolbar>
    </Paper>
  )
}
