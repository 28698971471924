import React, { useCallback, useState } from 'react'
import { AppDrawer, AppDrawerToolbar, AppDrawerContent, AppDrawerActions } from '../../Components/AppDrawer'
import { v4 as uuid } from 'uuid'
import { Box, Button, Paper, Typography } from '@material-ui/core'
import { useAppStyles } from '../../Hooks/useAppStyles'
import { useAddConsignee } from './Hooks/useAddConsignee'
import { ConsigneeSchema } from '../../Schema/ConsigneeSchema'
import { ConsigneeForm } from './Components/ConsigneeForm'
import { useSnackbarMessages } from '../../Components/AppSnackbar/Hooks/useSnackbarMessages'

interface ConsigneeAddDrawerProps {
  open: boolean
  onClose: (schema?: ConsigneeSchema) => void,
  defaultData?: Partial<ConsigneeSchema>
}

export const ConsigneeAddDrawer: React.FC<ConsigneeAddDrawerProps> = ({
  open,
  onClose,
  defaultData,
}) => {
  const appStyles = useAppStyles()
  const { addConsignee, processing } = useAddConsignee()
  const { addErrorMessage, addSuccessMessage } = useSnackbarMessages()

  const [hash, setHash] = useState('')
  const [schema, setSchema] = React.useState(new ConsigneeSchema(defaultData))

  const handleOnSchemaChanged = useCallback((schema: ConsigneeSchema) => {
    setHash(uuid())
  }, [])

  const handleOnSave = useCallback(() => {
    if (schema.validate()) {
      addConsignee(schema).then(result => {
        addSuccessMessage('Consignee saved successfully.')
        setSchema(new ConsigneeSchema(defaultData))
        onClose(result)
      }).catch(() => {
        addErrorMessage('Failed to add a consignee.')
      })
    }
    setHash(uuid())
  }, [schema, addConsignee, onClose])

  return (
    <AppDrawer
      width={400}
      open={open}
      onClose={onClose}
    >
      <AppDrawerToolbar>
        <Typography variant={'h5'}>Add Consignee</Typography>
      </AppDrawerToolbar>
      <AppDrawerContent>
        <Box className={appStyles.content}>
          <Paper className={appStyles.content}>
            <form autoComplete={uuid()}>
              <ConsigneeForm schema={schema} onChange={handleOnSchemaChanged} />
            </form>
          </Paper>
        </Box>
      </AppDrawerContent>
      <AppDrawerActions>
        <Button disabled={processing} color={'primary'} onClick={handleOnSave}>Save</Button>
      </AppDrawerActions>
    </AppDrawer>
  )
}
