import React from 'react'
import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  Switch,
} from '@material-ui/core'

export interface SwitchInputProps {
  className?: any
  label: any;
  value: boolean;
  onChange: (checked: boolean) => void;
  style?: any;
  disabled?: boolean;
  error?: boolean
  helperText?: string
  required?: boolean
}

export const SwitchInput: React.FC<SwitchInputProps> = ({
  className,
  label,
  value,
  onChange,
  style,
  error,
  helperText,
  disabled = false,
  required,
}) => {
  return (
    <FormControl style={style} error={error} className={className} required={required}>
      <FormControlLabel
        control={
          <Switch
            disabled={disabled}
            checked={value}
            onChange={(e: any) => onChange(e.target.checked)}
            color="primary"
          />
        }
        label={label}
      />
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  )
}
