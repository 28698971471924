import { Singleton } from 'typescript-ioc'
import { APIClientBase } from './APIClientBase'

@Singleton
export class ShippingAPIClient extends APIClientBase {
  /**
   * Instanciate api client
   */
  constructor () {
    super('shipping')
  }
}
