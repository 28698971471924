import React, { useCallback, useState } from 'react'
import { AppDrawer, AppDrawerToolbar, AppDrawerContent, AppDrawerActions } from '../../Components/AppDrawer'
import { v4 as uuid } from 'uuid'
import { Box, Button, CircularProgress, Paper, Typography } from '@material-ui/core'
import { useAppStyles } from '../../Hooks/useAppStyles'
import { ShipmentItemSchema } from '../../Schema/ShipmentItemSchema'
import { ShipmentItemDeliveryForm } from './Components/ShipmentItemDeliveryForm'
import { useUpdateShipmentItem } from './Hooks/useUpdateShipmentItem'
import _ from 'lodash'
import { useSnackbarMessages } from '../../Components/AppSnackbar/Hooks/useSnackbarMessages'
import { getShipmentItemSelector } from './Selectors'
import { useAppSelector } from '../../Hooks/useAppSelector'

interface ShipmentItemAddDrawerProps {
  open: boolean
  onClose: (schema?: ShipmentItemSchema) => void,
  shipmentItemIds: string[]
}

export const ShipmentItemsBulkUpdateDrawer: React.FC<ShipmentItemAddDrawerProps> = ({
  open,
  onClose,
  shipmentItemIds,
}) => {
  const appStyles = useAppStyles()
  const getShipmentItem = useAppSelector(state => getShipmentItemSelector(state))
  const { addErrorMessage, addSuccessMessage } = useSnackbarMessages()

  const [hash, setHash] = useState('')
  const [schema, setSchema] = React.useState(new ShipmentItemSchema())
  const { updateShipmentItem, processing } = useUpdateShipmentItem()

  const handleOnSchemaChanged = useCallback((schema: ShipmentItemSchema) => {
    setHash(uuid())
  }, [])

  const handleOnSave = useCallback(async () => {
    schema.validate()
    const errors = _.keys(schema.getErrors())

    if (_.isEmpty(shipmentItemIds)) {
      return
    }

    if (_.isEmpty(_.intersection(errors, ['isDelivered', 'deliveryDate', 'signedBy']))) {
      for (const shipmentItemId of shipmentItemIds) {
        try {
          const shipmentItem = getShipmentItem(shipmentItemId)?.clone()
          if (shipmentItem) {
            _.assign(shipmentItem, {
              isDelivered: schema.isDelivered,
              signedBy: schema.signedBy,
              deliveryDate: schema.deliveryDate,
            })
            await updateShipmentItem(shipmentItem)
            addSuccessMessage('Shipment item udpated.')
          }
        } catch (err) {
          addErrorMessage('Failed to update shipment item.')
        }
      }
      onClose()
    } else {
      addErrorMessage('Please correct the errors.')
    }
    setHash(uuid())
  }, [schema, onClose, addErrorMessage, shipmentItemIds, addSuccessMessage, updateShipmentItem, getShipmentItem])

  const handleOnReset = useCallback(() => {
    setSchema(new ShipmentItemSchema())
  }, [])

  return (
    <AppDrawer
      width={400}
      open={open}
      onClose={onClose}
    >
      <AppDrawerToolbar>
        <Typography variant={'h5'} className={appStyles.flexOne}>Update Shipment Items</Typography>
        <Button disabled={processing} onClick={handleOnReset} color={'primary'}>Reset</Button>
      </AppDrawerToolbar>
      <AppDrawerContent>
        <Box className={appStyles.content}>
          <Paper className={appStyles.content}>
            {processing && <CircularProgress />}
            {!processing && <ShipmentItemDeliveryForm schema={schema} onChange={handleOnSchemaChanged} />}
          </Paper>
        </Box>
      </AppDrawerContent>
      <AppDrawerActions>
        <Button disabled={processing} color={'primary'} onClick={handleOnSave}>Update</Button>
      </AppDrawerActions>
    </AppDrawer>
  )
}
