import { useCallback, useState } from 'react'

import { useMounted } from '../../../Hooks/useMounted'
import { useAppDispatch } from '../../../Hooks/useAppDispatch'
import { DelaysActions } from '../StoreAdapter'
import { DelaySchema } from '../../../Schema/DelaySchema'
import { ServiceLocator } from '../../../Services/ServiceLocator'
import { DelaysAPI } from '../../../Services/API/DelaysAPI'

export const useAddDelay = () => {
  const dispatch = useAppDispatch()
  const mounted = useMounted()

  const [processing, setProcessing] = useState(false)
  const [error, setError] = useState(false)
  const [errorMessage, setErrorMessage] = useState<string>(null)

  const addDelay = useCallback(
    async (schema: DelaySchema): Promise<DelaySchema> => {
      setProcessing(true)
      return ServiceLocator.resolve(DelaysAPI)
        .createEntity(schema)
        .then((schema) => {
          dispatch(DelaysActions.addOne(schema))
          if (mounted.current) {
            setProcessing(false)
            setError(true)
            setErrorMessage(null)
          }
          return schema
        })
        .catch((err) => {
          if (mounted.current) {
            setProcessing(false)
            setError(true)
            setErrorMessage(err.message)
            throw err
          }
          return null
        })
    },
    [dispatch, mounted],
  )

  return {
    processing,
    error,
    errorMessage,
    addDelay,
  }
}
