import React, { useCallback, useEffect, useState } from 'react'

export interface AppViewProps {
  value: string,
  route: string,
  label: string,
}

interface AppViewsContextProps {
  selected?: string,
  selectView: (value: string) => void
  hidden?: boolean
}

export const AppViewsContext = React.createContext<AppViewsContextProps>({
  selected: null,
  selectView: (value: string) => {},
  hidden: true,
})

interface AppViewsProps {
  selected?: string,
  onSelected?: (selected: string) => void
}

export const AppViews: React.FC<AppViewsProps> = ({
  selected,
  onSelected = (selected: string) => {},
  children,
}) => {
  const [selectedView, setSelectView] = useState(selected)

  const selectView = useCallback((view: string) => {
    setSelectView(view)
    onSelected(view)
  }, [onSelected])

  const context: AppViewsContextProps = {
    selected: selectedView,
    selectView: selectView,
  }

  useEffect(() => {
    selectView(selected)
  }, [selectView, selected])

  return (
    <AppViewsContext.Provider value={context}>
      {children}
    </AppViewsContext.Provider>
  )
}
