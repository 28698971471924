import { Type } from 'class-transformer'
import { IsNotEmpty, IsOptional, IsString, IsUUID } from 'class-validator'
import _ from 'lodash'
import { BaseSchema } from './BaseSchema'

export class ShipmentDelaySchema extends BaseSchema {
  /**
   * User ID
   */
  @IsOptional()
  @IsUUID()
  id: string

  /**
   * Shipment Id
   */
  @Type(() => String)
  @IsNotEmpty()
  @IsString()
  shipmentId: string = null

  /**
   * Delay Id
   */
  @Type(() => String)
  @IsNotEmpty()
  @IsString()
  delayId: string = null

  /**
   * Date created
   */
  @Type(() => Date)
  created: Date

  /**
   * Updated by
   */
  createdBy: string

  /**
   * Date updated
   */
  @Type(() => Date)
  updated: Date

  /**
   * Updated by
   */
  updatedBy: string

  /**
   * Date deleted
   */
  @Type(() => Date)
  deleted: Date

  /**
   * Deleted by
   */
  deletedBy: string

  /**
   * Construct
   * @param data
   */
  constructor (data?: Partial<ShipmentDelaySchema>) {
    super()
    _.assign(this, data)
  }
}
