import { createStyles, makeStyles } from '@material-ui/core'
import { GridCellParams, GridColDef, DataGrid, GridSortDirection, GridValueFormatterParams } from '@material-ui/data-grid'
import React from 'react'
import { useHistory } from 'react-router'
import { DataGridBoolean } from '../../../Components/DataGrid/DataGridBoolean'
import { ChargeCodeSchema } from '../../../Schema/ChargeCodeSchema'
import { formatLocalDateTime } from '../../../Utils/DateFunctions'
import { ChargeCodeTag } from './ChargeCodeTag'

const columns: GridColDef[] = [
  {
    field: 'code',
    headerName: 'Code',
    width: 250,
    renderCell: (params: GridValueFormatterParams) => {
      return (
        <ChargeCodeTag chargeCodeId={params.getValue(params.id, 'id')?.toString()} />
      )
    },
  },
  {
    field: 'description',
    headerName: 'Description',
    width: 250,
  },
  {
    field: 'isEnabled',
    headerName: 'Enabled?',
    width: 120,
    headerAlign: 'center',
    align: 'center',
    renderCell: (params: GridValueFormatterParams) => {
      return (
        <DataGridBoolean params={params} />
      )
    },
  },
  {
    field: 'updated',
    headerName: 'Updated',
    width: 180,
    type: 'dateTime',
    valueFormatter: (params: GridValueFormatterParams) => {
      return formatLocalDateTime(params.getValue(params.id, 'updated') as Date)
    },
  },
  {
    field: 'created',
    headerName: 'Created',
    width: 180,
    type: 'dateTime',
    valueFormatter: (params: GridValueFormatterParams) => {
      return formatLocalDateTime(params.getValue(params.id, 'created') as Date)
    },
  },
]

interface CharegeCodesDataGridProps {
  entities: ChargeCodeSchema[]
}

export const CharegeCodesDataGrid: React.FC<CharegeCodesDataGridProps> = ({
  entities,
}) => {
  const history = useHistory()
  const styles = useStyles()

  const [sortModel, setSortModel] = React.useState([
    {
      field: 'code',
      sort: 'asc' as GridSortDirection,
    },
  ])

  const handleOnCellClick = (params: GridCellParams) => {
    /* if (_.includes(['status', 'assigneeId'], cellParams.field)) {
      return
    } */
    history.push(`/settings/charge-codes/${params.getValue(params.id, 'id')}`)
  }

  return (
    <DataGrid
      sortModel={sortModel}
      onSortModelChange={(model) => setSortModel(model)}
      className={styles.grid}
      columns={columns}
      rows={entities}
      showColumnRightBorder
      onCellClick={handleOnCellClick}
    />
  )
}

const useStyles = makeStyles(theme =>
  createStyles({
    grid: {
      fontSize: 14,
    },
  }),
)
