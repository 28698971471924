import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AxiosClientFactory } from '../Services/AxiosClientFactory'
import { ServiceLocator } from '../Services/ServiceLocator'

interface TokensState {
  accessToken: string
  refreshToken: string
}

const AuthSlice = createSlice({
  name: 'auths',
  initialState: {
    accessToken: null,
    refreshToken: null,
  } as TokensState,
  reducers: {
    unset (state) {
      ServiceLocator.resolve(AxiosClientFactory).setAuthToken(null)
      state.accessToken = null
      state.refreshToken = null
    },
    setTokens (
      state,
      action: PayloadAction<{ accessToken: string; refreshToken: string }>,
    ) {
      if (action.payload) {
        ServiceLocator.resolve(AxiosClientFactory).setAuthToken(
          action.payload.accessToken,
        )
        state.accessToken = action.payload.accessToken
        state.refreshToken = action.payload.refreshToken
      } else {
        ServiceLocator.resolve(AxiosClientFactory).setAuthToken(null)
      }
    },
  },
})

export const AuthActions = AuthSlice.actions
export const AuthReducer = AuthSlice.reducer
