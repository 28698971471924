import React, { useCallback, useEffect, useState } from 'react'
import { AppDrawer, AppDrawerToolbar, AppDrawerContent, AppDrawerActions } from '../../Components/AppDrawer'
import { UserSchema } from '../../Schema/UserSchema'
import { UserForm } from './Components/UserForm'
import { v4 as uuid } from 'uuid'
import { Box, Button, CircularProgress, Paper, Typography } from '@material-ui/core'
import { useAppStyles } from '../../Hooks/useAppStyles'
import { UsersSelectors } from './StoreAdapter'
import { AppStore } from '../../AppState/AppStore'
import { useUpdateUser } from './Hooks/useUpdateUser'
import { BaseSchema } from '../../Schema/BaseSchema'
import { useSnackbarMessages } from '../../Components/AppSnackbar/Hooks/useSnackbarMessages'

interface UserAddDrawerProps {
  userId: string
  open: boolean
  onClose: () => void,
}

export const UserEditDrawer: React.FC<UserAddDrawerProps> = ({
  open,
  onClose,
  userId,
}) => {
  const { addSuccessMessage, addErrorMessage } = useSnackbarMessages()
  const appStyles = useAppStyles()
  const { updateUser, processing } = useUpdateUser()

  const userState = UsersSelectors.selectById(AppStore.getState(), userId)

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [hash, setHash] = useState('')
  const [user, setUser] = React.useState<UserSchema>(null)

  const handleOnSchemaChanged = useCallback((schema: UserSchema) => {
    setHash(uuid())
  }, [])

  useEffect(() => {
    if (!userState || !(userState instanceof BaseSchema)) {
      return
    }
    setUser(userState.clone())
  }, [userState])

  const handleOnSave = useCallback(() => {
    if (user.validate()) {
      updateUser(user).then(() => {
        addSuccessMessage('User updated successfully.')
        onClose()
      }).catch(() => {
        addErrorMessage('Failed to udpate the user.')
      })
    }
    setHash(uuid())
  }, [user, updateUser, onClose, addSuccessMessage, addErrorMessage])

  return (
    <AppDrawer
      width={400}
      open={open}
      onClose={onClose}
    >
      <AppDrawerToolbar>
        <Typography variant={'h5'}>Edit User</Typography>
      </AppDrawerToolbar>
      <AppDrawerContent>
        <Box className={appStyles.content}>
          <Paper className={appStyles.content}>
            {!user && <CircularProgress />}
            {user && <UserForm schema={user} onChange={handleOnSchemaChanged} />}
          </Paper>
        </Box>
      </AppDrawerContent>
      <AppDrawerActions>
        <Button disabled={processing} color={'primary'} onClick={handleOnSave}>Save</Button>
      </AppDrawerActions>
    </AppDrawer>
  )
}
