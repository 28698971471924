import React, { useCallback, useEffect, useState } from 'react'
import { v4 as uuid } from 'uuid'
import { Box, Button, CircularProgress, Divider, LinearProgress, Paper, Typography } from '@material-ui/core'

import { ShipmentSchema } from '../../../Schema/ShipmentSchema'
import { BaseSchema } from '../../../Schema/BaseSchema'
import { useUpdateShipment } from '../Hooks/useUpdateShipment'
import { ShipmentForm } from './ShipmentForm'
import { useDebouncedCallback } from 'use-debounce/lib'
import { Alert } from '@material-ui/lab'
import { useSnackbarMessages } from '../../../Components/AppSnackbar/Hooks/useSnackbarMessages'
import { useAppViews } from '../../../Components/AppViews/useAppViews'
import { Close } from '@material-ui/icons'
import { useAppStyles } from '../../../Hooks/useAppStyles'
import { usePrevious } from '../../../Hooks/usePrevious'

interface ShipmentUpdaterProps {
  shipment: ShipmentSchema
}

export const ShipmentUpdater: React.FC<ShipmentUpdaterProps> = ({
  shipment,
}) => {
  const appStyles = useAppStyles()
  const { selectView } = useAppViews()

  const { addErrorMessage, addSuccessMessage } = useSnackbarMessages()
  const { updateShipment, processing, errors } = useUpdateShipment()

  const [hash, setHash] = useState('')
  const [schema, setSchema] = React.useState<ShipmentSchema>(null)
  const previousHash = usePrevious(hash)

  const delayedSave = useDebouncedCallback((schema: ShipmentSchema) => {
    updateShipment(schema).then(() => {
      addSuccessMessage('Shipment saved.')
    }).catch(() => {
      addErrorMessage('Failed to save the Shipment.')
      schema.setErrors(errors)
    })
  }, 2000)

  const handleOnSchemaChanged = useCallback((schema: ShipmentSchema) => {
    setHash(schema.generateHash())
  }, [])

  useEffect(() => {
    if (!schema || !previousHash) {
      return
    }
    if (previousHash !== hash && schema.validate()) {
      delayedSave.cancel()
      delayedSave(schema)
    }
  }, [delayedSave, previousHash, hash, schema])

  useEffect(() => {
    if (!shipment || !(shipment instanceof BaseSchema)) {
      setSchema(null)
      return
    }
    if (schema) {
      return
    }
    setSchema(shipment.clone())
    setHash(shipment.generateHash())
  }, [schema, shipment])

  return (
    <>
      <Box flex={1} display={'flex'} justifyContent={'flex-end'} className={appStyles.content}>
        <Button startIcon={<Close />} onClick={() => selectView('view')} >Close</Button>
      </Box>
      <Divider style={{ marginBottom: 5 }} />
      {!schema && <CircularProgress />}
      {processing && <LinearProgress />}
      {schema && !schema.isValid() && <Alert severity={'warning'}>Please correct the validation errors.</Alert>}
      {schema && <form autoComplete="off"><ShipmentForm schema={schema} onChange={handleOnSchemaChanged} /></form>}
    </>
  )
}
