import { APIClientBase } from './Clients/APIClientBase'
import { ServiceLocator } from '../ServiceLocator'
import _ from 'lodash'
import { plainToClass, classToPlain } from 'class-transformer'

/**
 *
 */
export interface ListResponseMeta {
  itemCount: number
  pageCount: number
  pageItemCount: number
  pageItemLimit: number
  pageNumber: number
}

/**
 * Constructor type definition/alias.
 */
export interface ConstructorType<T> {
  /**
   * Constructor signature.
   */
  new (): T
}

/**
 * List Response
 */
export interface ListResponse<T> {
  /**
   * A list of entities
   */
  pageItems: T[]

  /**
   * Total item count
   */
  metadata: ListResponseMeta
}

export class APIBase<T> {
  /**
   * API resource path
   */
  resourcePath: string

  /**
   * API instance
   */
  apiInstance: APIClientBase

  /**
   * Generic type
   */
  ctor: ConstructorType<T>

  /**
   * Resolve API instance on create
   */
  constructor (ctor: ConstructorType<T>, client: any) {
    this.apiInstance = ServiceLocator.resolve(client)
    this.ctor = ctor
  }

  protected buildBaseEndpoint () {
    return `/${this.apiInstance.getVersion()}/${this.resourcePath}`
  }

  async fetchPaginatedEntityList (params?: object): Promise<ListResponse<T>> {
    const endpoint = this.buildBaseEndpoint()
    return this.apiInstance
      .get<ListResponse<T>>(endpoint, { params: params })
      .then((result) => {
        return {
          pageItems: plainToClass(this.ctor, result.data.pageItems),
          metadata: result.data.metadata,
        }
      })
  }

  async fetchEntityList (params?: object): Promise<T[]> {
    const endpoint = this.buildBaseEndpoint()
    return this.apiInstance
      .get<T[]>(endpoint, { params: params })
      .then((result) => {
        return plainToClass(this.ctor, result.data)
      })
  }

  async getEntity (id: string) {
    const endpoint = `${this.buildBaseEndpoint()}/${id}`
    return this.apiInstance.get<T>(endpoint).then((result) => {
      return plainToClass(this.ctor, result.data)
    })
  }

  async createEntity (data: Partial<T>) {
    const endpoint = this.buildBaseEndpoint()
    return this.apiInstance
      .post<T>(endpoint, classToPlain(data))
      .then((result) => {
        return plainToClass(this.ctor, result.data)
      })
  }

  async updateEntity (data: Partial<T>) {
    const id = _.get(data, 'id')
    if (_.isEmpty(id)) {
      throw Error('Missing ID.')
    }
    const endpoint = `${this.buildBaseEndpoint()}/${id}`
    return this.apiInstance
      .put<T>(endpoint, classToPlain(data))
      .then((result) => {
        return plainToClass(this.ctor, result.data)
      })
  }

  async deleteEntity (id: string) {
    const endpoint = `${this.buildBaseEndpoint()}/${id}`
    return this.apiInstance.delete<T>(endpoint).then((result) => {
      return plainToClass(this.ctor, result.data)
    })
  }
}
