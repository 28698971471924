import { useAppSelector } from '../../../Hooks/useAppSelector'
import { ShipmentSelectors } from '../StoreAdapter'

export const useShipmentSelector = (entityId: string) => {
  const entityState = useAppSelector((state) =>
    ShipmentSelectors.selectById(state, entityId),
  )

  return entityState
}
