import React, { useCallback, useState } from 'react'
import { AppDrawer, AppDrawerToolbar, AppDrawerContent, AppDrawerActions } from '../../Components/AppDrawer'
import { v4 as uuid } from 'uuid'
import { Box, Button, Paper, Typography } from '@material-ui/core'
import { useAppStyles } from '../../Hooks/useAppStyles'
import { useAddDelay } from './Hooks/useAddDelay'
import { DelaySchema } from '../../Schema/DelaySchema'
import { DelayForm } from './Components/DelayForm'
import { useSnackbarMessages } from '../../Components/AppSnackbar/Hooks/useSnackbarMessages'

interface DelayAddDrawerProps {
  open: boolean
  onClose: () => void,
  defaultData?: Partial<DelaySchema>,
}

export const DelayAddDrawer: React.FC<DelayAddDrawerProps> = ({
  open,
  onClose,
  defaultData,
}) => {
  const { addSuccessMessage, addErrorMessage } = useSnackbarMessages()
  const appStyles = useAppStyles()
  const { addDelay, processing } = useAddDelay()

  const [hash, setHash] = useState('')
  const [schema, setSchema] = React.useState(new DelaySchema(defaultData))

  const handleOnSchemaChanged = useCallback((schema: DelaySchema) => {
    setHash(uuid())
  }, [])

  const handleOnSave = useCallback(() => {
    if (schema.validate()) {
      addDelay(schema).then(() => {
        addSuccessMessage('Delay added successfully.')
        setSchema(new DelaySchema())
        onClose()
      }).catch(() => {
        addErrorMessage('Failed to add the Delay.')
      })
    }
    setHash(uuid())
  }, [schema, addDelay, onClose, addSuccessMessage, addErrorMessage])

  return (
    <AppDrawer
      width={400}
      open={open}
      onClose={onClose}
    >
      <AppDrawerToolbar>
        <Typography variant={'h5'}>Add Delay</Typography>
      </AppDrawerToolbar>
      <AppDrawerContent>
        <Box className={appStyles.content}>
          <Paper className={appStyles.content}>
            <DelayForm schema={schema} onChange={handleOnSchemaChanged} />
          </Paper>
        </Box>
      </AppDrawerContent>
      <AppDrawerActions>
        <Button disabled={processing} color={'primary'} onClick={handleOnSave}>Save</Button>
      </AppDrawerActions>
    </AppDrawer>
  )
}
