import React, { useCallback } from 'react'
import { useSnackbarMessages } from '../../../Components/AppSnackbar/Hooks/useSnackbarMessages'
import { DeleteIconButton } from '../../../Components/Buttons/DeleteIconButton'
import { AppPermission } from '../../../Hooks/useAppPermissions'
import { ServiceSchema } from '../../../Schema/ServiceSchema'
import { useDeleteService } from '../Hooks/useDeleteService'

interface DeleteServiceButtonProps {
  schema: ServiceSchema
  onDeleted?: () => void
}

export const DeleteServiceButton: React.FC<DeleteServiceButtonProps> = ({
  schema,
  onDeleted = () => {},
}) => {
  const { addSuccessMessage, addErrorMessage } = useSnackbarMessages()
  const { deleteService, processing, errorMessage } = useDeleteService()

  const handleOnDelete = useCallback(() => {
    deleteService(schema.id).then(() => {
      addSuccessMessage('The service deleted successfully.')
      onDeleted()
    }).catch(() => {
      addErrorMessage(errorMessage)
    })
  }, [schema?.id, addSuccessMessage, addErrorMessage, deleteService, errorMessage, onDeleted])

  return (
    <DeleteIconButton permissions={[AppPermission.ServiceDelete]} onDelete={handleOnDelete} disabled={processing} />
  )
}
