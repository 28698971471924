import { Box, IconButton, Tooltip } from '@material-ui/core'
import React from 'react'
import Launch from '@material-ui/icons/LaunchOutlined'
import crypto from 'crypto'

const hasher = (data: string) => {
  return crypto.createHash('md5').update(data).digest('hex')
}

interface OpenTrackingWebsiteButtonProps {
  trackingUrl: string
  onOpen?: () => void
  buttonSize?: 'small' | 'medium'
}

export const OpenTrackingWebsiteButton: React.FC<OpenTrackingWebsiteButtonProps> = ({
  trackingUrl,
  onOpen = () => {},
  buttonSize = 'medium',
}) => {
  const handleOnOpen = () => {
    if (onOpen) {
      onOpen()
    }
  }

  if (!trackingUrl) {
    return null
  }

  return (
    <Box onClick={(e) => {
      handleOnOpen()
      e.stopPropagation()
    }}>
      <Tooltip title={'Open Tracking Website'}>
        <IconButton target={hasher(trackingUrl)} href={trackingUrl} size={buttonSize}><Launch style={{ fontSize: 'inherit' }} /></IconButton>
      </Tooltip>
    </Box>
  )
}
