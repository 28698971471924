import { Grid } from '@material-ui/core'
import React, { useCallback } from 'react'
import { SchemaSwitchInput } from '../../../Components/Inputs/Schema/SchemaSwitchInput'
import { SchemaTextInput } from '../../../Components/Inputs/Schema/SchemaTextInput'
import { CarrierSchema } from '../../../Schema/CarrierSchema'

interface CarrierFormProps {
  schema: CarrierSchema
  onChange: (schema: CarrierSchema) => void
}

export const CarrierForm: React.FC<CarrierFormProps> = ({
  schema,
  onChange,
}) => {
  const handleOnSchemaChanged = useCallback((value: any, field: string) => {
    schema.setValue(field, value)
    onChange(schema)
  }, [onChange, schema])

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <SchemaTextInput
          label={'Name'}
          placeholder={'Enter a name'}
          schema={schema}
          field={'name'}
          required
          onChange={handleOnSchemaChanged}
        />
      </Grid>
      <Grid item xs={12}>
        <SchemaTextInput
          label={'Tracking Number Prefix'}
          placeholder={'Enter a tracking number prefix'}
          schema={schema}
          field={'trackingNumberPrefix'}
          onChange={handleOnSchemaChanged}
        />
      </Grid>
      <Grid item xs={12}>
        <SchemaSwitchInput
          label={'Is Enabled?'}
          schema={schema}
          field={'isEnabled'}
          onChange={handleOnSchemaChanged}
        />
      </Grid>
    </Grid>
  )
}
