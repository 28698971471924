import { Grid } from '@material-ui/core'
import React, { useCallback } from 'react'
import { SchemaTextInput } from '../../../Components/Inputs/Schema/SchemaTextInput'
import { ShipmentItemSchema } from '../../../Schema/ShipmentItemSchema'
import { SchemaContainerPicker } from '../../../Components/Inputs/Schema/SchemaContainerPicker'
import { SchemaNumberInput } from '../../../Components/Inputs/Schema/SchemaNumberInput'
import { ShipmentItemDeliveryForm } from './ShipmentItemDeliveryForm'

interface ShipmentItemFormProps {
  schema: ShipmentItemSchema
  onChange: (schema: ShipmentItemSchema) => void
}

export const ShipmentItemForm: React.FC<ShipmentItemFormProps> = ({
  schema,
  onChange,
}) => {
  const handleOnSchemaChanged = useCallback((value: any, field: string) => {
    schema.setValue(field, value)
    onChange(schema)
  }, [onChange, schema])

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <SchemaTextInput
          label={'Carrier Tracking Number'}
          placeholder={'Enter a Carrier Tracking Number'}
          schema={schema}
          field={'carrierTrackingNumber'}
          required
          onChange={handleOnSchemaChanged}
          autoFocus
        />
      </Grid>
      <Grid item xs={12}>
        <SchemaNumberInput
          label={'Weight'}
          placeholder={'Enter Weight'}
          schema={schema}
          field={'weight'}
          required
          onChange={handleOnSchemaChanged}
        />
      </Grid>
      <Grid item xs={12}>
        <SchemaNumberInput
          label={'Pieces'}
          placeholder={'Enter Pieces'}
          schema={schema}
          field={'pieces'}
          required
          onChange={handleOnSchemaChanged}
        />
      </Grid>
      <Grid item xs={12}>
        <SchemaContainerPicker
          schema={schema}
          required
          onChange={handleOnSchemaChanged}
        />
      </Grid>
      <ShipmentItemDeliveryForm schema={schema} onChange={onChange} />
    </Grid>
  )
}
