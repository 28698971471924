import { APIBase } from './APIBase'
import { Singleton } from 'typescript-ioc'
import { ShippingAPIClient } from './Clients/ShippingAPIClient'
import { ShipmentItemSchema } from '../../Schema/ShipmentItemSchema'

@Singleton
export class ShipmentItemsAPI extends APIBase<ShipmentItemSchema> {
  constructor () {
    super(ShipmentItemSchema, ShippingAPIClient)
    this.resourcePath = 'shipped-items'
  }
}
