import React, { useCallback, useEffect, useState } from 'react'
import { v4 as uuid } from 'uuid'
import { Box, Button, CircularProgress, Paper, Typography } from '@material-ui/core'

import { AppDrawer, AppDrawerToolbar, AppDrawerContent, AppDrawerActions } from '../../Components/AppDrawer'
import { useAppStyles } from '../../Hooks/useAppStyles'
import { useUpdateCarrier } from './Hooks/useUpdateCarrier'
import { BaseSchema } from '../../Schema/BaseSchema'
import { useCarrierSelector } from './Hooks/useCarrierSelector'
import { CarrierSchema } from '../../Schema/CarrierSchema'
import { CarrierForm } from './Components/CarrierForm'
import { useSnackbarMessages } from '../../Components/AppSnackbar/Hooks/useSnackbarMessages'
import { DeleteCarrierButton } from './Components/DeleteCarrierButton'

interface CarrierEditDrawerProps {
  schemaId: string
  open: boolean
  onClose: () => void,
}

export const CarrierEditDrawer: React.FC<CarrierEditDrawerProps> = ({
  open,
  onClose,
  schemaId,
}) => {
  const { addSuccessMessage, addErrorMessage } = useSnackbarMessages()
  const appStyles = useAppStyles()
  const { updateCarrier, processing } = useUpdateCarrier()

  const schemaState = useCarrierSelector(schemaId)

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [hash, setHash] = useState('')
  const [schema, setSchema] = React.useState<CarrierSchema>(null)

  const handleOnSchemaChanged = useCallback((schema: CarrierSchema) => {
    setHash(uuid())
  }, [])

  useEffect(() => {
    if (!schemaState || !(schemaState instanceof BaseSchema)) {
      setSchema(null)
      return
    }
    setSchema(schemaState.clone())
  }, [schemaState])

  const handleOnSave = useCallback(() => {
    if (schema.validate()) {
      updateCarrier(schema).then(() => {
        addSuccessMessage('Carrier updated successfully.')
        onClose()
      }).catch(() => {
        addErrorMessage('Failed to update the Carrier.')
      })
    }
    setHash(uuid())
  }, [schema, updateCarrier, onClose, addSuccessMessage, addErrorMessage])

  return (
    <AppDrawer
      width={400}
      open={open}
      onClose={onClose}
    >
      <AppDrawerToolbar>
        <Typography variant={'h5'} style={{ flex: 1 }}>Edit Carrier</Typography>
        <DeleteCarrierButton schema={schema} onDeleted={onClose} />
      </AppDrawerToolbar>
      <AppDrawerContent>
        <Box className={appStyles.content}>
          <Paper className={appStyles.content}>
            {!schema && <CircularProgress />}
            {schema && <CarrierForm schema={schema} onChange={handleOnSchemaChanged} />}
          </Paper>
        </Box>
      </AppDrawerContent>
      <AppDrawerActions>
        <Button disabled={processing} color={'primary'} onClick={handleOnSave}>Save</Button>
      </AppDrawerActions>
    </AppDrawer>
  )
}
